import { ChangeDetectorRef, Component } from '@angular/core';
import { AdminEditableBaseComponent } from '../admin-editable-base.component';
import { AppQuery } from '@thema-core/state';
import { map } from 'rxjs/operators';

@Component({
  selector: 'thema-core-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent extends AdminEditableBaseComponent {
  public activeUrls$ = this.appQuery.language$.pipe(map((l) => this.urls[l]));

  constructor(private appQuery: AppQuery, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  private urls = {
    en: {
      jewelleryWorkshop: 'en/s/jewellery-workshop-pricelist',
      watchWorkshopPricelist: 'en/s/watch-workshop-pricelist',
      diamondPricelist: 'en/s/diamond-pricelist',
      goldPricelist: 'en/s/gold-pricelist',
      bespoke: 'en/s/bespoke',
      computerAidedDesign: 'en/s/design-and-printing',
      waxModeling: 'en/s/wax-crafting-handmade-jewellery',
      engraving: 'en/s/engraving',
      setting: 'en/s/setting',
      saleOfGoldPreciousMetal: 'en/s/sale-of-gold-and-precious-metals',
      saleDiamonds: 'en/s/sale-of-diamonds-and-precious-stones',
      commission: 'en/s/commission',
      appraisal: 'en/s/appraisal',
      insurance: 'en/s/insurance',
    },
    no: {
      jewelleryWorkshop: 's/gullsmedverksted-prisliste',
      watchWorkshopPricelist: 's/urmakerverksted-prisliste',
      diamondPricelist: 's/diamanter-prisliste',
      goldPricelist: 's/gull-prisliste',
      bespoke: 's/spesialdesign',
      computerAidedDesign: 's/3d-design-og-printing-cad-cam',
      waxModeling: 's/voksmodellering-og-handlagede-smykker',
      engraving: 's/gravering',
      setting: 's/fatting',
      saleOfGoldPreciousMetal: 's/salg-av-gull-og-edelmetall',
      saleDiamonds: 's/salg-av-diamanter-og-edelstener',
      commission: 's/kommisjonssalg',
      appraisal: 's/taksering',
      insurance: 's/forsikring',
    },
  };
}
