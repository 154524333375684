import { Component, Inject } from '@angular/core';
import { AdminEditablePartComponent } from '../../admin-editable-part-component';
import { IS_ADMIN_APP } from '@thema-core/tokens';

@Component({
  selector: 'thema-core-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent extends AdminEditablePartComponent {
  constructor(@Inject(IS_ADMIN_APP) public isAdminApp: boolean) {
    super();
  }
}
