import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetRoutePipe } from './get-route.pipe';



@NgModule({
  declarations: [GetRoutePipe],
  exports: [GetRoutePipe],
  imports: [
    CommonModule
  ]
})
export class GetRouteModule { }
