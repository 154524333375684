import { Injectable } from '@angular/core';
import { HttpService } from '@thema-core/services';
import { Observable } from 'rxjs';
import {
  AttributeType,
  DiamondFilters,
  LocaleOptions,
  Pagination,
  ProductPage,
  SortOrder,
} from '@thema-core/models';
import { omitProperties } from '@thema-core/helpers';
import { ParamMap, Params } from '@angular/router';
import { RangeFilters } from './range-filters';

interface SortOptions {
  sortBy: string;
  sortOrder: SortOrder;
}

@Injectable({
  providedIn: 'root',
})
export class DiamondsCatalogService {
  constructor(private http: HttpService) {}

  public getPage(
    baseUrl: string,
    query: ParamMap,
    pagination: Pagination,
    options: LocaleOptions,
    sortOptions: SortOptions
  ): Observable<ProductPage> {
    return this.fetchPage(
      baseUrl,
      omitProperties<Pagination>(pagination, ['total']),
      options,
      sortOptions,
      this.getFilterParamsFromUrlQuery(query, pagination)
    );
  }

  public parseRangeFilters(activeFilters: string[]): RangeFilters {
    const result = {};
    ['price', 'diamond_weight_ct', 'availability'].forEach((key) => {
      const filter = activeFilters.find((f) => f.startsWith(key));
      if (!filter) {
        return;
      }
      result[key] = filter
        .split('=')[1]
        .split(',')
        .map((v) => +v);
    });
    return result as RangeFilters;
  }

  public filtersToParams(filters: DiamondFilters): Params {
    return Object.values(filters).reduce((result, curr) => {
      const selectedOptions = curr.options?.filter((o) => o.isSelected);
      if (
        !selectedOptions ||
        (selectedOptions.length === curr.options.length &&
          curr.type === AttributeType.Select) ||
        selectedOptions.length === 0
      ) {
        return result;
      }

      result[curr.label.toLowerCase()] = selectedOptions
        .map((o) => o.label.toLowerCase())
        .join(',');
      return result;
    }, {});
  }

  private fetchPage(
    baseUrl: string,
    page: Partial<Pagination>,
    options: LocaleOptions,
    sortOptions: SortOptions,
    filters?: Record<keyof DiamondFilters, string>
  ): Observable<ProductPage> {
    const params = this.http.objectToParams({
      ...page,
      store: options.language,
      country: options.country.toUpperCase(),
      currency: options.currency,
      filterGroup: 'DiamondFilterGroup',
      ...sortOptions,
    });

    const urlParam = this.createUrlParam(baseUrl, filters);
    return this.http.get<ProductPage>(`/Products/ByQueryWithContext?url=${urlParam}`, {
      params,
    });
  }

  private createUrlParam(
    baseUrl: string,
    filters?: Record<keyof DiamondFilters, string>
  ): string {
    if (!filters || Object.keys(filters).length === 0) {
      return baseUrl;
    }

    return encodeURIComponent(
      `${baseUrl}?${Object.entries(filters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    );
  }

  private getFilterParamsFromUrlQuery(
    query: ParamMap,
    pagination: Pagination
  ): Record<string, string> {
    const params = {};
    query.keys.forEach((key) => {
      if (key in pagination || key === 'sortOrder' || key === 'sortBy') {
        return;
      }
      params[key] = query.get(key);
    });
    return params;
  }
}
