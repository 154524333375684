import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CartState } from '@thema-core/models';
import { CartStore } from './cart.store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CartQuery extends Query<CartState> {
  public items$ = this.select('positions');
  public note$ = this.select('note');
  public cart$: Observable<CartState> = this.select().pipe(map((c) => ({ ...c })));
  public id$ = this.select('id');
  public availablePaymentMethods$ = this.select('availablePaymentMethods');
  public availableShippingMethods$ = this.select('availableShippingMethods');
  public advanceOnly$ = this.select((state) => {
    return state.advance?.isSet;
  });
  public selectedPaymentMethodId$ = this.select('selectedPaymentMethod').pipe(
    map((m) => m?.id)
  );
  public selectedShippingMethodId$ = this.select('selectedShippingMethod').pipe(
    map((m) => m?.id)
  );
  public itemCount$ = this.select('positions').pipe(
    map((p) => {
      if (!p) {
        return null;
      }

      return p.reduce((acc, curr) => {
        acc += curr.quantity;
        return acc;
      }, 0);
    })
  );

  constructor(protected store: CartStore) {
    super(store);
  }
}
