import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { AppRoutes } from '@thema-core/models';
import { AddLangDataPipe } from '../add-lang-data/add-lang-data.pipe';
import { ROUTES } from '@thema-core/tokens';
import { isNgDevMode } from '@thema-core/helpers';

export interface GetRouteOptions {
  withSlash: boolean;
  relative: boolean;
}

const DEFAULT_OPTIONS = {
  withSlash: true,
  relative: false,
};

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'getRoute',
})
export class GetRoutePipe implements PipeTransform {
  constructor(
    private addLangData: AddLangDataPipe,
    @Inject(ROUTES) private routes: AppRoutes
  ) {}

  public transform(
    value: keyof AppRoutes,
    options: Partial<GetRouteOptions> = DEFAULT_OPTIONS
  ): string {
    const route = this.routes[value];
    if (route == undefined) {
      isNgDevMode() && console.warn(`URL key value "${value}" returned ${route}`);
      return '';
    }
    const { withSlash, relative } = { ...DEFAULT_OPTIONS, ...options };
    if (relative) {
      const splitUrl = route.split('/');
      return splitUrl[splitUrl.length - 1];
    }

    const routeWithLang = this.addLangData.transform(`/${route}`);
    return withSlash ? routeWithLang : routeWithLang.substring(1);
  }
}
