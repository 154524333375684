import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StaticPageDirective } from './common/static-page.directive';
import { FaqComponent } from './static-pages-implementations/faq/faq.component';
import { ContactComponent } from './static-pages-implementations/contact/contact.component';
import { SvgIconsModule } from '../svg';
import {
  ButtonModule,
  CheckboxModule,
  ExpansionModule,
  FormFieldModule,
} from '../simple-components';
import { ServicesComponent } from './static-pages-implementations/services/services.component';
import { ServiceTileComponent } from './static-pages-implementations/services/service-tile/service-tile.component';
import { AdminEditablePartComponent } from './static-pages-implementations/admin-editable-part-component';
import { ContactFormComponent } from './static-pages-implementations/contact/contact-form/contact-form.component';
import { ContactImageGalleryComponent } from './static-pages-implementations/contact/contact-image-gallery/contact-image-gallery.component';
import { ContactDetailsComponent } from './static-pages-implementations/contact/contact-details/contact-details.component';
import { EducationComponent } from './static-pages-implementations/education/education.component';
import { EducationTileComponent } from './static-pages-implementations/education/education-tile/education-tile.component';
import { EducationArticleComponent } from './static-pages-implementations/education-article/education-article.component';
import { EditableTableComponent } from './static-pages-implementations/education-article/editable-table/editable-table.component';
import { EditableExpansionComponent } from './static-pages-implementations/faq/editable-expansion/editable-expansion.component';
import { RouterModule } from '@angular/router';
import { DiamondGroupComponent } from './static-pages-implementations/education-article/diamond-group/diamond-group.component';
import { CustomLayoutComponent } from './static-pages-implementations/custom-layout/custom-layout.component';
import {
  AutoResizeModule,
  LazyLoadModule,
  RouterLinkByUrlModule,
} from '@thema-core/directives';
import { StaticPageContainerComponent } from './static-page-container/static-page-container.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ImageWithTextComponent } from './static-pages-implementations/custom-layout/static-parts/image-with-text/image-with-text.component';
import { ContactExpertModule } from '../contact-expert/contact-expert.module';
import { CarouselModule } from '../carousel/carousel.module';
import { PurifyModule } from '@thema-core/pipes';

@NgModule({
  declarations: [
    StaticPageDirective,
    FaqComponent,
    ContactComponent,
    ServicesComponent,
    ServiceTileComponent,
    AdminEditablePartComponent,
    ContactFormComponent,
    ContactImageGalleryComponent,
    ContactDetailsComponent,
    EducationComponent,
    EducationTileComponent,
    EducationArticleComponent,
    CustomLayoutComponent,
    EditableTableComponent,
    EditableExpansionComponent,
    DiamondGroupComponent,
    StaticPageContainerComponent,
    ImageWithTextComponent,
  ],
  imports: [
    CommonModule,
    SvgIconsModule,
    ExpansionModule,
    FormFieldModule,
    FormsModule,
    CheckboxModule,
    ButtonModule,
    RouterModule,
    LazyLoadModule,
    AutoResizeModule,
    TranslocoModule,
    RouterLinkByUrlModule,
    ContactExpertModule,
    CarouselModule,
    PurifyModule,
  ],
  exports: [
    StaticPageDirective,
    FaqComponent,
    ContactComponent,
    FormFieldModule,
    StaticPageContainerComponent,
  ],
})
export class StaticPagesLibModule {}
