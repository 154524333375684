import { APP_STORE_CONFIG, AppStoreConfig } from '@thema-core/state';

const appStoreConfig: AppStoreConfig = {
  defaultCountry: 'PL',
  defaultCurrency: 'PLN',
  COUNTRIES: [],
  CURRENCIES: [],
};

export const APP_STORE_PROVIDER = {
  provide: APP_STORE_CONFIG,
  useValue: appStoreConfig,
};
