import { Inject, Injectable } from '@angular/core';
import {
  createInitialState,
  SEO_CONFIG,
  SeoConfig,
  SeoState,
  SeoStore,
} from './seo.store';
import { AppQuery } from '../../app/app.query';
import { filter, map, take } from 'rxjs/operators';
import { SeoApiService } from './seo-api.service';
import {
  AppRoutes,
  BlogEntry,
  BlogEntryList,
  CtxContent,
  ProductList,
  ProductVM,
  SeoHandler,
} from '@thema-core/models';
import { SeoQuery } from './seo.query';
import { DOCUMENT } from '@angular/common';
import { transaction } from '@datorama/akita';
import { ROUTES, STORAGE_URL } from '@thema-core/tokens';
import { TranslocoService } from '@ngneat/transloco';
import { JsonLdService } from '../../../../../../apps/e-diamenty/src/app/common/services/json-ld.service';
import { ROUTES_CONFIG } from '../../../../../../apps/e-diamenty/src/app/configs/routes-config';

@Injectable({ providedIn: 'root' })
export class SeoService implements SeoHandler {
  private isInitialized = false;

  constructor(
    private query: SeoQuery,
    private store: SeoStore,
    private appQuery: AppQuery,
    private seoApi: SeoApiService,
    private transloco: TranslocoService,
    private jsonld: JsonLdService,
    @Inject(STORAGE_URL) private mediaStorageUrl: string,
    @Inject(DOCUMENT) private document: Document,
    @Inject(SEO_CONFIG) private seoConfig: SeoConfig,
    @Inject(ROUTES) private appRoutes: AppRoutes
  ) {
    this.query.state$.subscribe(this.update);
  }

  public init(): void {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;
    this.appQuery.availableLangs$
      .pipe(
        map((languages) => languages.map((l) => l.tag)),
        filter((al) => al.length > 0),
        take(1)
      )
      .subscribe((al) => {
        this.seoApi.updateHreflangs(al);
        this.store.update({ hrefLangs: al });
      });
  }

  public updateCanonical(url: string): void {
    this.seoApi.updateCanonical(url);
  }

  public removeCanonical(): void {
    this.seoApi.removeCanonical();
  }

  @transaction()
  public updateStaticPageSeo(pageUrl: string, title: string, description: string): void {
    const actualUrl = this.document.location.pathname;
    const origin = this.document.location.origin;
    this.store.update(() => {
      return {
        title,
        description,
        robots: 'all',
        ogTags: {
          'og:title': title,
          'og:description': description,
          'og:site_name': this.seoConfig.ogTags['og:site_name'],
          'og:type': 'article',
          'og:url': actualUrl,
          'og:image': `${origin}${this.seoConfig.ogTags['og:image']}`,
          'og:image:alt': this.seoConfig.ogTags['og:image:alt'],
          'og:image:url': `${origin}${this.seoConfig.ogTags['og:image:url']}`,
        },
      };
    });
  }

  @transaction()
  public updateBlogArticleSeo(entry: BlogEntry): void {
    // this.jsonld.setBlogPostSchema(title,)
    const actualUrl = this.document.location.pathname;
    const origin = this.document.location.origin;

    if (actualUrl.startsWith('/blog')) {
      this.jsonld.setBlogPostSchema(actualUrl, entry);
    }

    this.store.update(() => {
      return {
        title: entry.title,
        description: entry.metaDescription,
        robots: 'all',
        ogTags: {
          'og:title': entry.title,
          'og:description': entry.metaDescription,
          'og:site_name': this.seoConfig.ogTags['og:site_name'],
          'og:type': 'article',
          'og:url': actualUrl,
          'og:image': `${origin}${this.seoConfig.ogTags['og:image']}`,
          'og:image:alt': this.seoConfig.ogTags['og:image:alt'],
          'og:image:url': `${origin}${this.seoConfig.ogTags['og:image:url']}`,
        },
      };
    });
  }

  public setHomepageSeo(): void {
    this.jsonld.setHomeSchema();
    this.store.update((old) => ({
      ...createInitialState(this.document.location.origin, this.seoConfig),
      hrefLangs: old.hrefLangs,
    }));
  }

  public resetWithNewTitle(translateKey: string): void {
    this.store.update((old) => ({
      ...createInitialState(this.document.location.origin, this.seoConfig),
      title: this.transloco.translate(translateKey),
      hrefLangs: old.hrefLangs,
    }));
  }

  public setBlogSeo(entries: BlogEntryList[]): void {
    this.jsonld.setBlogSchema(entries);
  }

  public setCatalogPageSeo(
    products: ProductVM[] | ProductList[],
    context: CtxContent
  ): void {
    this.updateCanonical(ROUTES_CONFIG.diamondCatalog!);
    let imageUrl = '';
    let imageDescription = '';
    if (products.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const thumbnailAttr = products[0].attributes['thumbnail'] as any;
      if (thumbnailAttr) {
        imageUrl = this.mediaStorageUrl + '/' + thumbnailAttr.url;
        imageDescription = thumbnailAttr.description ?? '';
      } else {
        imageUrl = `${this.document.location.origin}${this.seoConfig.ogTags['og:image']}`;
        imageDescription = this.seoConfig.ogTags['og:image:alt'];
      }
    }
    this.store.update((prev) => {
      return {
        hrefLangs: prev.hrefLangs,
        title: context.title,
        description: context.metaDescription ?? context.metaTitle,
        robots: 'all',
        ogTags: {
          'og:title': context.metaTitle,
          'og:description': context.metaDescription,
          'og:image': imageUrl,
          'og:image:alt': imageDescription,
          'og:image:url': imageUrl,
          'og:site_name': this.seoConfig.ogTags['og:site_name'],
          'og:url': this.document.location.href,
          'og:type': 'website',
        },
      };
    });
  }

  public updateTags(tags: { [tagName: string]: string }): void {
    this.store.update({
      ...tags,
    });
  }

  public setProductSeo(isDiamond: boolean, product: ProductVM): void {
    const robots = isDiamond ? 'noindex' : 'all';
    const currency = this.appQuery.getValue().currency;

    this.store.update({
      title: product.short_description ?? '',
      description: product.description ?? '',
      robots,
      ogTags: this.seoApi.productToOgTags(isDiamond, product, currency),
    });

    this.jsonld.setProductSchema(product);
  }

  private update = (state: SeoState): void => {
    this.seoApi.title = state.title;
    this.seoApi.updateDescription(state.description);
    this.seoApi.updateTag({ name: 'robots', content: state.robots });
    this.seoApi.updateOgTags(state.ogTags);
  };

  private getWithOptionalPrefixSegment(
    original: string,
    prefix: string | undefined
  ): string {
    if (prefix) {
      return `${prefix}/${original}`;
    }
    return original;
  }
}
