<ng-container *transloco="let t">
  <a href="tel: {{t('common_phoneNumber')}}">
    <svg-icon key="phone" fontSize="20px" style="margin-right: 5px; color: white;"></svg-icon>
    <div class="phone-info">
      <span class="phone-label font-paragraph gray">{{t('navbar_contactExpertMessage')}}</span>
      <span class="contact-number font-link white">
          {{t('common_phoneNumber')}} Pon. — Pt. 8-16
        </span>
    </div>
  </a>
</ng-container>
