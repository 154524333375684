import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductAttributesTableComponent } from './product-attributes-table.component';
import { MatTooltipModule } from '../../tooltip/tooltip-module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ProductLibModule } from '../../product';

@NgModule({
  declarations: [ProductAttributesTableComponent],
  exports: [ProductAttributesTableComponent],
  imports: [CommonModule, MatTooltipModule, IconButtonModule, ProductLibModule],
})
export class ProductAttributesTableModule {}
