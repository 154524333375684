// tslint:disable-next-line:no-any
import { AnyObject } from '@thema-core/models';

export function omitProperties<T extends object>(
  target: T,
  propertyNames: (keyof T)[]
): Partial<T> {
  return Object.keys(target).reduce((result, currentKey) => {
    if (propertyNames.includes(currentKey as keyof T)) {
      return result;
    }

    result[currentKey] = target[currentKey];
    return result;
  }, {});
}

// tslint:disable-next-line:no-any
export function pickProperties<T extends object, U extends T = T>(
  target: U,
  propertyNames: (keyof T)[]
): T {
  return Object.keys(target).reduce((result, currentKey) => {
    if (propertyNames.includes(currentKey as keyof T)) {
      result[currentKey] = target[currentKey];
      return result;
    }
    return result;
  }, {}) as T;
}

export function updateInPlace<T>(target: T, update: Partial<T>): void {
  Object.entries(update).forEach(([key, value]) => {
    target[key] = value;
  });
}

export function assignPropertiesInPlace<T extends object>(
  target: unknown,
  source: T,
  propertyNames: (keyof T)[]
): void {
  Object.assign(target as object, pickProperties(source, propertyNames));
}

export function omitEmptyProperties<T extends object>(target: T): Partial<T> {
  return Object.entries(target).reduce((result, [currentKey, currentValue]) => {
    if (currentValue == null || isEmptyString(currentValue)) {
      return result;
    }

    result[currentKey] = currentValue;
    return result;
  }, {});
}

/*
  !This function won't work correctly with nested reference types!
 */
export function areEqualObjects(obj1: AnyObject, obj2: AnyObject): boolean {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (const key in obj1) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function isEmptyString(text: unknown): boolean {
  return typeof text === 'string' && text.trim() === '';
}

export function isEmpty(value: unknown): boolean {
  return value == null || value === '';
}
