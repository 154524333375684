import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegallySourcedDiamondsComponent } from './legally-sourced-diamonds.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [LegallySourcedDiamondsComponent],
  exports: [LegallySourcedDiamondsComponent],
  imports: [CommonModule, TranslocoModule],
})
export class LegallySourcedDiamondsModule {}
