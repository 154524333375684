import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {
  AppRoutes,
  Environment,
  OGMetaData,
  OGProductMetaData,
  ProductVM,
} from '@thema-core/models';
import { AppQuery } from '../../app/app.query';
import { Router } from '@angular/router';
import { ENVIRONMENT, ROUTES } from '@thema-core/tokens';
import { SEO_CONFIG, SeoConfig } from './seo.store';

@Injectable({
  providedIn: 'root',
})
export class SeoApiService {
  private renderer: Renderer2;
  public updateTag = this.metaService.updateTag.bind(this.metaService);

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private appQuery: AppQuery,
    private router: Router,
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(DOCUMENT) private document: Document,
    @Inject(SEO_CONFIG) private seoConfig: SeoConfig,
    @Inject(ROUTES) private appRoutes: AppRoutes,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public get title(): string {
    return this.titleService.getTitle();
  }

  public set title(newTitle: string) {
    this.titleService.setTitle((newTitle ?? '').substring(0, 54));
  }

  public updateDescription = (content: string): void => {
    this.metaService.updateTag({
      name: 'description',
      content: (content ?? '').substring(0, 155),
    });
  };

  public updateTags(tags: { name: string; content: string }[]): void {
    tags.forEach((tag) => this.updateTag(tag));
  }

  // public setSchemaForProduct(prod: ProductBase): void {
  //   const stag = this.schemaService.createProductPageLdJson({
  //     pageTitle: 'tytul',
  //     pageDescription: 'opis',
  //     product: prod,
  //   });
  //   this.document.head.appendChild(stag);
  // }

  public updateCanonical(newHref: string): void {
    const host = 'https://' + this.document.location.host;
    const canonical = host + newHref;
    const current = this.document.getElementById('link-canonical');
    if (current) {
      current.setAttribute('href', canonical);
    } else {
      const link = this.document.createElement('link');
      link.rel = 'canonical';
      link.id = 'link-canonical';
      link.setAttribute('href', canonical);
      this.document.head.appendChild(link);
    }
  }

  public removeCanonical(): void {
    const current = this.document.getElementById('link-canonical');
    if (current) {
      this.document.head.removeChild(current);
    }
  }

  public removeOgTags(): void {
    const ogTags =
      this.document.head.querySelectorAll<HTMLMetaElement>('[property^="og:"]');
    ogTags.forEach((tag) => this.metaService.removeTagElement(tag));
  }

  public updateOgTags(ogData: OGMetaData | OGProductMetaData): void {
    this.removeOgTags();
    Object.entries(ogData).forEach(([property, content]) => {
      this.metaService.addTag({ property, content });
    });
  }

  public updateHreflangs(availableLangs: string[]): void {
    const defaultLang = this.appQuery.getDefaultLang();
    availableLangs.forEach((tag) => {
      const link = this.document.createElement('link') as HTMLLinkElement;
      link.rel = 'alternate';
      link.hreflang = tag;
      link.href = `https://${this.environment.domain}${
        tag === defaultLang ? '' : '/' + tag
      }`;
      this.document.head.appendChild(link);

      if (tag === defaultLang) {
        const xDefault = this.document.createElement('link') as HTMLLinkElement;
        xDefault.rel = 'alternate';
        xDefault.hreflang = 'x-default';
        xDefault.href = `https://${this.environment.domain}`;
        this.document.head.appendChild(xDefault);
      }
    });
  }

  public productToOgTags(
    isDiamond: boolean,
    product: ProductVM,
    currency: string
  ): OGProductMetaData {
    const origin = this.document.location.origin;
    const placeholderUrl = isDiamond
      ? `${origin}${this.seoConfig.diamondFallbackImage}`
      : `${origin}${this.seoConfig.productFallbackImage}`;
    const media = product.mediaItems.length
      ? {
          url: product.mediaItems[0].url,
          description: product.mediaItems[0].description,
        }
      : { url: placeholderUrl, description: null };
    return {
      'og:url': `${origin}/${this.appRoutes.productCard}/${product.url_key}`,
      'og:site_name': this.seoConfig.ogTags['og:site_name'],
      'og:type': 'og:product',
      'og:image': media.url,
      'og:image:url': media.url,
      'og:image:alt': media.description ?? '',
      'og:description': product.description ?? '',
      'og:title': product.short_description ?? '',
      'og:product:price:amount': product.pricing.currentPriceTaxIncluded,
      'og:product:price:currency': currency,
    };
  }
}
