<div class="education-article-page">

  <div class="education-article-container">
    <header>
      <h1 #editable>
        Diamond MM to CT conversion charts
      </h1>
    </header>
    <main>

      <p #editable>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
        aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <img src="../image-placeholder.png" alt="" #editable>

      <p #editable>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
        aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <ol #editableGroup>
        <li [class]="CLASSES.EDITABLE_ITEM" *ngFor="let el of viewModelGroups[0] "></li>
      </ol>

      <ul #editableGroup>
        <li [class]="CLASSES.EDITABLE_ITEM" *ngFor="let el of viewModelGroups[1] "></li>
      </ul>

      <h3 #editable>Diamond MM to CT conversion charts</h3>
      <p #editable>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
        aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <thema-core-diamond-group></thema-core-diamond-group>

      <thema-core-editable-table #editableComponent [headers]="tableHeaders" [data]="tableContent" style="width: 100%">
      </thema-core-editable-table>

      <div class="image-row">
        <img src="../image-placeholder.png" alt="" #editable>
        <img src="../image-placeholder.png" alt="" #editable>
      </div>

      <p #editable>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
        aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <thema-core-editable-expansion #editableComponent [initialContent]="expansionContent"></thema-core-editable-expansion>

      <h3 #editable>Diamond MM to CT conversion charts</h3>
      <p #editable>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
        aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <img src="../image-placeholder.png" alt="" #editable>
    </main>
  </div>


</div>
