import { Injectable } from '@angular/core';
import { combineQueries, filterNil, Query } from '@datorama/akita';
import { ProductState, ProductStore } from './product.store';
import { map } from 'rxjs/operators';
import {
  AttributeType,
  CodeLabelValue,
  ProductConfigurationView,
  ProductMediaItem,
  ProductVM,
  SelectValue,
} from '@thema-core/models';
import { Observable } from 'rxjs';
import {
  ConfigurableAttribute,
  ConfigurableAttributeOption,
} from '@thema-core/components';

@Injectable({ providedIn: 'root' })
export class ProductQuery extends Query<ProductState> {
  public mediaItems$ = this.select('product').pipe(
    filterNil,
    map((p) => p!.mediaItems)
  ) as Observable<ProductMediaItem[]>;
  public certificateUrl$ = this.select('certificateUrl');
  public configurationsInfo$ = this.select([
    'configurationAttribute',
    'configurations',
    'currentConfigurationPath',
  ]);
  public product$ = this.select('product').pipe(filterNil) as Observable<ProductVM>;
  public stockStatus$ = this.select('product').pipe(
    map((p) => {
      return (p?.attributes.find((a) => a.code === 'stock_status')?.value as
        | SelectValue
        | undefined)?.selectKey;
    })
  );
  public isOutOfStock$ = this.stockStatus$.pipe(
    map((v) => v === 'out_of_stock' || v === 'discontinued')
  );
  public isDiamond$ = this.select('product').pipe(
    map((p) => p?.attributeSetKey === 'diamond')
  );

  public configurableAttributes$ = this.configurationsInfo$.pipe(
    map((info) => {
      return info.currentConfigurationPath?.map((part) =>
        ProductQuery.configurationToConfigurableAttribute(part)
      );
    })
  );

  public simpleAttributes$ = combineQueries([
    this.product$,
    this.configurableAttributes$,
  ]).pipe(map((v) => this.getNotConfigurableAttributes(v)));

  constructor(protected store: ProductStore) {
    super(store);
  }

  public getLeafId(): string | undefined {
    const store = this.store.getValue();
    let id: string | undefined;
    if (store.currentConfigurationPath) {
      id = store.currentConfigurationPath[
        store.currentConfigurationPath.length - 1
      ].configurationSettings?.configurations.find((c) => c.isCurrent)?.id;
    }
    return id ?? store.product?.id;
  }

  private getNotConfigurableAttributes([p, ca]): CodeLabelValue[] {
    if (!ca) {
      return p.attributes;
    }
    return p.attributes.filter((a) => !ca.some((c) => c.code === a.code));
  }

  private static configurationToConfigurableAttribute(
    configuration: ProductConfigurationView
  ): ConfigurableAttribute {
    const config = {
      configuration: configuration,
      code: configuration.configurationSettings?.configurationAttribute.code,
      label: configuration.configurationSettings?.configurationAttribute.label,
      options: configuration.configurationSettings?.configurations.map((c) =>
        ProductQuery.configurationToConfigurableAttributeOption(c, configuration)
      ),
      value: null as unknown,
    };
    config.value = config.options!.find((o) => o.isSelected)?.value;
    return config;
  }

  private static configurationToConfigurableAttributeOption(
    configuration: ProductConfigurationView,
    parent: ProductConfigurationView
  ): ConfigurableAttributeOption {
    const attr = configuration.attributes!.find(
      (a) => a.code === parent.configurationSettings?.configurationAttribute.code
    )!;
    return ProductQuery.isSelectValue(attr, attr.value)
      ? {
          label: attr.value.label as string,
          value: attr.value.selectKey,
          isSelected: configuration.isCurrent,
          swatch: attr.value.swatch,
          config: configuration,
        }
      : {
          label: attr.value as string,
          value: attr.value as string,
          isSelected: configuration.isCurrent,
          config: configuration,
        };
  }

  private static isSelectValue(
    attribute: CodeLabelValue,
    value: unknown
  ): value is SelectValue {
    return attribute.type === AttributeType.Select;
  }
}
