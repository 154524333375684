import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SnackService } from '@thema-core/components';
import { TranslocoService } from '@ngneat/transloco';
import {
  AppService,
  CartService,
  SeoService,
  UserQuery,
  WishlistService,
} from '@thema-core/state';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { AnyObject } from '@thema-core/models';

@Component({
  selector: 'ed-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private transloco: TranslocoService,
    private appService: AppService,
    private router: Router,
    private seo: SeoService,
    private cartService: CartService,
    private snack: SnackService,
    private wishlistService: WishlistService,
    private userQuery: UserQuery,
    @Inject(PLATFORM_ID) private pid: AnyObject
  ) {
    this.seo.init();
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.pid)) {
      return;
    }
    this.cartService.initializeCart();

    this.userQuery.isLoggedIn$.subscribe((v) => {
      if (v) {
        this.wishlistService.getWishlistIds();
        return;
      }
      this.wishlistService.clearWishList();
    });
  }
}
