import {
  CodeLabelValue,
  Environment,
  ProductList,
  ProductMediaItem,
  ProductMediaItemType,
  ProductVM,
  SelectValue,
} from '@thema-core/models';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@thema-core/tokens';

@Injectable({
  providedIn: 'root',
})
export class ProductMapperService {
  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  public productMapper = (product: ProductVM): ProductVM => {
    product.mediaItems.forEach(this.mapMediaItem);

    product.configurationSettings?.configurations.forEach((configuration) => {
      configuration.mediaItems?.forEach(this.mapMediaItem);
    });

    const brand = product.attributes.find((a) => a.code === 'brand_name') as
      | CodeLabelValue<SelectValue>
      | undefined;

    return {
      ...product,
      ...this.getImagesData(product),
      brand_name: brand?.value.label,
      description: product.attributes.find((a) => a.code === 'description')?.value,
      short_description: product.attributes.find((a) => a.code === 'short_description')
        ?.value,
      url_key: product.attributes.find((a) => a.code === 'url_key')?.value,
    } as ProductVM;
  };

  public productListToProductVMs(products: ProductList[]): ProductVM[] {
    return products.map((product) => {
      this.mapMediaItem(product.attributes.small_image);
      this.mapMediaItem(product.attributes.thumbnail);
      const mediaItems = [
        product.attributes.small_image ??
          product.attributes.thumbnail ??
          ProductMapperService.getPlaceholderMediaItem(),
      ];

      return {
        id: product.id,
        sku: product.sku,
        url_key: product.attributes.url_key,
        short_description: product.attributes.short_description,
        mediaItems,
        thumbnail: product.attributes.thumbnail,
        small_image: product.attributes.small_image,
        fallbackImage: mediaItems[0],
        brand_name: product.attributes.brand_name,
        pricing: product.pricing,
        attributes: [],
        configurations: product.configurations,
        isFavourite: product.isFavourite,
        shape: product.attributes.diamond_shape,
      } as ProductVM;
    });
  }

  public mapMediaItem = (mi: ProductMediaItem | undefined): void => {
    if (!mi) {
      return;
    }
    if (!ProductMapperService.isFullUrl(mi.url)) {
      mi.url = this.environment.httpSettings.mediaStorageUrl + '/' + mi.url;
    }
    mi.description = mi.description ?? '';

    // todo this mapping fires twice(?) on ssr, check it later, temporarily adding !mi.videoUrl
    // which presence implies that mi has already been mapped
    if (mi.type === ProductMediaItemType.Video && !mi.videoUrl) {
      mi.videoUrl = mi.url;
      mi.url = mi.imageId;
      if (!ProductMapperService.isFullUrl(mi.url)) {
        mi.url = this.environment.mediaIdToFullUrl(mi.imageId);
      }
    }
  };

  public static isFullUrl(url: string): boolean {
    return url.startsWith('https');
  }

  private getImagesData(product: ProductVM): {
    fallbackImage: ProductMediaItem;
    small_image: ProductMediaItem | undefined;
    thumbnail: ProductMediaItem | undefined;
  } {
    const smallImgAttr = product.attributes.find((a) => a.code === 'small_image');
    const thumbnailAttr = product.attributes.find((a) => a.code === 'thumbnail');
    const imageAttr = product.attributes.find((a) => a.code === 'image');

    const image = product.mediaItems.find((mi) => mi.imageId === imageAttr?.value);
    const small_image = product.mediaItems.find(
      (mi) => mi.imageId === smallImgAttr?.value
    );
    const thumbnail = product.mediaItems.find(
      (mi) => mi.imageId === thumbnailAttr?.value
    );

    const fallbackImage =
      image ??
      small_image ??
      product.mediaItems[0] ??
      ProductMapperService.getPlaceholderMediaItem();

    return {
      fallbackImage,
      small_image,
      thumbnail,
    };
  }

  private static getPlaceholderMediaItem(): ProductMediaItem {
    return {
      description: '',
      url: 'assets/images/product-sde-placeholder.jpg',
    } as ProductMediaItem;
  }
}
