import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PricingShort } from '@thema-core/models';

@Component({
  selector: 'thema-core-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPriceComponent {
  @Input()
  public pricing: PricingShort;
  @Input()
  public currency: string;
}
