import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AnyObject } from '@thema-core/models';
import { isPlatformBrowser } from '@angular/common';
import { APP_KEY } from '@thema-core/tokens';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  private readonly localStorage: Storage;

  readonly type: 'native' | 'memory';

  constructor(
    @Inject(APP_KEY) private appKey: string,
    @Inject(PLATFORM_ID) pid: AnyObject
  ) {
    const { storage, type } = createStorage(isPlatformBrowser(pid));
    this.localStorage = storage;
    this.type = type;
  }

  public get length(): number {
    return this.localStorage.length;
  }

  public clear(): void {
    return this.localStorage.clear();
  }

  public getItem(key: string): string | null {
    return this.localStorage.getItem(this.prefixKey(key));
  }

  public key(index: number): string | null {
    return this.localStorage.key(index);
  }

  public removeItem(key: string): void {
    return this.localStorage.removeItem(this.prefixKey(key));
  }

  public setItem(key: string, value: string): void {
    return this.localStorage.setItem(this.prefixKey(key), value);
  }

  private prefixKey(key: string): string {
    return `${this.appKey}_${key}`;
  }
}

class MemoryStorage implements Storage {
  private readonly data = new Map<string, unknown>();

  public get length(): number {
    return this.data.size;
  }

  public clear(): void {
    this.data.clear();
  }

  public removeItem(key: string): void {
    this.data.delete(key);
  }

  public setItem(key: string, value: unknown): void {
    this.data.set(key, value);
  }

  public getItem(key: string): string | null {
    const value = this.data.get(key);

    return value ? JSON.stringify(value) : null;
  }

  public key(): string | null {
    return null;
  }
}

function createStorage(isBrowser: boolean): {
  storage: Storage;
  type: 'memory' | 'native';
} {
  return isLocalStorageAvailable(isBrowser)
    ? // eslint-disable-next-line no-restricted-globals
      { storage: localStorage, type: 'native' }
    : { storage: new MemoryStorage(), type: 'memory' };
}

function isLocalStorageAvailable(isBrowser: boolean): boolean {
  try {
    // eslint-disable-next-line no-restricted-globals
    return isBrowser && typeof localStorage !== 'undefined';
  } catch {
    return false;
  }
}
