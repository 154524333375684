import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: `button[thema-core-button], button[thema-core-button-ghost],
             a[thema-core-button], a[thema-core-button-ghost], a[themaCoreButtonGhost], a[themaCoreLinkGhost],
             a[thema-core-link-external], a[thema-core-link-external-ghost]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  private _isDisabled = false;
  private _isBusy = false;
  private _ghostTheme: 'light' | 'dark' = 'dark';

  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input()
  public set isDisabled(value: boolean) {
    this._isDisabled = value;
    this.updateLayoutForDisabledState();
  }

  public get isBusy(): boolean {
    return this._isBusy;
  }

  @Input()
  public set isBusy(value: boolean) {
    this._isBusy = value;
    this.updateLayoutForDisabledState();
    this.updateLayoutForBusyState();
  }

  @Input()
  public set ghostTheme(value: 'light' | 'dark') {
    this._ghostTheme = value;
    if (value === 'light') {
      this.hostElement.classList.add('thema-button-ghost-light');
    } else {
      this.hostElement.classList.remove('thema-button-ghost-light');
    }
  }

  public hostElement: HTMLButtonElement | HTMLAnchorElement;

  constructor(@Inject(ElementRef) elementRef: ElementRef) {
    this.hostElement = elementRef.nativeElement;
    this.hostElement.classList.add('thema-button-base');

    if (
      this.hasAttributes(
        'thema-core-button-ghost',
        'app-link-ghost',
        'app-link-external-ghost'
      )
    ) {
      this.hostElement.classList.add('thema-button-ghost');
    } else {
      this.hostElement.classList.add('thema-button-flat');
    }

    // to prevent button clicks acting as 'send' on forms where it wasn't intended
    if (
      !this.hasAttributes('type') &&
      this.hasAttributes('thema-core-button', 'thema-core-button-ghost') &&
      this.hostElement['href'] === undefined
    ) {
      this.hostElement.setAttribute('type', 'button');
    }

    if (this.hasAttributes('app-link-external', 'app-link-external-ghost')) {
      this.hostElement.setAttribute('target', '_blank');
      this.hostElement.setAttribute('rel', 'noopener noreferrer nofollow');
    }
  }

  private hasAttributes(...attrs: string[]): boolean {
    return attrs.some((a) => this.hostElement.hasAttribute(a));
  }

  private updateLayoutForDisabledState(): void {
    if (this.isDisabled || this.isBusy) {
      this.hostElement.setAttribute('disabled', 'true');
    } else {
      this.hostElement.removeAttribute('disabled');
    }
  }

  private updateLayoutForBusyState(): void {
    if (this.isBusy) {
      this.hostElement.classList.add('thema-button-busy');
    } else {
      this.hostElement.classList.remove('thema-button-busy');
    }
  }
}
