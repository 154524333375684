import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@thema-core/tokens';
import { Environment } from '@thema-core/models';

@Injectable({
  providedIn: 'root',
})
export class WarnService {
  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  public developmentWarn(condition: boolean, message: string): void {
    if (!this.environment.production && condition) {
      console.warn(message);
    }
  }
}
