import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodPipe } from './method.pipe';

@NgModule({
  declarations: [MethodPipe],
  imports: [CommonModule],
  exports: [MethodPipe],
})
export class MethodModule {}
