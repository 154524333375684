import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutSectionComponent } from './about-section.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [AboutSectionComponent],
  exports: [AboutSectionComponent],
  imports: [CommonModule, RouterModule, ButtonModule, TranslocoModule],
})
export class AboutSectionModule {}
