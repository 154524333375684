import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'thema-core-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestComponent {
  @Output()
  public checkoutClicked = new EventEmitter<void>();
}
