import { AuthData } from '@thema-core/models';
import { HttpRequest } from '@angular/common/http';

export function tokenGetterFactory(
  appKey: string
): (authHeader?: HttpRequest<unknown> | string | null) => string | null {
  return (authHeader): string | null => {
    return authHeader == null
      ? getTokenFromLocalStorage(appKey)
      : extractTokenFromHeader(authHeader);
  };
}

function getTokenFromLocalStorage(appKey): string | null {
  // eslint-disable-next-line no-restricted-globals
  const auth = localStorage.getItem(`${appKey}_auth`);
  if (!auth) {
    return null;
  }
  const parsed = JSON.parse(auth) as AuthData;
  return parsed.accessToken;
}

function extractTokenFromHeader(
  headerOrRequest: HttpRequest<unknown> | string | null
): string | null {
  if (!headerOrRequest || headerOrRequest instanceof HttpRequest) {
    return null;
  }

  return headerOrRequest.split(' ')[1];
}
