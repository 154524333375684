export function createCertificateUrl(
  certificateNumber: string,
  certificateType: string
): string {
  const ct = certificateType?.toLowerCase();
  switch (ct) {
    case 'gia':
      return `https://www.gia.edu/report-check?reportno=${certificateNumber}`;
    case 'hrd':
      return `https://my.hrdantwerp.com/?id=34&record_number=${certificateNumber}`;
    case 'igi':
      return `http://www.igiworldwide.com/verify.php?r=${certificateNumber}`;
    default:
      return '';
  }
}
