export interface ContentBlockCreate {
  key: string;
  layout: string;
  title: string;
  subtitle: string;
  text: string;
  url: string;
  imageUrl: string;
  label: string;
}

export interface ContentBlock extends ContentBlockCreate {
  id: string;
}

export type ContentBlockUpdate = ContentBlockCreate;

enum HomepageContentProps {
  slide1 = 'slide1',
  slide2 = 'slide2',
  slide3 = 'slide3',
  shortcutTilesFirst1 = 'shortcutTilesFirst1',
  shortcutTilesFirst2 = 'shortcutTilesFirst2',
  popCollections1 = 'popCollections1',
  popCollections2 = 'popCollections2',
  popCollections3 = 'popCollections3',
  popCollections4 = 'popCollections4',
  shortcutTilesSecond1 = 'shortcutTilesSecond1',
  shortcutTilesSecond2 = 'shortcutTilesSecond2',
  shortcutTilesSecond3 = 'shortcutTilesSecond3',
  shortcutTilesSecond4 = 'shortcutTilesSecond4',
  shortcutTilesSecond5 = 'shortcutTilesSecond5',
  shortcutTilesSecond6 = 'shortcutTilesSecond6',
  aboutSDE1 = 'aboutSDE1',
  aboutSDE2 = 'aboutSDE2',
  shortcutTilesThird1 = 'shortcutTilesThird1',
  shortcutTilesThird2 = 'shortcutTilesThird2'
}

export type HomepageContent = {
  [key in HomepageContentProps]: ContentBlock;
}

export const homepageContentBlockKeys = Object.keys(HomepageContentProps) as (keyof HomepageContentProps)[];
