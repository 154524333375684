import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CartState } from '@thema-core/models';

export function createInitialState(): CartState {
  return {
    id: '',
    customerId: '',
    email: '',
    type: 0,
    appliedCoupons: [],
    appliedGiftCards: [],
    appliedStoreCredit: 0,
    availableShippingMethods: [],
    estimatedDelivery: '',
    subtotal: 0,
    subtotalTaxAmount: 0,
    subtotalTaxIncluded: 0,
    total: 0,
    totalTaxAmount: 0,
    advance: {
      isAvailable: false,
      isSet: false,
      amount: 0,
    },
    taxAmount: 0,
    positions: [],
    // billingAddress: createEmptyAddress(),
    // shippingAddress: createEmptyAddress(),
    paymentMethod: {
      providerCode: '',
      methodCode: '',
    },
    note: '',
    availablePaymentMethods: [],
    selectedShippingMethod: null,
    selectedPaymentMethod: null,
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart', resettable: true })
export class CartStore extends Store<CartState> {
  constructor() {
    super(createInitialState());
  }
}
