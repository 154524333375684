import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OGProductMetaData } from '@thema-core/models';

export const SEO_CONFIG = new InjectionToken<SeoConfig>('seo-config');

export interface SeoConfig {
  title: string;
  description: string;
  robots: string;
  ogTags: OGProductMetaData;
  diamondFallbackImage: string;
  productFallbackImage: string;
}

export interface SeoState {
  title: string;
  description: string;
  robots: string;
  ogTags: OGProductMetaData;
  hrefLangs?: string[];
}

export function createInitialState(origin: string, seoConfig: SeoConfig): SeoState {
  return {
    title: seoConfig.title,
    description: seoConfig.description,
    robots: 'all',
    ogTags: {
      'og:title': seoConfig.ogTags['og:title'],
      'og:description': seoConfig.ogTags['og:description'],
      'og:site_name': seoConfig.ogTags['og:site_name'],
      'og:type': 'website',
      'og:url': origin,
      'og:image': `${origin}${seoConfig.ogTags['og:image']}`,
      'og:image:alt': seoConfig.ogTags['og:image:alt'],
      'og:image:url': `${origin}${seoConfig.ogTags['og:image:url']}`,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'seo' })
export class SeoStore extends Store<SeoState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(@Inject(SEO_CONFIG) seoConfig: SeoConfig) {
    // injecting document here causes strange problem with SSR
    // and the meta data is updated correctly in through later
    // calls to seo service methods anyway, so '' can stay for now
    // super(createInitialState(''));
    super(seoConfig);
  }
}
