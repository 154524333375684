import { Directive, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { mustMatch } from '@thema-core/helpers';

@Directive({
  selector: '[appMustMatchValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MustMatchValidatorDirective, multi: true },
  ],
})
export class MustMatchValidatorDirective {
  // noinspection JSMismatchedCollectionQueryUpdate
  @Input('appMustMatchValidator') private mustMatch: string[] = [];

  public validate(formGroup: UntypedFormGroup): ValidationErrors | null {
    return mustMatch(this.mustMatch[0], this.mustMatch[1])(formGroup);
  }
}
