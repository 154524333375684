import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: 'a[routerLinkByUrl]',
})
export class RouterLinkByUrlDirective {
  private readonly nativeElement =
    inject<ElementRef<HTMLAnchorElement>>(ElementRef).nativeElement;
  private readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);

  private _url: string;

  @Input('routerLinkByUrl')
  public set url(v: string) {
    this._url = '/' + v;
    this.renderer.setAttribute(this.nativeElement, 'href', this._url);
  }

  constructor() {
    fromEvent(this.nativeElement, 'click')
      .pipe(takeUntilDestroyed())
      .subscribe(this.onClick);
  }

  public onClick = (e: Event): void => {
    e.preventDefault();
    void this.router.navigateByUrl(this._url);
  };
}
