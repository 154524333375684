import { IdValue } from './id-value';
import { CodeLabelValue } from './code-label-value';
import { Pricing, ProductListConfiguration } from './page';
import { FormControl, FormGroup } from '@angular/forms';

export interface ProductVM {
  id: string;
  sku: string;
  url_key: string;
  store?: string;
  description?: string;
  short_description?: string;
  brand_name?: string;
  small_image?: ProductMediaItem;
  thumbnail?: ProductMediaItem;
  swatch?: ProductMediaItem;
  fallbackImage: ProductMediaItem;
  isFavourite: boolean;
  attributes: CodeLabelValue[];
  pricing: Pricing;
  shape?: string;
  mediaItems: ProductMediaItem[];
  attributeSetKey?: string;
  configurations: ProductListConfiguration[] | null;
  configurationSettings?: ProductConfigurationSettingsView;
}

export interface ProductConfigurationSettingsView {
  configurationAttribute: Pick<CodeLabelValue, 'label' | 'code' | 'type'>;
  configurations: ProductConfigurationView[];
}

export interface ProductConfigurationView {
  isCurrent?: boolean;
  id: string;
  sku: string;
  type: ProductType;
  attributes?: CodeLabelValue[];
  pricing: Pricing;
  mediaItems?: ProductMediaItem[];
  configurationSettings?: ProductConfigurationSettingsView;
}

export interface SimpleProduct extends SimpleProductNew {
  id: string;
  dynamicAttributeValues: IdValue[];
  createdAt: string;
  updatedAt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stores: any;
}

export interface SimpleProductNewForm {
  attributeSetId: FormControl<string>;
  isEnabled: FormControl<boolean>;
  sku: FormControl<string>;
  mpn: FormControl<string>;
  showInFilterResults: FormControl<boolean>;
  storeAttributeValues: FormGroup;
  attributeValues: FormGroup;
}

export interface SimpleProductNewFormExtended extends SimpleProductNewForm {
  certificate: FormControl<string>;
  certificateType: FormControl<string>;
}

export interface SimpleProductNew extends SimpleProductUpdate {
  attributeSetId: string;
}

export interface SimpleProductUpdate {
  isEnabled: boolean;
  sku: string;
  mpn: string;
  showInFilterResults: boolean;
  attributeValues: IdValue[];
  storeAttributeValues: { [lang: string]: IdValue[] };
  categoryIds: string[];
  mediaItems: ProductMediaItem[];
}

export interface ProductMediaItem {
  id: string;
  type: ProductMediaItemType;
  isHidden: boolean;
  description: string;
  imageId: string;
  url: string;
  videoUrl?: string;
}

export enum ProductMediaItemType {
  Undefined,
  Photo,
  Video,
}

export interface ProductImage {
  id: string;
  extension: string;
  path: string;
}

export interface ProductListItem {
  id: string;
  type: ProductType;
  sku: string;
  mpn: string;
  attributeTypeName: string;
  productAttributes: { [code: string]: unknown };
}

export enum ProductType {
  Undefined,
  SimpleProduct,
  ConfigurableRoot,
  ConfigurableProduct,
  ProductConfiguration,
  // VirtualProduct,
  // GroupedProduct,
  // BundleProduct
}
