import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@thema-core/external';

const REFRESH_TIME_THRESHOLD = 300000; // 5 min

export const TOKEN_REFRESH_INTERCEPTOR_CONFIG =
  new InjectionToken<TokenRefresherInterceptorConfig>('token_refresh_interceptor_config');
export const AUTH_SERVICE_TOKEN = new InjectionToken<Auth>('auth_service');

export interface TokenRefresherInterceptorConfig {
  shouldPassUntouchedUrlEnds: string[];
}
interface Auth {
  refreshToken;
}

@Injectable()
export class TokenRefresherInterceptor implements HttpInterceptor {
  private tokenRefresh$: Subject<void> | null;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private auth: Auth,
    @Inject(TOKEN_REFRESH_INTERCEPTOR_CONFIG)
    private config: TokenRefresherInterceptorConfig,
    private jwt: JwtHelperService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldPassUntouched(request)) {
      return next.handle(request);
    }

    if (this.shouldRefresh()) {
      return this.refreshToken().pipe(
        switchMap(() => next.handle(this.updateToken(request)))
      );
    }

    return next.handle(request);
  }

  private refreshToken(): Observable<unknown> {
    if (this.tokenRefresh$) {
      return this.tokenRefresh$.asObservable();
    }

    this.tokenRefresh$ = new Subject();
    return this.auth.refreshToken().pipe(
      tap(() => {
        this.tokenRefresh$?.next();
        this.tokenRefresh$?.complete();
        this.tokenRefresh$ = null;
      })
    );
  }

  private updateToken = (request: HttpRequest<unknown>): HttpRequest<unknown> =>
    request.clone({
      setHeaders: { Authorization: 'Bearer ' + this.jwt.tokenGetter() },
    });

  private shouldRefresh(): boolean {
    const expirationDate = this.jwt.getTokenExpirationDate();
    if (expirationDate === null) {
      return false;
    }

    const now = new Date();

    return expirationDate.getTime() - now.getTime() < REFRESH_TIME_THRESHOLD;
  }
  private hasEndUrlToPassUntouched(req: HttpRequest<unknown>): boolean {
    return this.config.shouldPassUntouchedUrlEnds.some((urlEnding) =>
      req.url.endsWith(urlEnding)
    );
  }

  private shouldPassUntouched(req: HttpRequest<unknown>): boolean {
    return (
      !req.headers.get('Authorization') ||
      this.hasEndUrlToPassUntouched(req) ||
      this.jwt.isTokenExpired()
    );
  }
}

export const TOKEN_REFRESH_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenRefresherInterceptor,
  multi: true,
};
