import { Injectable } from '@angular/core';
import { Attachable } from '@thema-core/models';

@Injectable({
  providedIn: 'root',
})
export class RouteStrategyEmitterService {
  private nameToComponent = new Map();

  public willBeReattached(componentName: string): void {
    const component = this.nameToComponent.get(componentName) as Attachable | null;
    if (component) {
      component.willBeAttached();
    }
  }

  public willBeDetached(componentName: string): void {
    const component = this.nameToComponent.get(componentName) as Attachable | null;
    if (component) {
      component.willBeDetached();
    }
  }

  public registerReusable(component: Attachable): void {
    this.nameToComponent.set(component.constructor.name, component);
  }
}
