import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field.component';
import { InputDirective } from './input/input.directive';
import { LabelDirective } from './label/label.directive';
import { InputErrorComponent } from './input-error/input-error.component';
import { ObserversModule } from '@angular/cdk/observers';
import { InputPostfixDirective } from './input-postfix/input-postfix.directive';
import { InputPostfixDataComponent } from './input-postfix/input-postfix-data/input-postfix-data.component';
import {
  COMPONENT_APPEARANCE_DEFAULT_CONFIG,
  ComponentAppearanceConfig,
} from '@thema-core/models';
import { COMPONENT_APPEARANCE_TOKEN } from '@thema-core/tokens';
import { InputPrefixDirective } from './input-prefix/input-prefix.directive';

@NgModule({
  declarations: [
    FormFieldComponent,
    InputDirective,
    LabelDirective,
    InputErrorComponent,
    InputPostfixDirective,
    InputPostfixDataComponent,
    InputPrefixDirective,
  ],
  exports: [
    FormFieldComponent,
    InputDirective,
    LabelDirective,
    InputErrorComponent,
    InputPostfixDirective,
    InputPostfixDataComponent,
    InputPrefixDirective,
  ],
  imports: [CommonModule, ObserversModule],
})
export class FormFieldModule {
  public static forRoot(
    config: Partial<ComponentAppearanceConfig> = COMPONENT_APPEARANCE_DEFAULT_CONFIG
  ): ModuleWithProviders<FormFieldModule> {
    return {
      ngModule: FormFieldModule,
      providers: [
        {
          provide: COMPONENT_APPEARANCE_TOKEN,
          useValue: { ...COMPONENT_APPEARANCE_DEFAULT_CONFIG, ...config },
        },
      ],
    };
  }
}
