import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable, throwError } from 'rxjs';
import { catchError, mapTo, mergeMap, tap } from 'rxjs/operators';
import { AnyObject, AuthData } from '@thema-core/models';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpStatus } from '@thema-core/models';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpService,
    @Inject(PLATFORM_ID) private pid: AnyObject,
    private localStorage: LocalStorageService
  ) {}

  public register(data: {
    email: string;
    password: string;
    language: string;
  }): Observable<boolean> {
    return this.http
      .post('/Customers', data, { responseType: 'text' })
      .pipe(mergeMap(this.getAccessToken), mapTo(true));
  }

  // mapping result to true cuz we don't want auth data
  // to be passed around in the app
  public login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Observable<boolean> {
    return this.http
      .post<string>(
        '/Identity/Authenticate',
        {
          email,
          password,
        },
        {
          responseType: 'text',
        }
      )
      .pipe(mergeMap(this.getAccessToken), mapTo(true));
  }

  public changePassword(passwordChange: {
    oldPassword: string;
    password: string;
  }): Observable<unknown> {
    return this.http.put('/Identity/ChangePassword', passwordChange);
  }

  public requestPasswordReset(email: string): Observable<unknown> {
    return this.http.post('/Identity/ResetPassword', { email }, { responseType: 'text' });
  }

  public resetPassword(resetCode: string, password: string): Observable<unknown> {
    return this.http.put(
      '/Identity/ResetPassword',
      { resetCode, password },
      { responseType: 'text' }
    );
  }

  // this method is for token refresher and it needs that data
  public refreshToken(): Observable<AuthData> {
    const auth = this.localStorage.getItem('auth');
    if (!auth) {
      return throwError('Missing auth data');
    }

    const parsed = JSON.parse(auth) as AuthData;
    return this.getAccessToken(parsed.refreshToken);
  }

  public logout(): void {
    this.localStorage.removeItem('cartId');
    this.localStorage.removeItem('auth');
  }

  private getAccessToken = (codeOrRefreshToken: string): Observable<AuthData> => {
    return this.http
      .get<AuthData>('/Customers/Me/Token', {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + codeOrRefreshToken),
      })
      .pipe(
        tap((authData) => {
          this.localStorage.setItem('auth', JSON.stringify(authData));
        }),
        catchError((e: HttpErrorResponse) => {
          if (
            e.status === HttpStatus.Unauthorized ||
            e.status === HttpStatus.BadRequest
          ) {
            this.logout();
            // this.sessionStore.update({ isLoggedIn: false });
          }

          return throwError(e);
        })
      );
  };
}
