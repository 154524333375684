import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddLangDataPipe } from './add-lang-data.pipe';

@NgModule({
  declarations: [AddLangDataPipe],
  exports: [AddLangDataPipe],
  imports: [CommonModule],
})
export class AddLangDataModule {}
