import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AppState, AppStore } from './app.store';
import { Country, LocaleOptions } from '@thema-core/models';
import { pickProperties } from '@thema-core/helpers';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
  public language$ = this.select('language');
  public currency$ = this.select('currency');
  public country$ = this.select('country');
  public localeOptions$ = this.select(['language', 'currency', 'country']);
  public availableLangs$ = this.select('languages');
  public countries$ = this.select('countries');
  public currencies$ = this.select('currencies');

  constructor(protected store: AppStore) {
    super(store);
  }

  public getDefaultLang(): string {
    return this.store.getValue().defaultLanguage;
  }

  public getDeliveryCountry(): string {
    return this.store.getValue().country;
  }

  public getCurrentLocaleOptions(): LocaleOptions {
    return pickProperties(this.getValue(), ['language', 'country', 'currency']);
  }

  public getActiveLang(): string {
    return this.store.getValue().language;
  }

  public getLangId(langTag: string): string | undefined {
    return this.store.getValue().languages.find((l) => l.tag === langTag)?.id;
  }
}
