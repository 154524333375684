import {
  provideTransloco,
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@thema-core/services';
import { AppQuery } from '@thema-core/state';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private appQuery: AppQuery, private http: HttpService) {}

  public getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(
      `/Languages/${this.appQuery.getLangId(lang)}/Translations`
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        prodMode: !isDevMode(),
        missingHandler: {
          useFallbackTranslation: false,
          allowEmpty: false,
          logMissingKey: false,
        },
        fallbackLang: 'pl',
      }),
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
