import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Router,
  RouteReuseStrategy,
  Scroll,
} from '@angular/router';
import { RouteStrategyEmitterService } from '@thema-core/services';
import { isAttachableComponent } from '@thema-core/helpers';
import { RouteReusable } from '@thema-core/components';
import { ComponentRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<symbol, DetachedRouteHandle>();

  constructor(private emitter: RouteStrategyEmitterService) {}

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (
      !route.routeConfig?.component ||
      !isAttachableComponent(route.component, RouteReusable)
    ) {
      return null;
    }

    return (
      (this.storedRoutes.get(route.routeConfig.data!.detachedRouteKey) as ComponentRef<
        RouteReusable
      >) || null
    );
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const shouldAttach =
      !!route.routeConfig?.component &&
      isAttachableComponent(route.component, RouteReusable) &&
      !!this.storedRoutes.get(route.routeConfig.data!.detachedRouteKey);

    if (shouldAttach) {
      this.emitter.willBeReattached(route.routeConfig!.component!['name']);
    }
    return shouldAttach;
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const shouldDetach = isAttachableComponent(route.component, RouteReusable);
    if (shouldDetach) {
      this.emitter.willBeDetached(route.routeConfig!.component!['name']);
    }

    return shouldDetach;
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return (
      future.routeConfig === curr.routeConfig &&
      future.routeConfig?.component?.name !== 'HomepageComponent'
    );
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (!handle) {
      return;
    }
    this.storedRoutes.set(route.data!.detachedRouteKey, handle);
  }
}

export class ScrollSettings {
  /* Extend App Router Module with this class */
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.router.events
      .pipe(filter((e: unknown) => e instanceof Scroll))
      .subscribe(this.onScroll);
  }

  private onScroll = (e: Scroll) => {
    if (this.shouldSkipScrollRestoration()) {
      return;
    }

    if (e.position) {
      const position = e.position;
      // backward navigation
      // requestAnimationFrame because dom has to be fully constructed first
      window?.requestAnimationFrame(() => {
        this.viewportScroller.scrollToPosition(position);
      });
    } else if (e.anchor) {
      // anchor navigation
      this.viewportScroller.scrollToAnchor(e.anchor);
    } else {
      // forward navigation
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  };

  private shouldSkipScrollRestoration(): boolean {
    const currentNavigation = this.router.getCurrentNavigation();
    return (
      !!currentNavigation?.extras?.state?.skipScrollRestoration ||
      !!currentNavigation?.extras?.replaceUrl
    );
  }
}
