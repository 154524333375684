import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslocoModule } from '@ngneat/transloco';
import { ProductTileModule } from '../product-tile/product-tile.module';
import { SvgIconsModule } from '../svg';

@NgModule({
  declarations: [CarouselComponent],
  exports: [CarouselComponent],
  imports: [
    CommonModule,
    NguCarouselModule,
    TranslocoModule,
    ProductTileModule,
    SvgIconsModule,
  ],
})
export class CarouselModule {}
