import { ChangeDetectorRef, Component } from '@angular/core';
import { AdminEditableBaseComponent } from '../admin-editable-base.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'thema-core-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent extends AdminEditableBaseComponent {
  public lang = '';
  public initialTitles = [
    "Diamond guide - The 4 C's",
    'Diamond MM to CT conversion charts',
    'Diamond shape Guide',
    'Synthetic diamonds and simulants',
    'Certificates and conflict free diamonds - kimberly',
    'Pearl Guide',
    'Organic Stones',
    'Gemstone Guide',
    'Bracelet and Chain Guide',
    'Ring size Guide',
    'Metal Guide',
  ];

  // todo don't worry, this will not stay here
  public urls = {
    en: [
      'en/s/diamond-guide-the-4-c',
      'en/s/diamond-mm-to-ct-conversion-charts',
      'en/s/diamond-shape-guide',
      'en/s/synthetic-diamonds-and-simulants',
      'en/s/certificates-and-conflict-free-diamonds-kimberly',
      'en/s/pearl-guide',
      'en/s/organic-stones',
      'en/s/gemstone-guide',
      'en/s/bracelet-and-chain-guide',
      'en/s/ring-size-guide',
      'en/s/metal-guide',
    ],
    no: [
      's/diamantkvalitet-de-4-cer',
      's/diamant-mm-ct-omregningstabeller',
      's/diamant-slipeformer',
      's/syntetiske-diamanter-og-etterlikninger',
      's/sertifikater-og-konfliktfrie-diamanter-kimberly',
      's/perle-guide',
      's/organiske-stener-guide',
      's/edelsten-guide',
      's/armband-og-kjedevare-guide',
      's/ring-strorrelse-guide',
      's/edelmetall-guide',
    ],
  };
  constructor(private transloco: TranslocoService, cdr: ChangeDetectorRef) {
    super(cdr);
    this.lang = this.transloco.getActiveLang();
  }
}
