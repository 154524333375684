import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartHeaderComponent } from './cart-header/cart-header.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { GuestComponent } from './checkout/guest/guest.component';
import { ThankYouPageComponent } from './checkout/thank-you-page/thank-you-page.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoResizeModule } from '@thema-core/directives';
import { IconButtonModule } from '../simple-components/icon-button/icon-button.module';
import {
  AvailabilityModule,
  ButtonModule,
  ContactNumberModule,
  ExpansionGroupModule,
  FormFieldModule,
  LoginFormModule,
  ProductPriceModule,
  QuantityControlModule,
} from '../simple-components';
import { CouponCodeFormComponent } from './order-summary/coupon-code-form/coupon-code-form.component';
import { OrderSummaryPriceInfoComponent } from './order-summary/order-summary-price-info/order-summary-price-info.component';
import { OrderSummaryShippingComponent } from './order-summary/order-summary-shipping/order-summary-shipping.component';
import { OrderSummaryDeliveryTimeComponent } from './order-summary/order-summary-delivery-time/order-summary-delivery-time.component';
import { EngravingFormComponent } from './cart-item/engraving-form/engraving-form.component';
import { AddLangDataModule, GetRouteModule } from '@thema-core/pipes';
import { TableRowDirective } from './order-summary/order-summary-row/order-summary-row.directive';
import { CheckoutLoginComponent } from './checkout/checkout-login/checkout-login.component';
import { OrderSummaryPriceInfoModule } from './order-summary/order-summary-price-info/order-summary-price-info.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    CartHeaderComponent,
    CartItemComponent,
    GuestComponent,
    ThankYouPageComponent,
    CheckoutComponent,
    OrderSummaryComponent,
    CouponCodeFormComponent,
    OrderSummaryShippingComponent,
    OrderSummaryDeliveryTimeComponent,
    EngravingFormComponent,
    TableRowDirective,
    CheckoutLoginComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    IconButtonModule,
    FormsModule,
    FormFieldModule,
    ButtonModule,
    ContactNumberModule,
    RouterModule,
    ExpansionGroupModule,
    QuantityControlModule,
    ReactiveFormsModule,
    ProductPriceModule,
    AvailabilityModule,
    AutoResizeModule,
    LoginFormModule,
    AddLangDataModule,
    GetRouteModule,
    OrderSummaryPriceInfoModule,
    MatTooltipModule,
  ],
  exports: [
    CartHeaderComponent,
    CartItemComponent,
    GuestComponent,
    ThankYouPageComponent,
    CheckoutComponent,
    OrderSummaryComponent,
    CouponCodeFormComponent,
    OrderSummaryShippingComponent,
    OrderSummaryDeliveryTimeComponent,
    TableRowDirective,
    CheckoutLoginComponent,
    OrderSummaryPriceInfoComponent,
  ],
})
export class CartLibModule {}
