import { Component, Inject, ViewChild } from '@angular/core';
import { AdminEditablePartComponent } from '../../admin-editable-part-component';
import { HttpService, SnackService } from '@thema-core/services';
import { TranslocoService } from '@ngneat/transloco';
import { NgForm } from '@angular/forms';
import { IS_ADMIN_APP } from '@thema-core/tokens';

interface ContactData {
  category: string;
  content: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'thema-core-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent extends AdminEditablePartComponent {
  @ViewChild('form')
  private form: NgForm;

  public contactForm = {
    category: '',
    message: '',
    email: '',
    phone: '',
    agreement1: false,
  };
  public sending = false;
  public consentError = [false, false];

  constructor(
    private http: HttpService,
    private snack: SnackService,
    private t: TranslocoService,
    @Inject(IS_ADMIN_APP) private isAdminApp: boolean
  ) {
    super();
  }

  public onSubmit(): void {
    if (this.isAdminApp) {
      return;
    }

    if (!this.contactForm.agreement1) {
      this.consentError = [!this.contactForm.agreement1];
      return;
    }

    if (this.form.invalid) {
      this.snack.showError(this.t.translate('common_required_data_missing'));
      return;
    }

    this.sending = true;

    this.http
      .post('/Notifications/Contact', {
        category: this.contactForm.category,
        content: this.contactForm.message,
        email: this.contactForm.email,
        phone: this.contactForm.phone,
      } as ContactData)
      .subscribe(
        () => {
          this.snack.showSuccess(this.t.translate('contact_message_sent'));
          this.form.resetForm();
        },
        () => {
          this.snack.showError(this.t.translate('common_unknown_error_occurred'));
        },
        () => {
          this.sending = false;
        }
      );
  }
}
