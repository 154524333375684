import { Injectable } from '@angular/core';
import {
  AnyObject,
  CodeLabelValue,
  LocaleOptions,
  Pricing,
  ProductConfigurationSettingsView,
  ProductConfigurationView,
  ProductMediaItem,
  ProductVM,
  SelectValue,
} from '@thema-core/models';
import { ProductStore } from './product.store';
import { AppQuery } from '../../../../../state/src/lib/app/app.query';
import produce from 'immer';
import { transaction } from '@datorama/akita';
import { ProductApiService } from '../product-api.service';
import { createCertificateUrl } from '../helpers/certificate';

interface ComposedData {
  mediaItems: ProductMediaItem[];
  attributes: CodeLabelValue[];
  pricing: Pricing | undefined;
  sku: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private store: ProductStore,
    private productApi: ProductApiService,
    private appQuery: AppQuery
  ) {}

  public updateConfigurations(
    configuration?: ProductConfigurationView | ProductVM,
    parentConfigurationCode?: string
  ): void {
    const configurationSettings = configuration?.configurationSettings;
    const oldPath = this.store.getValue().currentConfigurationPath;
    let newPath = this.getConfigurationPath(configuration?.id, configurationSettings);
    if (!newPath.length) {
      if (!oldPath) {
        return;
      }

      newPath = oldPath;
    }

    let resultPath: ProductConfigurationView[];

    if (oldPath && parentConfigurationCode) {
      const configuredByIndex = oldPath.findIndex(
        (c) =>
          c.configurationSettings?.configurationAttribute.code === parentConfigurationCode
      );

      const head = oldPath.slice(0, configuredByIndex + 1);
      const sliceWithUpdatedState = produce(head, (draftHead) => {
        const fc = draftHead[
          draftHead.length - 1
        ].configurationSettings!.configurations.find((c) => c.id === configuration!.id)!;
        fc.mediaItems = configuration?.mediaItems;
        fc.attributes = this.mergeAttributes(
          fc.attributes ?? [],
          configuration?.attributes ?? []
        );
        if (configuration?.pricing) {
          fc.pricing = configuration.pricing;
        }
        if (configuration?.sku) {
          fc.sku = configuration.sku;
        }
        draftHead[draftHead.length - 1].configurationSettings!.configurations.forEach(
          (c) => (c.isCurrent = c.id === configuration!.id)
        );
      });

      if (this.isTheSamePath(sliceWithUpdatedState, newPath)) {
        resultPath = [...sliceWithUpdatedState];
      } else {
        resultPath = [...sliceWithUpdatedState, ...newPath];
      }
    } else {
      resultPath = newPath;
    }

    const newComposed = this.getComposedProductData(resultPath, oldPath);

    this.store.update((draft) => {
      if (configurationSettings) {
        draft.configurations = configurationSettings.configurations;
        draft.configurationAttribute = configurationSettings.configurationAttribute;
      }
      draft.currentConfigurationPath = resultPath;
      if (draft.product) {
        if (newComposed.mediaItems.length) {
          draft.product.mediaItems = newComposed.mediaItems;
        }
        draft.product.sku = newComposed.sku;
        draft.product.attributes = newComposed.attributes;
        if (newComposed.pricing) {
          draft.product.pricing = newComposed.pricing;
        }
        const smallImgAttr = draft.product.attributes.find(
          (a) => a.code === 'small_image'
        );
        const thumbnailAttr = draft.product.attributes.find(
          (a) => a.code === 'thumbnail'
        );
        const small_image = draft.product.mediaItems.find(
          (mi) => mi.imageId === smallImgAttr?.value
        );
        const thumbnail = draft.product.mediaItems.find(
          (mi) => mi.imageId === thumbnailAttr?.value
        );
        const brand = draft.product.attributes.find((a) => a.code === 'brand_name') as
          | CodeLabelValue<SelectValue>
          | undefined;
        draft.product.small_image = small_image;
        draft.product.brand_name = brand?.value.label;
        draft.product.description = draft.product.attributes.find(
          (a) => a.code === 'description'
        )?.value as string;
        draft.product.short_description = draft.product.attributes.find(
          (a) => a.code === 'short_description'
        )?.value as string;
        draft.product.url_key = draft.product.attributes.find((a) => a.code === 'url_key')
          ?.value as string;
        draft.product.thumbnail = thumbnail;
      }
    });
  }

  public setConfiguration(
    selectedConfig: ProductConfigurationView,
    baseConfiguration: ProductConfigurationView,
    locale?: LocaleOptions
  ): void {
    if (!locale) {
      locale = this.appQuery.getCurrentLocaleOptions();
    }

    if (selectedConfig.type === 4) {
      this.updateConfigurations(
        selectedConfig,
        baseConfiguration.configurationSettings?.configurationAttribute.code
      );
      return;
    }

    this.productApi
      .getConfiguration(selectedConfig.id, locale)
      .subscribe((configuration) => {
        this.updateConfigurations(
          configuration,
          baseConfiguration.configurationSettings?.configurationAttribute.code
        );
      });
  }

  @transaction()
  public updateProduct(product: ProductVM): void {
    this.updateConfigurations(product);
    this.store.update({
      product,
      certificateUrl: ProductService.getCertificateUrl(product.attributes),
    });
  }

  public clear(): void {
    this.store.reset();
  }

  private isTheSamePath(
    pathA: ProductConfigurationView[],
    pathB: ProductConfigurationView[]
  ): boolean {
    if (!pathA || !pathB || pathA.length !== pathB.length) {
      return false;
    }

    for (let i = 0; i < pathA.length; i++) {
      if (
        pathA[i].configurationSettings?.configurationAttribute.code !==
        pathB[i].configurationSettings?.configurationAttribute.code
      ) {
        return false;
      }
    }

    return true;
  }

  private getComposedProductData(
    newConfigPath: ProductConfigurationView[],
    oldConfigPath?: ProductConfigurationView[]
  ): ComposedData {
    const oldComposedAttrs = oldConfigPath?.reduce((result, curr) => {
      const selected = curr.configurationSettings?.configurations.find(
        (c) => c.isCurrent
      );
      return this.mergeAttributes(result, selected?.attributes ?? []);
    }, [] as CodeLabelValue[]);

    const newComposition = newConfigPath.reduce((result, pathPart) => {
      const selected = pathPart.configurationSettings?.configurations.find(
        (c) => c.isCurrent
      );
      result.mediaItems = selected?.mediaItems ?? result.mediaItems;
      result.sku = selected?.sku ?? result.sku;
      result.attributes = this.mergeAttributes(
        result.attributes ?? [],
        selected?.attributes ?? []
      );
      if (selected?.pricing) {
        result.pricing = selected.pricing;
      }
      return result;
    }, {} as ComposedData);

    const toMerge = this.store.getValue().product?.attributes;
    let nonComposedOldAttrs: CodeLabelValue[] = [];
    if (toMerge) {
      nonComposedOldAttrs = toMerge.filter(
        (a) => !oldComposedAttrs!.find((oa) => oa.code === a.code)
      );
    }
    newComposition.attributes = this.mergeAttributes(
      nonComposedOldAttrs,
      newComposition.attributes
    );
    return newComposition;
  }

  private getConfigurationPath(
    mainId?: string,
    configurationSettings?: ProductConfigurationSettingsView
  ): ProductConfigurationView[] {
    if (!configurationSettings) {
      return [];
    }
    const result: ProductConfigurationView[] = [];
    let currentItem:
      | { configurationSettings?: ProductConfigurationSettingsView }
      | undefined = { configurationSettings: configurationSettings };

    while (currentItem?.configurationSettings) {
      result.push(currentItem as ProductConfigurationView);
      currentItem = currentItem.configurationSettings?.configurations.find(
        (c) => c.configurationSettings
      );
    }

    return produce(result, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        const nextId = result[i + 1]?.id ?? mainId;
        const toBeCurrent = draft[i].configurationSettings!.configurations.find(
          (c) => c.id === nextId
        );
        if (toBeCurrent) {
          toBeCurrent.isCurrent = true;
        } else {
          const withUrl = draft[i].configurationSettings!.configurations.filter((c) =>
            c.attributes?.find((a) => a.code === 'url_key')
          )[0];
          if (withUrl) {
            withUrl.isCurrent = true;
          }
        }
      }
    });
  }

  private mergeAttributes(
    base: CodeLabelValue[],
    updated: CodeLabelValue[]
  ): CodeLabelValue[] {
    return produce(base, (draft) => {
      updated.forEach((u) => {
        const correspondingBase = draft.find((a) => a.code === u.code);
        if (correspondingBase) {
          correspondingBase.value = u.value;
        } else {
          draft.push(u);
        }
      });
    });
  }

  private static getCertificateUrl(attributes: CodeLabelValue[]): string {
    const certificateNumber = attributes.find((el) => el.code === 'diamond_certificate')
      ?.value as string;
    const certificateType = (attributes.find(
      (el) => el.code === 'diamond_certificate_type'
    )?.value as AnyObject)?.selectKey as string;
    return createCertificateUrl(certificateNumber ?? '', certificateType);
  }
}
