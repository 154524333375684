import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputPostfix]',
})
export class InputPostfixDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.tabIndex = -1;
  }
}
