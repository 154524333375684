<ng-container *transloco="let t;">
  <div *ngIf="pricing.isDiscounted"
       class="discounted-price">
    {{pricing.fullPriceTaxIncluded | currency: currency : 'symbol-narrow' : '1.0-2'}}
    <sup>{{pricing.taxRate === 0 ? t('common_productPrice_net') : t('common_productPrice_inclVat')}}</sup>
  </div>
  <div class="regular-price">{{pricing.currentPriceTaxIncluded | currency: currency : 'symbol-narrow' : '1.0-2'}}
    <sup>{{pricing.taxRate === 0 ? t('common_productPrice_net') : t('common_productPrice_inclVat')}}</sup>
  </div>
</ng-container>
