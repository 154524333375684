import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { JwtHelperService } from '@thema-core/external';
import { CustomerInfo, JwtToken } from '@thema-core/models';

export interface UserState {
  isLoggedIn: boolean;
  userInfo: Partial<CustomerInfo>;
}

export function createInitialState(isLoggedIn: boolean, email: string): UserState {
  return {
    isLoggedIn,
    userInfo: {
      firstname: '',
      lastname: '',
      email,
      phone: '',
      lines: ['', ''],
      city: '',
      postalCode: '',
      country: '',
      dateOfBirth: '',
      addresses: [],
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'login' })
export class UserStore extends Store<UserState> {
  constructor(jwtHelperService: JwtHelperService) {
    const isLoggedIn = !jwtHelperService.isTokenExpired();
    const token = jwtHelperService.decodeToken(
      jwtHelperService.tokenGetter()
    ) as JwtToken;
    const email = isLoggedIn ? token.Email : '';

    super(createInitialState(isLoggedIn, email));
  }
}
