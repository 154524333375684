import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkByUrlDirective } from './router-link-by-url.directive';



@NgModule({
  declarations: [RouterLinkByUrlDirective],
  exports: [
    RouterLinkByUrlDirective
  ],
  imports: [
    CommonModule
  ]
})
export class RouterLinkByUrlModule { }
