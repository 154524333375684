import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityComponent } from './availability.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MatTooltipModule } from '../../tooltip/tooltip-module';

@NgModule({
  declarations: [AvailabilityComponent],
  exports: [AvailabilityComponent],
  imports: [CommonModule, MatTooltipModule, IconButtonModule, TranslocoModule],
})
export class AvailabilityModule {}
