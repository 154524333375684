import { InjectionToken } from '@angular/core';
import {
  AppRoutes,
  ComponentAppearance,
  Environment,
  IAppQuery,
  InstanceType,
  ISnackService,
  LanguageUpdateService,
} from '@thema-core/models';

export const IS_ADMIN_APP = new InjectionToken<boolean>('is-admin-app');
export const INSTANCE = new InjectionToken<InstanceType>('instance_resolver');
export const STORAGE_URL = new InjectionToken<string>('storage_url');
export const ENV_FILE = new InjectionToken<Environment>('environment original content');
export const ENVIRONMENT = new InjectionToken<Environment>('environment-settings');
export const APP_KEY = new InjectionToken<string>(
  'application key to prefix local storage keys for example'
);
export const ROUTES = new InjectionToken<AppRoutes>('common-routes-names');

export const COMPONENT_APPEARANCE_TOKEN = new InjectionToken<ComponentAppearance>(
  'component_appearance_token'
);
export const LANGUAGE_UPDATE_SERVICE = new InjectionToken<LanguageUpdateService>(
  'language-update-callback'
);
export const APP_QUERY = new InjectionToken<IAppQuery>('app-query');
export const SNACK_SERVICE = new InjectionToken<ISnackService>('snack-service');
export const LOCAL_STORAGE = new InjectionToken<Storage>('local_storage');
