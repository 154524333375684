import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@thema-core/services';
import { Observable } from 'rxjs';
import { AppQuery } from '@thema-core/state';
import { ProductList } from '@thema-core/models';

const ENDPOINT_URL = '/Customers/Me/Wishlist';

@Injectable({
  providedIn: 'root',
})
export class WishlistApiService {
  constructor(private http: HttpService, private appQuery: AppQuery) {}

  public getList(): Observable<ProductList[]> {
    return this.http.get(`${ENDPOINT_URL}`, { params: this.getParams() });
  }

  public getIds(): Observable<(string | number)[]> {
    return this.http.get(`${ENDPOINT_URL}Ids`);
  }

  public addProduct(productId: string): Observable<string> {
    return this.http.post(
      `${ENDPOINT_URL}`,
      {},
      {
        params: this.http.objectToParams({ productId }),
      }
    );
  }

  public deleteProduct(productId: string | number): Observable<string> {
    return this.http.delete(`${ENDPOINT_URL}/${productId}`);
  }

  private getParams(): HttpParams {
    const currStore = this.appQuery.getValue();
    const params = this.http.objectToParams({
      store: currStore.language,
      currency: currStore.currency,
      country: currStore.country,
    });

    return params as HttpParams;
  }
}
