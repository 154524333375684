<div class="services-container">
  <header>
    <h1 #editable>Services</h1>
  </header>
  <main>
    <p #editable>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat.
    </p>
    <section *ngIf="activeUrls$ | async as url">
      <a
        [routerLinkByUrl]="url.jewelleryWorkshop"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Jewellery Workshop Pricelist"
          initialSubtitle="Jewelery needs regular inspection and maintenance">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.watchWorkshopPricelist"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Watch workshop"
          initialSubtitle="Experience of 40+ Years">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.diamondPricelist"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Diamond pricelist"
          initialSubtitle="We can help you to create uniqe jewelerry!">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.goldPricelist"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Gold pricelist"
          initialSubtitle="100% legal">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.bespoke"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Bespoke"
          initialSubtitle="Shop with us!">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.computerAidedDesign"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="computer aided"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a

        [routerLinkByUrl]="url.waxModeling"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Wax modeling"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.engraving"

      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Engraving"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.setting"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Setting"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.saleOfGoldPreciousMetal"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Sale of gold"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.saleDiamonds"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="Sale of diamonds"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.commission"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="commission"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.appraisal"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="appraisal"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>

      <a
        [routerLinkByUrl]="url.insurance"
      >
        <thema-core-service-tile
          #editableComponent
          initialTitle="insurance"
          initialSubtitle="See our whole offer">
        </thema-core-service-tile>
      </a>
    </section>

    <article class="down-p">
      <h3 #editable>Services</h3>
      <p #editable>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
        rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      </p>
    </article>
  </main>
</div>


