import { APP_STORE_PROVIDER } from './app-store-config';
import { AUTH_PROVIDERS } from './auth-config';
import { SEO_PROVIDER } from './seo-config';
import { FEATURES_PROVIDERS } from './features-config';

export const E_DIAMENTY_PROVIDERS = {
  APP_STORE_PROVIDER,
  AUTH_PROVIDERS,
  SEO_PROVIDER,
  FEATURES_PROVIDERS,
};
