import { Inject, Injectable } from '@angular/core';
import { AppState, AppStore } from './app.store';
import { Language } from '@thema-core/models';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT, Location } from '@angular/common';
import { LOCAL_STORAGE } from '@thema-core/tokens';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private appStore: AppStore,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private location: Location,
    private transloco: TranslocoService,
    private router: Router
  ) {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.update({ isSideMenuOpened: false });
    });
  }

  public update(app: Partial<AppState>): void {
    this.appStore.update(app);
  }

  public updatedDeliveryCountry(countryCode: string): void {
    this.appStore.update({ country: countryCode });
  }

  public updateLanguage(lang: string, shouldRedirect = false): void {
    this.onLanguageChange(lang, shouldRedirect);
    this.update({ language: lang });
  }

  public updateAvailableLanguages(languages: Language[]): void {
    const defaultLanguage =
      languages.find((l) => l.isDefault)?.tag ||
      languages.find((l) => l.tag === this.transloco.getDefaultLang())?.tag;
    this.update({ languages, defaultLanguage });
  }

  private onLanguageChange = (newLang: string, shouldRedirect: boolean): void => {
    this.localStorage.setItem('lang', newLang);
    this.transloco.setActiveLang(newLang);
    this.document.documentElement.lang = newLang;
    shouldRedirect && this.redirectToLang(newLang);
  };

  private redirectToLang(lang: string): Promise<boolean> | boolean {
    const finalUrl = lang === this.transloco.getDefaultLang() ? '/' : `/${lang}`;
    return finalUrl === this.location.path()
      ? false
      : this.router.navigateByUrl(finalUrl);
  }
}
