import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'thema-core-expansion-group',
  templateUrl: './expansion-group.component.html',
  styleUrls: ['./expansion-group.component.scss'],
})
export class ExpansionGroupComponent implements OnInit {
  public items: { title: string; content: string }[];
  @Input()
  public titleKey: string;
  @Input()
  public summaryClasses: string[];
  @Input()
  public paragraphClasses: string[];
  @Input()
  public contentKey: string;

  constructor(private t: TranslocoService) {}
  public ngOnInit(): void {
    this.items = this.createItems();
  }

  private createItems(): { title: string; content: string }[] {
    const result = [] as { title: string; content: string }[];
    for (let i = 1; i < 11; i++) {
      const key = `${this.titleKey}${i}`;
      const title = this.t.translate(key);

      // that means that translation doesn't exist
      if (title === key) {
        break;
      }

      const content = this.t.translate(`${this.contentKey}${i}`);
      result.push({
        title,
        content,
      });
    }

    return result;
  }
}
