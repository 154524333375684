import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordFormComponent } from './change-password-form.component';
import { FormsModule } from '@angular/forms';
import {
  ButtonModule,
  FormFieldModule,
  IconButtonModule,
  SvgIconsModule,
} from '@thema-core/components';
import {
  MustMatchValidatorModule,
  PasswordValidatorModule,
} from '@thema-core/directives';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [ChangePasswordFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    FormFieldModule,
    PasswordValidatorModule,
    IconButtonModule,
    SvgIconsModule,
    TranslocoModule,
    MustMatchValidatorModule,
    TranslocoModule,
    ButtonModule,
  ],
  exports: [ChangePasswordFormComponent],
})
export class ChangePasswordFormModule {}
