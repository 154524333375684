import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from './icon-button.component';
import { SvgIconsModule } from '../../svg/_svg-icon/svg-icons.module';

@NgModule({
  declarations: [IconButtonComponent],
  imports: [CommonModule, SvgIconsModule],
  exports: [IconButtonComponent],
})
export class IconButtonModule {}
