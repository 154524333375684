import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { isEmail } from '@thema-core/helpers';

@Directive({
  selector: '[themaCoreIsEmailValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: IsEmailValidatorDirective, multi: true },
  ],
})
export class IsEmailValidatorDirective {
  public validate(control: AbstractControl): ValidationErrors | null {
    return isEmail(control);
  }
}
