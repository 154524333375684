import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { SvgIconsModule } from '../../svg';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, FormsModule, SvgIconsModule],
  exports: [SelectComponent],
})
export class SelectModule {}
