<ng-container *transloco="let t;">

  <div style="display: flex; justify-content: center;">
    <a thema-core-button
       class="login-register-nav"
       (click)="changeMode('login')"
       [class.active]="isLoginMode">{{t('login_signIn')}}</a>
    <a thema-core-button
       class="login-register-nav"
       (click)="changeMode('register')"
       [class.active]="!isLoginMode">{{t('login_register')}}</a>
  </div>

  <ng-container *ngIf="isLoginMode; else register">
    <div class="wrapper"
         *transloco="let t;">
      <h1>{{t('login_signIn')}}</h1>
      <div>
        <form (ngSubmit)="onLogin()"
              (input)="clearErrorStatus()">
          <thema-core-form-field theme="light2"
                                 [appearance]="appearance">
            <label appLabel>{{t('login_email')}}</label>
            <input appInput
                   [(ngModel)]="loginData.email"
                   placeholder="przyklad@adres.pl"
                   autocomplete="email"
                   themaCoreIsEmailValidator
                   name="email"
                   type="email"
                   required>
          </thema-core-form-field>
          <thema-core-form-field theme="light2"
                                 [appearance]="appearance">
            <label appLabel>{{t('login_password')}}</label>
            <input appInput
                   [(ngModel)]="loginData.password"
                   placeholder="********"
                   autocomplete="current-password"
                   name="password"
                   [type]="isPasswordVisible ? 'text' : 'password'"
                   required
                   minlength="8">
            <button [thema-core-icon-button]="isPasswordVisible ? 'hide' :  'show'"
                    (click)="isPasswordVisible = !isPasswordVisible"
                    type="button"
                    iconSize="21px"
                    appInputPostfix></button>
          </thema-core-form-field>
          <div class="error-placeholder">
            <thema-core-input-error *ngIf="authError$ | async as loginErr">{{t(loginErr)}}</thema-core-input-error>
          </div>
          <a class="font-link-decorated forgot-pass"
             routerLink
             (click)="forgotPassword.emit()">{{t('login_forgotPassword')}}</a>
          <div class="actions">
            <button thema-core-button
                    type="submit"
                    [isDisabled]="(isLoggingIn$ | async) == true"
            >
              <!--              refactor-->
              {{t(
              (isLoggingIn$ | async)
                ? 'common_processing'
                : (
                  checkoutMode ? 'checkout_singInAndCheckout_button' : 'login_signIn'
                )
            )
              }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-template #register>
    <div class="wrapper">
      <h1>{{t('login_joinCustomerClub')}}</h1>
      <div>
        <form [appMustMatchValidator]="['password', 'confirm-password']"
              (ngSubmit)="onRegister()"
              (input)="clearErrorStatus()">
          <thema-core-form-field theme="light2"
                                 [appearance]="appearance">
            <label appLabel>{{t('login_email')}}</label>
            <input appInput
                   [(ngModel)]="loginData.email"
                   placeholder="przyklad@adres.pl"
                   themaCoreIsEmailValidator
                   autocomplete="email"
                   name="email"
                   type="email"
                   required>
          </thema-core-form-field>
          <thema-core-form-field theme="light2"
                                 [appearance]="appearance">
            <label appLabel>{{t('login_password')}}</label>
            <input appInput
                   [(ngModel)]="loginData.password"
                   #passInput="ngModel"
                   appPasswordValidator
                   name="password"
                   [type]="isPasswordVisible ? 'text' : 'password'"
                   placeholder="********"
                   autocomplete="new-password"
                   required
                   minlength="8">
            <button [thema-core-icon-button]="isPasswordVisible ? 'hide' :  'show'"
                    (click)="isPasswordVisible = !isPasswordVisible"
                    type="button"
                    iconSize="21px"
                    appInputPostfix></button>
            <thema-core-input-error>
              <div *ngIf="passInput.errors?.password">
                {{t('login_passwordError')}}
              </div>
              <div *ngIf="passInput.errors?.required">
                {{t('login_requiredField')}}
              </div>
            </thema-core-input-error>
          </thema-core-form-field>

          <thema-core-form-field theme="light2"
                                 [appearance]="appearance">
            <label appLabel>{{t('login_repeatPassword')}}</label>
            <input appInput
                   placeholder="********"
                   #confirmInput="ngModel"
                   [type]="isConfirmPasswordVisible ? 'text' : 'password'"
                   name="confirm-password"
                   [(ngModel)]="confirmation"
                   autocomplete="confirm-password"
                   required>
            <button [thema-core-icon-button]="isConfirmPasswordVisible ? 'hide' :  'show'"
                    (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    type="button"
                    iconSize="21px"
                    appInputPostfix></button>
            <thema-core-input-error>
              <div *ngIf="confirmInput.errors?.mustMatch">
                {{t('login_passwordsNotMatching')}}
              </div>
              <div *ngIf="confirmInput.errors?.required">
                {{t('login_requiredField')}}
              </div>
            </thema-core-input-error>
          </thema-core-form-field>
          <thema-core-checkbox *ngFor="let consent of requiredConsents"
                               checkboxPosition="top"
                               [checked]="consent.checked"
                               (checkedChange)="onRequiredConsentCheckChange(consent, $event)"
                               [class.error]="consent.hasError"
                               required="true">
            <div [innerHTML]="t(consent.translation) | purify"></div>
          </thema-core-checkbox>
          <thema-core-checkbox *ngFor="let consent of optionalConsents"
                               checkboxPosition="top"
                               [(checked)]="consent.checked">
              <div [innerHTML]="t(consent.translation) | purify"></div>
          </thema-core-checkbox>
          <div class="error-placeholder">
            <thema-core-input-error style="margin-bottom: 16px; display: block;"
                                    *ngIf="registrationError$ | async as registerErr">{{t(registerErr)}}</thema-core-input-error>
          </div>

          <div>
            <button thema-core-button
                    type="submit"
                    [isDisabled]="(isLoggingIn$ | async) == true"
            >
              {{t((isLoggingIn$ | async) ? 'common_processing' : 'login_joinCustomerClub')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ng-container>
