<form #form="ngForm"
      (ngSubmit)="onSubmit()"
      class="contact-form-container">
  <thema-core-form-field>
    <label appLabel>Temat</label>
    <input appInput
           type="text"
           name="category"
           required
           [(ngModel)]="contactForm.category"
    >
  </thema-core-form-field>

  <thema-core-form-field>
    <label appLabel>Treść wiadomości</label>
    <textarea appInput
              appAutoResize
              name="message"
              required
              [(ngModel)]="contactForm.message"
    ></textarea>
  </thema-core-form-field>

  <div class="email-and-address">

    <thema-core-form-field>
      <label appLabel>Twój adres e-mail</label>
      <input appInput
             placeholder="przyklad@adres.pl"
             type="email"
             name="email"
             required
             [(ngModel)]="contactForm.email"

      >
    </thema-core-form-field>
    <thema-core-form-field *transloco="let t;">
      <label appLabel>Numer telefonu</label>
      <input appInput
             placeholder="{{t('common_phone_placeholder')}}"
             type="tel"
             name="phone"
             [(ngModel)]="contactForm.phone"

      >
    </thema-core-form-field>
  </div>

  <article class="checkboxes-container">
    <thema-core-checkbox checkboxPosition="top"
                         [class.error]="consentError[0]"
                         [(checked)]="contactForm.agreement1"
                         (checkedChange)="consentError[0] = false"
                         style="margin-top: 15px">
      <span>Wyrażam zgodę na przetwarzanie danych osobowych, wpisanych w formularzu kontaktowym, w celu udzielenia
        odpowiedzi
        na przesłane zapytanie. Administratorem danych osobowych jest YES Biżuteria S.A. z siedzibą w Poznaniu, przy <span style="white-space: nowrap;">ul.
        Świerzawskiej 1</span>. Więcej w <a href="/informacje/polityka-prywatnosci"
                                     target="_blank">polityce prywatności</a>.</span>
    </thema-core-checkbox>
  </article>
  <button thema-core-button
          style="background: #13102f"
          type="submit"
          [isDisabled]="sending">Wyślij
  </button>
</form>
