export class SimpleCache {
  private _storage = new Map<string, { expirationTime: number; value: unknown }>();

  private static hasExpired(expirationTime: number): boolean {
    return expirationTime > 0 && expirationTime < Date.now();
  }

  public get<T>(key: string): T | null {
    const hit = this._storage.get(key);
    if (!hit || SimpleCache.hasExpired(hit.expirationTime)) {
      return null;
    }

    return hit.value as T;
  }

  public clear(): void {
    this._storage = new Map<string, { expirationTime: number; value: unknown }>();
  }

  public put(key: string, value: unknown, ttl = 0): void {
    this._storage.set(key, {
      expirationTime: ttl === 0 ? 0 : Date.now() + ttl,
      value,
    });
  }
}
