import { FactoryProvider } from '@angular/core';
import { Environment, HttpServiceConfig } from '@thema-core/models';
import { HTTP_SERVICE_CONFIG } from '@thema-core/services';
import { ENVIRONMENT } from '@thema-core/tokens';

export const HTTP_CONFIG_PROVIDER: FactoryProvider = {
  provide: HTTP_SERVICE_CONFIG,
  deps: [ENVIRONMENT],
  useFactory: (env: Environment): HttpServiceConfig => env.httpSettings,
};
