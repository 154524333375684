export function range(length: number, startFrom?: number, step = 1): number[] {
  const base = [...Array(length).keys()];
  if (!startFrom) {
    return base;
  }

  const start = startFrom || 0;
  return base.map((v) => v * step + start);
}

export function arrayRemoveInPlace<T = unknown>(
  arr: T[],
  toDelete: T,
  predicate?: (value: T) => boolean
): T {
  const index = predicate ? arr.findIndex(predicate) : arr.indexOf(toDelete);
  return arr.splice(index, 1)[0] as T;
}

export function flatSingle<T>(arr: T[][]): T[] {
  return arr.reduce((acc, val) => acc.concat(val));
}

export function sortArrayOfObjectsByConfigurationAndKey<T>(
  array: T[],
  configurationOrder: string[],
  keyToSort: string
): T[] {
  array.sort(function (objA, objB) {
    const keyA = objA[keyToSort];
    const keyB = objB[keyToSort];

    if (configurationOrder.indexOf(keyA) > configurationOrder.indexOf(keyB)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
}

export function chunkArray<T>(myArray: T[], chunkSize: number): T[][] {
  /*
  chunkArray([1,2,3,4,5,6], 3) => [ [1,2,3], [4,5,6] ]
  chunkArray([1,2,3,4,5,6], 2) => [ [1,2], [3,4], [5,6] ]
  * */

  const arrayLength = myArray.length;
  const tempArray: T[][] = [];
  for (let index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

/*
  !This function won't work correctly with nested reference types!
 */
export function areEqualArrays(arr1: unknown[], arr2: unknown[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0, length = arr1.length; i < length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
