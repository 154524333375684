<aside class="contact-details-container"
       [ngClass]="{'contact-details-admin-container': isAdminApp}"
>
  <div class="contact-details-group" style="margin-top: 20px;">
    <h4 #editable>Kontakt</h4>
    <article class="contact-details-subgroup">
      <svg-icon class="svg-container" key="phone"></svg-icon>
      <h6 #editable>INFOLINE</h6>
      <address>
        <a href="tel:{{infoline.innerText}}" #infoline #editable>
          111-222-333
        </a>
      </address>
    </article>
    <article class="contact-details-subgroup">
      <svg-icon class="svg-container" key="email"></svg-icon>
      <h6 #editable>E-MAIL</h6>
      <address>
        <a href="mailto:{{emailMeetUs.innerText}}" #emailMeetUs #editable>
          przyklad@adres.pl
        </a>
      </address>
    </article>
<!--    <div class="contact-details-subgroup">-->
<!--      <svg-icon class="svg-container" key="place"></svg-icon>-->
<!--      <h6 #editable>ADDRESS</h6>-->
<!--      <address #editable>Lorem ipsum</address>-->
<!--    </div>-->
  </div>

  <div class="contact-details-group">
    <p #editable>
      There are many variations of passages of Lorem Ipsum available, but the majority have suffered
      alteration in some
    </p>
  </div>

  <div class="contact-detais-group">
    <svg-icon fontSize="20px" key="diamond" style="position: relative; top: 2px;"></svg-icon>
    <h4 style="display: inline-block; margin-left: 5px;">Dane firmy</h4>
    <p style="padding-left: 25px; white-space: pre-line;">
      YES BIŻUTERIA S.A. <br>
      ul. Świerzawska 1<br>
      60-321 Poznań<br>
      NIP: 778-01-71-607<br>
      REGON: 630182051<br>
      KRS: 0000743417
    </p>
    <p style="white-space: pre-line;margin-top: 20px;">
      Konto bankowe<br>
      BNP Paribas Bank Polska S.A.:<br>
      92 1600 1084 1849 3081 1000 0001
    </p>
  </div>
</aside>
