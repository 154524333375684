<div class="education-container">
  <header>
    <h1 #editable>
      Education
    </h1>
  </header>
  <main>
    <p #editable>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
    </p>
    <section>
      <thema-core-education-tile
        #editableComponent
        *ngFor="let title of initialTitles; let i=index"
        [url]="urls[lang][i]"
        [initialTitle]="title"
      ></thema-core-education-tile>
    </section>

    <article class="down-p">
      <h3 #editable>Education</h3>
      <p #editable>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat.
      </p>
    </article>
  </main>
</div>

