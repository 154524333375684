import { Injectable } from '@angular/core';
import { HttpService } from '@thema-core/services';
import { Observable } from 'rxjs';
import { StaticPageTranslationWithLayout } from '@thema-core/components';

const ENDPOINT_URL = '/Content/Pages';

@Injectable({
  providedIn: 'root',
})
export class StaticPagesLibService {
  constructor(private http: HttpService) {}

  public getTranslationByUrl(
    contentPageUrl: string
  ): Observable<StaticPageTranslationWithLayout> {
    return this.http.get<StaticPageTranslationWithLayout>(`${ENDPOINT_URL}/ByUrl`, {
      params: { contentPageUrl },
    });
  }
}
