<section *transloco="let t">
  <h3 class="title">{{t('homepage_carousel_title')}}</h3>
  <ngu-carousel class="swiper"
                *ngIf="products$ | async as products"
                [inputs]="carouselTileConfig"
                [dataSource]="products">
    <button NguCarouselPrev
            class="leftRs">
      <svg-icon fontSize="30px"
                key="sliderPageChanger"></svg-icon>
    </button>
    <button NguCarouselNext
            class="rightRs">
      <svg-icon fontSize="30px"
                key="sliderPageChanger"></svg-icon>
    </button>

    <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
      <ed-product-tile [product]="item"></ed-product-tile>
    </ngu-tile>
  </ngu-carousel>

</section>

