/* tslint:disable:member-access typedef */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { SvgIconRegistry } from './registry';
import { SVG_CONFIG, SVG_ICONS_CONFIG } from './types';

type Changes = {
  color: SimpleChange;
  fontSize: SimpleChange;
  size: SimpleChange;
  key: SimpleChange;
};

let svgIdCounter = 0;

@Component({
  selector: 'svg-icon',
  template: '',
  host: {
    role: 'img',
    'aria-hidden': 'true',
  },
  styles: [
    `
      :host {
        display: inline-block;
        fill: currentColor;
        width: 1em;
        height: 1em;
        font-size: 1.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent implements OnInit, OnChanges {
  static instanceId = 0;

  @Input()
  key: string;

  @Input()
  size: 'lg' | 'md' | 'sm' | 'xs' = 'md';

  @Input()
  fontSize: string;

  @Input()
  color: string;

  private mergedConfig: SVG_CONFIG;

  constructor(
    private host: ElementRef,
    private registry: SvgIconRegistry,
    @Inject(SVG_ICONS_CONFIG) private config: SVG_CONFIG
  ) {
    SvgIconComponent.instanceId++;
    this.mergedConfig = this.createConfig();
  }

  ngOnInit() {
    this.element.setAttribute('role', 'img');
    this.render();
  }

  ngOnChanges(changes: Changes) {
    if (changes.key?.firstChange === false) {
      this.element.classList.remove(`svg-icon-${changes.key.previousValue}`);
      this.render();
    }

    if (changes.color?.currentValue) {
      this.element.style.color = this.color;
    }

    if (changes.size?.currentValue) {
      this.element.style.fontSize = this.mergedConfig.sizes[this.size];
    }

    if (changes.fontSize?.currentValue) {
      this.element.style.fontSize = this.fontSize;
      const dimension = this.fontSize === 'preserve' ? 'auto' : '1em';
      this.element.style.width = dimension;
      this.element.style.height = dimension;
    }
  }

  get element() {
    return this.host.nativeElement;
  }

  private render() {
    if (this.key && this.registry.hasSvg(this.key)) {
      this.element.classList.remove();
      this.element.classList.add(`svg-icon-${this.key}`);
      this.element.innerHTML = this.registry.get(this.key, {
        setDimensions: this.fontSize !== 'preserve',
      });
      if (this.key === 'diamondShapeMixedShapes') {
        this.element
          .querySelectorAll('[id]')
          .forEach((withId) =>
            withId.setAttribute('id', withId.id + SvgIconComponent.instanceId)
          );
        this.element.querySelectorAll('[clip-path]').forEach((el) => {
          let cp = el.getAttribute('clip-path');
          cp = cp.slice(0, cp.length - 1) + SvgIconComponent.instanceId + ')';
          el.setAttribute('clip-path', cp);
        });
      }
      this.createUniqueIdForGradient(this.element.firstElementChild);
    } else if (isDevMode()) {
      console.warn(`⚠️ ${this.key} is missing!`);
    }
  }

  private createConfig() {
    const defaults: SVG_CONFIG = {
      sizes: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem',
        lg: '2rem',
      },
    };

    return {
      ...defaults,
      ...this.config,
    };
  }

  private createUniqueIdForGradient(svg: SVGElement): void {
    // only very specific case is handled here
    // so check is also very specific

    if (
      svg.childElementCount === 2 &&
      svg.firstElementChild?.tagName === 'defs' &&
      svg.lastElementChild?.tagName === 'circle' &&
      svg.firstElementChild.firstElementChild?.tagName === 'linearGradient'
    ) {
      const id = `svgid_${svgIdCounter++}`;
      svg.firstElementChild.firstElementChild.setAttribute('id', id);
      svg.lastElementChild.setAttribute('fill', `url(#${id})`);
      return;
    }
  }
}
