import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadModule } from '@thema-core/directives';
import { HoverMenuComponent } from './hover-menu.component';

@NgModule({
  declarations: [HoverMenuComponent],
  exports: [HoverMenuComponent],
  imports: [CommonModule, RouterModule, LazyLoadModule],
})
export class HoverMenuModule {}
