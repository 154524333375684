<table #editableGroup>
  <tr>
    <th *ngFor="let header of headersWithMetadata"
        #editable
        [innerHTML]="header.text | purify"
        [colSpan]="header.colspan">
    </th>
  </tr>
  <tr *ngFor="let row  of viewModelGroups[0]">
    <td *ngFor="let el of row"
        [innerHTML]="el | purify"
        [class]="[CLASSES.EDITABLE_ITEM]">
    </td>
  </tr>
</table>

