<div class="contact-page-container"
     #pageContainer>
  <div style="grid-column: 2;">
    <header>
      <h1 #editable>Contact</h1>
<!--      <h2 #editable>Scandianavian Diamond Exchange</h2>-->
    </header>

    <thema-core-contact-form #editableComponent></thema-core-contact-form>
<!--    <thema-core-contact-image-gallery #editableComponent></thema-core-contact-image-gallery>-->
  </div>
  <thema-core-contact-details #editableComponent></thema-core-contact-details>

</div>
