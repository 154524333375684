import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Directive({
  selector: '[appLazyload]',
})
export class LazyloadDirective implements OnInit {
  private _imgUrl: string;
  // tslint:disable-next-line:no-input-rename
  @Input('appLazyload')
  public set imgUrl(v: string) {
    this._imgUrl = v;
    this.load();
  }
  public get imgUrl(): string {
    return this._imgUrl;
  }
  @Input()
  public offset = 500;

  constructor(
    @Inject(PLATFORM_ID) private pid: number,
    private image: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    if (isPlatformServer(this.pid)) {
      return;
    }

    this.renderer.setAttribute(
      this.image.nativeElement,
      'data-expand',
      this.offset.toString()
    );
  }

  private load(): void {
    this.renderer.addClass(this.image.nativeElement, 'lazyload');
    this.renderer.setAttribute(this.image.nativeElement, 'data-src', this._imgUrl);
  }
}
