import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { breakpointsFactory } from '@thema-core/helpers';
import { DataLayerService, ProductBannersService } from '@thema-core/services';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ed-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  public bps = [400, 600, 800, 1280, 1620];
  public bpToMedia = {
    twoTiles: `(min-width: ${this.bps[0]}px)`,
    threeTiles: `(min-width: ${this.bps[1]}px)`,
    fourTiles: `(min-width: ${this.bps[2]}px)`,
    threeTilesLarge: `(min-width: ${this.bps[3]}px)`,
    fourTilesExtraLarge: `(min-width: ${this.bps[4]}px)`,
  };

  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4, all: 0 },
    gridBreakpoints: {
      sm: this.bps[0],
      md: this.bps[1],
      lg: this.bps[3],
      xl: this.bps[4],
    },
    speed: 250,
    point: {
      visible: true,
    },
    slide: 1,
    touch: true,
    loop: true,
    animation: 'lazy',
  };

  public products$ = this.productBannersService.getForCarousel().pipe(
    tap((v) => {
      this.dl.onViewItemList(v);
    })
  );

  constructor(
    private bo: BreakpointObserver,
    private dl: DataLayerService,
    private productBannersService: ProductBannersService
  ) {}

  public ngOnInit(): void {
    this.bo
      .observe(breakpointsFactory(this.bps))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((b) => {
        this.carouselTileConfig.slide = this.getNumberOfSlideMove(b);
      });
  }

  public getNumberOfSlideMove(b: BreakpointState): number {
    if (b.breakpoints[this.bpToMedia.fourTilesExtraLarge]) {
      return 4;
    }
    if (b.breakpoints[this.bpToMedia.threeTilesLarge]) {
      return 3;
    }
    if (b.breakpoints[this.bpToMedia.fourTiles]) {
      return 4;
    }
    if (b.breakpoints[this.bpToMedia.threeTiles]) {
      return 3;
    }
    if (b.breakpoints[this.bpToMedia.twoTiles]) {
      return 2;
    }
    return 1;
  }
}
