import { inject, NgModule } from '@angular/core';
import {
  NavigationError,
  Router,
  RouteReuseStrategy,
  RouterModule,
  Routes,
  UrlSegment,
} from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { LocalStorageService, RouteStrategyEmitterService } from '@thema-core/services';
import { CustomRouteReuseStrategy, ScrollSettings } from '@thema-core/configurations';
import { CartGuard, IsLoggedInGuard, IsLoggedOutGuard } from '@thema-core/guards';
import { ROUTES } from '@thema-core/tokens';
import { ROUTES_CONFIG } from './configs/routes-config';

const staticPages = [
  'o-nas',
  'kontakt',
  'poradnik/o-diamentach',
  'informacje/polityka-prywatnosci',
  'informacje/bezpieczenstwo-zakupow',
  'informacje/cookies',
  'obsluga/koszty-wysylki',
  'obsluga/zwroty',
  'obsluga/czas-dostawy',
  'obsluga/formy-platnosci',
  'obsluga/gwarancja',
  'obsluga/certyfikaty-diamentow',
  'informacje/regulamin-sklepu',
  'bizuteria',
  'aktualnosci',
];
const staticPagesMatcher = (segments: UrlSegment[]) => {
  const isStaticPage =
    segments[0]?.path === 'poradnik' ||
    staticPages.some((spUrl) =>
      spUrl.split('/').every((part, index) => segments[index]?.path === part)
    );
  return isStaticPage ? { consumed: segments } : null;
};

const routes: Routes = [
  {
    runGuardsAndResolvers: 'always',
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/homepage/homepage.module').then((m) => m.HomepageModule),
      },
      {
        path: ROUTES_CONFIG.diamondCatalog,
        loadChildren: () =>
          import('./pages/diamonds-catalog/diamonds-catalog.module').then(
            (m) => m.DiamondsCatalogModule
          ),
      },
      {
        path: ROUTES_CONFIG.login,
        canLoad: [IsLoggedOutGuard],
        loadChildren: () =>
          import('./pages/login/login-page.module').then((m) => m.LoginPageModule),
      },
      {
        path: ROUTES_CONFIG.register,
        loadChildren: () =>
          import('./pages/login/login-page.module').then((m) => m.LoginPageModule),
      },
      {
        path: ROUTES_CONFIG.forgotPassword,
        loadChildren: () =>
          import('./pages/login/login-page.module').then((m) => m.LoginPageModule),
      },
      {
        path: ROUTES_CONFIG.user,
        canLoad: [IsLoggedInGuard],
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'sklep/zmien-haslo',
        loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
      },
      {
        path: `${ROUTES_CONFIG.productCard}/:productUrl`,
        loadChildren: () =>
          import('./pages/product/product.module').then((m) => m.ProductModule),
      },
      {
        path: ROUTES_CONFIG.cart,
        canLoad: [CartGuard],
        canActivate: [CartGuard],
        loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartModule),
      },
      {
        path: ROUTES_CONFIG.checkout,
        loadChildren: () =>
          import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: ROUTES_CONFIG.wishList,
        loadChildren: () =>
          import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
      },
      {
        path: ROUTES_CONFIG.blog,
        loadChildren: () => import('./pages/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        matcher: staticPagesMatcher,
        loadChildren: () =>
          import('./pages/static/static.module').then((m) => m.StaticModule),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/not-found/not-found-wrapper/not-found-wrapper.module').then(
            (m) => m.NotFoundWrapperModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      errorHandler: navigationErrorHandler,
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
      deps: [RouteStrategyEmitterService],
    },
    { provide: ROUTES, useValue: ROUTES_CONFIG },
  ],
})
export class AppRoutingModule extends ScrollSettings {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    super(router, viewportScroller);
  }
}

function navigationErrorHandler(e: NavigationError): void {
  const localStorage = inject(LocalStorageService);

  if (localStorage.type === 'memory' || !isChunkLoadingError(e.error)) {
    throw e;
  }

  const count =
    1 + Number.parseInt(localStorage.getItem('chunkLoadErrorReloadCount') ?? '0');

  localStorage.setItem('chunkLoadErrorReloadTarget', e.url);
  localStorage.setItem('chunkLoadErrorReloadCount', count.toString());

  window.location.reload();
}

function isChunkLoadingError(e: unknown): boolean {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  const failedToFetchMessage = 'Failed to fetch dynamically imported module';

  if (
    typeof e === 'string' &&
    (e.startsWith(failedToFetchMessage) || chunkFailedMessage.test(e))
  ) {
    return true;
  }

  if (e instanceof Error) {
    return (
      e.message.startsWith(failedToFetchMessage) || chunkFailedMessage.test(e.message)
    );
  }

  return false;
}
