import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderWithIconComponent } from './header-with-icon/header-with-icon.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IconButtonModule } from '../icon-button/icon-button.module';

@NgModule({
  declarations: [HeaderWithIconComponent],
  exports: [HeaderWithIconComponent],
  imports: [CommonModule, TranslocoModule, IconButtonModule],
})
export class HeaderWithIconModule {}
