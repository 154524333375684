import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore, UserState } from './user.store';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  public isLoggedIn$ = this.select('isLoggedIn');
  public loginError$ = this.selectError().pipe(map((e) => e?.login));
  public registrationError$ = this.selectError().pipe(map((e) => e?.registration));
  public resetError$ = this.selectError().pipe(map((e) => e?.reset));
  public userInfo$ = this.select('userInfo');
  public totalSpend$ = this.select('userInfo').pipe(map((val) => val?.totalSpend));
  constructor(protected store: UserStore) {
    super(store);
  }

  public isLoggedIn(): boolean {
    return this.store.getValue().isLoggedIn;
  }
}
