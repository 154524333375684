import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideInComponent } from './slide-in.component';

@NgModule({
  declarations: [SlideInComponent],
  exports: [SlideInComponent],
  imports: [CommonModule],
})
export class SlideInModule {}
