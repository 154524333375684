/* eslint-disable @typescript-eslint/ban-types */
import { Address, Attachable } from '@thema-core/models';

declare const ngDevMode: boolean;
export function isNgDevMode(): boolean {
  return ngDevMode;
}

export function isNullOrUndefined(value: unknown): boolean {
  // tslint:disable-next-line:triple-equals
  return value == undefined;
}

export function getLastElement<T>(indexable: Array<T> | string): string | T | null {
  if (indexable.length === 0) {
    return null;
  }

  return indexable[indexable.length - 1];
}

export function isAttachableComponent(
  component: Function | undefined | null | string | Attachable,
  targetComponentType: Function
): component is Attachable {
  if (typeof component === 'string') {
    return false;
  }
  return component?.['__proto__']?.name === targetComponentType.name;
}

export function createEmptyAddress(): Address {
  return {
    firstname: '',
    lastname: '',
    phone: '',
    country: '',
    city: '',
    postalCode: '',
    lines: ['', ''],
    company: '',
    vatId: '',
    notes: '',
  };
}
