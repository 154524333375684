<a [routerLink]="[('productCard'| getRoute),  product.url_key]"
   (click)="onItemSelected(product)"
   class="flex-column-centered">
  <div class="photo-container">
    <img [appLazyload]="image"
         alt="">
    <div class="actions">
      <button class="wishlist"
              [thema-core-icon-button]="product.isFavourite ? 'favouritesFull' : 'favourites'"
              (click)="onAddToWishlist($event)"></button>
    </div>
  </div>
  <span class="description">{{product.short_description}}</span>
  <thema-core-product-price [pricing]="pricing"
                            [currency]="currency$ | async"></thema-core-product-price>
</a>
