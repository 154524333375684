import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  CodeLabelValue,
  Pricing,
  ProductConfigurationView,
  ProductVM,
} from '@thema-core/models';
import produce from 'immer';

export interface ProductState {
  prevPricing?: Pricing;
  configurationAttribute?: Pick<CodeLabelValue, 'code' | 'label' | 'type'>;
  configurations?: ProductConfigurationView[];
  currentConfigurationPath?: ProductConfigurationView[];
  product?: ProductVM;
  certificateUrl?: string;
}

export function createInitialState(): ProductState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'product', producerFn: produce, resettable: true })
export class ProductStore extends Store<ProductState> {
  constructor() {
    super(createInitialState());
  }
}
