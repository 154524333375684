import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SnackCallback, SnackConfig } from '@thema-core/models';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  private messageSubject = new Subject<SnackConfig>();

  public message$ = this.messageSubject.asObservable();

  public show(config: SnackConfig): void {
    this.messageSubject.next(config);
  }

  public showInfo(
    message: string,
    callback: SnackCallback = null,
    duration = 2500
  ): void {
    this.messageSubject.next({
      message: message,
      type: 'info',
      callback: callback,
      duration: duration,
    });
  }

  public showSuccess(
    message: string,
    callback: SnackCallback = null,
    duration = 2500
  ): void {
    this.messageSubject.next({
      message: message,
      type: 'success',
      callback: callback,
      duration: duration,
    });
  }

  public showWarning(
    message: string,
    callback: SnackCallback = null,
    duration = 2500
  ): void {
    this.messageSubject.next({
      message: message,
      type: 'warning',
      callback: callback,
      duration: duration,
    });
  }

  public showError(message: string, duration: number | null = null): void {
    this.messageSubject.next({
      message: message,
      type: 'error',
      duration: duration,
      callback: null,
    });
  }
}
