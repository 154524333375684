import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurifyPipe } from './purify.pipe';

@NgModule({
  declarations: [PurifyPipe],
  exports: [PurifyPipe],
  imports: [CommonModule],
})
export class PurifyModule {}
