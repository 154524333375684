import { AuthData } from '@thema-core/models';
import { tokenGetterFactory } from '@thema-core/configurations';
// eslint-disable-next-line no-restricted-imports
import { environment } from '../../environments/environment';

// this has to be set this way because it fires before APP_INITIALIZER
const jwtWhitelist = [
  'ediamenty-api-dev.azurewebsites.net',
  'ediamenty-api-stage.azurewebsites.net',
  'ediamenty-api.azurewebsites.net',
  'localhost:5001',
];

const blacklist = [
  ...jwtWhitelist.map((domain) => `http://${domain}/Account/Token`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Account/Token`),
  ...jwtWhitelist.map((domain) => `http://${domain}/Account/Authenticate`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Account/Authenticate`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Identity/Authenticate`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Identity/ResetPassword`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Customers/Me/Token`),
  ...jwtWhitelist.map((domain) => `https://${domain}/Products/`),
];

export const JwtConfig = {
  tokenGetter: tokenGetterFactory(environment.appKey),
  whitelistedDomains: jwtWhitelist,
  blacklistedRoutes: blacklist,
};
