import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AnyObject,
  CustomerAddress,
  CustomerAddressNew,
  CustomerAddressUpdate,
  CustomerInfo,
  CustomerUpdate,
  OrderAddress,
} from '@thema-core/models';
import { UserStore } from './user.store';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpStatus } from '@thema-core/models';
import { transaction } from '@datorama/akita';
import { AppQuery } from '../app/app.query';
import { omitEmptyProperties } from '@thema-core/helpers';
import { UserApiService, AuthService, SnackService } from '@thema-core/services';
import { CartService } from '../cart/cart.service';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable, of } from 'rxjs';
import { UserQuery } from './user.query';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private authService: AuthService,
    private store: UserStore,
    private router: Router,
    private userApi: UserApiService,
    private userQuery: UserQuery,
    private appQuery: AppQuery,
    private cartService: CartService,
    private snack: SnackService,
    private t: TranslocoService,
    @Inject(PLATFORM_ID) private pid: AnyObject
  ) {}

  @transaction()
  public login(data: { email: string; password: string }): void {
    this.store.setLoading(true);
    this.authService.login(data).subscribe(
      () => {
        this.store.setLoading(false);
        this.store.update({ isLoggedIn: true });
      },
      (e: HttpErrorResponse) => {
        this.store.setLoading(false);

        if (e.status === HttpStatus.Forbidden) {
          this.store.setError({ login: this.t.translate('common_account_blocked') });
          return;
        }

        this.store.setError({
          login: this.t.translate('common_invalid_email_or_password'),
        });
      }
    );
  }

  public changePassword(oldPassword: string, password: string): void {
    this.store.setLoading(true);
    this.snack.showInfo(this.t.translate('common_processing'), null, 0);
    this.authService.changePassword({ oldPassword, password }).subscribe(
      () => {
        this.store.setLoading(false);
        this.snack.showSuccess(this.t.translate('user_profile_password_changed'));
      },
      (e: HttpErrorResponse) => {
        this.snack.showError(this.t.translate('user_profile_password_change_error'));
        this.store.setLoading(false);
        this.store.setError({
          passwordChange: e?.error?.Reference || 'Unable to change password',
        });
      }
    );
  }

  public requestPasswordReset(email: string): Observable<unknown> {
    return this.authService.requestPasswordReset(email);
  }

  public resetPassword(code: string, password: string): Observable<unknown> {
    return this.authService.resetPassword(code, password);
  }

  public getUserPersonalInfo(): void {
    this.userApi
      .get()
      .pipe(map(this.removeNullLines))
      .subscribe((userInfo: CustomerInfo) => {
        this.store.update({ userInfo });
      });
  }

  public changePersonalInfo(payload: CustomerUpdate): void {
    this.snack.showInfo(this.t.translate('common_processing'), null, 0);
    const dataToSend = {
      language: this.appQuery.getActiveLang(),
      ...omitEmptyProperties(payload),
    } as CustomerUpdate & { language: string };
    this.userApi
      .update(dataToSend)
      .pipe(map(this.removeNullLines))
      .subscribe((userInfo: CustomerInfo) => {
        this.store.update({ userInfo });
        this.snack.showSuccess(this.t.translate('user_profile_info_update'));
      });
  }

  public updateAddress(
    billingForm: OrderAddress,
    shippingForm: OrderAddress | null = null
  ): void {
    this.snack.showInfo(this.t.translate('common_processing'), null, 0);
    const areAddressesTheSame = shippingForm === null;
    let preUpdateBillingAddress: CustomerAddress | undefined;
    let preUpdateShippingAddress: CustomerAddress | undefined;
    let wereAddressesTheSame: boolean;

    this.userQuery.userInfo$
      .pipe(
        first(),
        map(this.removeNullLines),
        tap((userInfo) => {
          preUpdateBillingAddress = userInfo.addresses?.find(
            (address) => address.defaultBillingAddress === true
          );
          preUpdateShippingAddress = userInfo.addresses?.find(
            (address) => address.defaultShippingAddress === true
          );
          wereAddressesTheSame =
            preUpdateShippingAddress?.id === preUpdateBillingAddress?.id;
        }),
        map(() => {
          const billingAddress = {
            ...billingForm,
            id: preUpdateBillingAddress?.id,
            defaultBillingAddress: true,
            defaultShippingAddress: false,
          } as CustomerAddress;

          if (areAddressesTheSame) {
            billingAddress.defaultShippingAddress = true;
            return [billingAddress, null];
          }
          const shippingAddress = {
            ...shippingForm,
            id: preUpdateShippingAddress?.id,
            defaultBillingAddress: false,
            defaultShippingAddress: true,
          } as CustomerAddress;

          return [billingAddress, shippingAddress];
        }),
        switchMap(([billingAddress, shippingAddress]) => {
          /* Handle shipping address */
          let apiCall: Observable<CustomerAddress | null> = of(null);

          if (areAddressesTheSame && wereAddressesTheSame) {
            // 1) Update not needed, it is the same object as billing address
          }
          if (areAddressesTheSame && !wereAddressesTheSame) {
            // 2) Shipping address is merged to billing address object so delete it
            apiCall = this.userApi.deleteAddress(preUpdateShippingAddress!.id);
          }
          if (!areAddressesTheSame) {
            apiCall = wereAddressesTheSame
              ? // 3) Shipping address was the same as billing address so it need to be created
                this.userApi.addAddress(shippingAddress as CustomerAddressNew)
              : // 4) Shipping address was separated object so only update needed
                this.userApi.updateAddress(shippingAddress as CustomerAddressUpdate);
          }
          return combineLatest([of(billingAddress), apiCall]);
        }),
        switchMap(([billingAddress]) =>
          preUpdateBillingAddress
            ? this.userApi.updateAddress(billingAddress as CustomerAddressUpdate)
            : this.userApi.addAddress(billingAddress as CustomerAddressNew)
        ),
        switchMap((billingAddress) => {
          const dataForUserInfoUpdate = {
            language: this.appQuery.getActiveLang(),
            ...omitEmptyProperties(billingAddress),
          } as CustomerUpdate & { language: string };
          return this.userApi.update(dataForUserInfoUpdate);
        })
      )
      .subscribe((userInfo) => {
        this.store.update({ userInfo });
        this.snack.showSuccess(this.t.translate('user_profile_info_update'));
      });
  }

  public register(data: { email: string; password: string }): void {
    this.store.setLoading(true);
    this.authService
      .register({
        ...data,
        language: this.appQuery.getActiveLang(),
      })
      .subscribe(
        () => {
          this.store.setLoading(false);
          this.store.update({ isLoggedIn: true });
        },
        (e: HttpErrorResponse) => {
          this.store.setLoading(false);
          const error = JSON.parse(e.error);
          if (!error) {
            return;
          }
          this.store.setError({
            registration: this.t.translate('common_' + error['Reference']),
          });
        }
      );
  }

  public logout(): void {
    this.store.setLoading(true);
    this.authService.logout();
    this.store.update({
      isLoggedIn: false,
      userInfo: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        lines: ['', ''],
        city: '',
        postalCode: '',
        country: '',
        dateOfBirth: '',
        addresses: [],
      },
    });
    this.cartService.startNewCart();
    this.store.setLoading(false);
    void this.router.navigate(['/']);
  }

  public setError<T>(error: T): void {
    this.store.setError(error);
  }

  private removeNullLines(info: CustomerInfo): CustomerInfo {
    const lines = info.lines ?? ['', ''];
    return { ...info, lines: [...lines] };
  }
}
