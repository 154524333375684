import { Injectable } from '@angular/core';
import { HttpService } from '@thema-core/services';
import {
  CtxContent,
  LocaleOptions,
  PageOptions,
  ProductPage,
} from '@thema-core/models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

export const CATALOG_DEFAULT_PAGE_SIZE = 48;

@Injectable({
  providedIn: 'root',
})
export class CatalogApiService {
  constructor(private http: HttpService, private t: TranslocoService) {}

  public getPage(
    baseUrl: string,
    query: { groupName: string; activeFilters: string[] }[] | null,
    pagination: PageOptions,
    options: LocaleOptions
  ): Observable<ProductPage> {
    return this.fetchPage(
      baseUrl,
      pagination,
      options,
      this.flattenGroupsFilters(query ?? [])
    );
  }

  public search(
    searchString: string,
    localeOptions: LocaleOptions
  ): Observable<ProductPage> {
    return this.http
      .get('/Products/BySearchString', {
        params: new HttpParams({
          fromObject: {
            searchString,
            store: localeOptions.language,
            currency: localeOptions.currency,
            country: localeOptions.country.toUpperCase(),
            maxResults: CATALOG_DEFAULT_PAGE_SIZE.toString(),
          },
        }),
      })
      .pipe(
        map((d: Partial<ProductPage>) => {
          return {
            filters: d.filters || [],
            ctxContent: ({
              content: '',
              breadcrumbs: [],
              title: `${this.t.translate('search_results_for')} "${searchString}"`,
            } as unknown) as CtxContent,
            products: d.products,
            activeFilters: [],
            pagination: {
              offset: 0,
              pageSize: CATALOG_DEFAULT_PAGE_SIZE,
              total: d.products?.length ?? 0,
            },
          } as ProductPage;
        })
      );
  }

  private fetchPage(
    baseUrl: string,
    page: PageOptions,
    options: LocaleOptions,
    filters?: Record<string, string>
  ): Observable<ProductPage> {
    const params = this.http.objectToParams({
      ...page,
      store: options.language,
      country: options.country.toUpperCase(),
      currency: options.currency,
      filterGroup: 'DefaultFilterGroup',
    });
    const urlParam = this.createUrlParam(baseUrl, filters);
    return this.http.get<ProductPage>(`/Products/ByQueryWithContext?url=${urlParam}`, {
      params,
    });
  }

  private createUrlParam(baseUrl: string, filters?: { [key: string]: string }): string {
    if (!filters || Object.keys(filters).length === 0) {
      return baseUrl;
    }

    return encodeURIComponent(
      `${baseUrl}?${Object.entries(filters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    );
  }

  private flattenGroupsFilters(
    groups: { groupName: string; activeFilters: string[] }[]
  ): { [groupName: string]: string } {
    return groups.reduce((acc, curr) => {
      acc[curr.groupName] = curr.activeFilters.join(',');
      return acc;
    }, {} as { [groupName: string]: string });
  }
}
