import { Inject, Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserQuery } from '@thema-core/state';
import { take, tap } from 'rxjs/operators';
import { GetRoutePipe } from '@thema-core/pipes';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard  {
  constructor(
    private userQuery: UserQuery,
    private router: Router,
    private getRoute: GetRoutePipe,
  ) {}

  public isLoggedIn = (): Observable<boolean> => this.userQuery.isLoggedIn$.pipe(take(1));

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(route.url);
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(segments);
  }

  private can = (segments: UrlSegment[]): Observable<boolean> =>
    this.isLoggedIn().pipe(
      tap((isLoggedIn) => {
        if (!isLoggedIn && (!segments[0] || segments[0].path !== 'login')) {
          void this.router.navigate([
            this.getRoute.transform(`login`),
          ]);
        }
      })
    );
}
