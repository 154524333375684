import { WholeCartUpdate } from './cart';
import { OrderAddress } from './customer-address';
import { ProductType, ProductVM } from './product';

export interface OrderToPlace extends WholeCartUpdate {
  cartId: string;
  store: string;
  currency: string;
  country: string;
  advanceOnly: boolean;
  shippingMethodId: string;
  paymentMethodId: string;
  confirmationUrl: string;
}

export interface OrderPlacedResponse {
  sessionId: string;
  paymentId: string;
  paymentProvider: string;
  providerTransactionId: string;
  amount: number;
  currency: string;
  orderId: string;
  firstname: string;
  lastname: string;
  email: string;
  description: string;
  confirmationUrl: string;
  providerPaymentUrl: string;
  providerProperty: string;
}

export interface OrderNew {
  quoteId: string;
  store: string;
  countryCode: string;
  currencyCode: string;
  locale: string;
}

export interface OrderKlarna {
  quoteId: string;
  store: string;
  countryCode: string;
  currencyCode: string;
  termsUrl: string;
  checkoutUrl: string;
  confirmationUrl: string;
}

export interface OrderKlarnaResponse {
  paymentProvider: string;
  providerTransactionId: string;
  amount: number;
  currency: string;
  orderId: string;
  firstname: string;
  lastname: string;
  email: string;
  description: string;
  confirmationUrl: string;
  providerProperty: string;
}

interface OrderBase {
  id: string;
  orderNumber: string;
  customerId: string;
  email: string;
  status: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  total: number;
  currencyCode: string;
  totalTaxIncluded: number;
  billingAddress: OrderAddress;
  shippingAddress: OrderAddress;
  createdAt: string;
}

export interface Order extends OrderBase {
  phone: string;
  tracking: string;
  provider: string;
  itemCount: number;
  billingName?: string;
  shippingName?: string;
  country: string;
  modifiedAt: string;
}

export interface OrderDetailed extends OrderBase {
  quoteId: string;
  positions: OrderPosition[];
  discount: number;
  totalTaxAmount: number;
  shippingMethod: ShippingMethod;
  paymentMethod: PaymentMethod;
  methodCode?: string;
  comments: OrderComment[];
  shipments: OrderShipment[];
  note: string;
  payments: OrderPayment[];
  remainingPayment: number;
  amountPaid: number;
  returns: OrderRefund[];
  store: string;
}

export interface OrderPayment {
  paymentId: string;
  method: number;
  providerCode: string;
  description: string;
  balance: number;
}

export interface ShippingMethod {
  shippingMethodId: string;
  taxRate: number;
  price: number;
  taxAmount: number;
  title: string;
  priceTaxIncluded: number;
  label: string;
}

export interface PaymentMethod {
  id: string;
  code: string;
  label: string;
  description: string;
  taxRate: number;
  price: number;
  taxAmount: number;
  priceTaxIncluded: number;

  // todo verify if that's correct
  methodCode?: unknown;
  title?: string;
}

export interface OrderPosition {
  items: ShippingPosition;
  positionId: string;
  quantity: number;
  unitTaxRate: number;
  unitPrice: number;
  unitTaxAmount: number;
  unitPriceTaxIncluded: number;
  price: number;
  taxAmount: number;
  totalDiscount: number;
  priceTaxIncluded: number;
  notes: { [key: string]: string };
  product: OrderProduct;
}

export type OrderProduct = Omit<ProductVM, 'pricing' | 'attributes'> & {
  type: ProductType;
  attributeValues: { [key: string]: string };
};

export interface ShippingPosition {
  [key: string]: number;
}

export interface OrderShipmentNew {
  provider: string;
  tracking: string;
  positions?: ShippingPosition;
}

export interface OrderComment {
  commentId: string;
  username: string;
  message: string;
  createdAt: string;
  type?: OrderMessageType;
  isVisible?: boolean;
  notifyCustomer?: boolean;
}

export interface OrderCommentNew extends OrderCommentUpdate {
  type: OrderMessageType;
}

export interface OrderCommentUpdate {
  message: string;
  notifyCustomer: boolean;
  isVisible: boolean;
}

export interface OrderShipment {
  createdAt: string;
  direction: number;
  positions: ShippingPosition;
  provider: string;
  shipmentId: string;
  tracking: string;
}

export enum OrderMessageType {
  Customer,
  Internal,
  Other,
}

export enum OrderPaymentStatus {
  Undefined,
  'Not paid',
  Expired,
  Cancelled,
  PartiallyPaid,
  Paid,
  PartiallyRefunded,
  Refunded,
}

export enum OrderStatus {
  Undefined,
  Created,
  New,
  OnHold,
  Canceled,
  Accepted,
  Invoiced,
  Shipped,
  Received,
  Returned,
  Complete,
  Closed,
  Archived,
}

export enum OrderItemStatus {
  Undefined,
  Created,
  InStock,
  OutOfStock,
  Backorder,
  Cancelled,
  Shipped,
  Received,
  Returned,
}

export const OrderBaseProductAttributesCodes = [
  'short_description',
  'small_image',
  'item_condition',

  'metal',
  'diamond',
  'ring_size',
  'gold_assay',
  'center_stone_weight',
  'bracelet_length',
  'engraving',

  'diamond_color',
  'diamond_weight_ct',
  'diamond_clarity',
  'diamond_certificate',
  'diamond_certificate_type',
];

export interface OrderRefundInput {
  comment: string;
  positions: { [id: string]: number };
  inventoryReturn: boolean;
}

export interface OrderRefund {
  id: string;
  comment: string;
  positions: { [key: string]: number };
  createdAt: string;
}

export interface OrderRefundResponse {
  [id: string]: number;
}
