export interface Country {
  countryCode: string;
  name: string;
  defaultCurrency: string;
  defaultStore: string;
}

export const COUNTRIES: Country[] = [
  { countryCode: 'se', name: 'country_sweden', defaultCurrency: 'EUR', defaultStore: '' },
  { countryCode: 'no', name: 'country_norway', defaultCurrency: 'NOK', defaultStore: '' },
  {
    countryCode: 'fi',
    name: 'country_finland',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  {
    countryCode: 'dk',
    name: 'country_denmark',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  {
    countryCode: 'gb',
    name: 'country_greatBritain',
    defaultCurrency: 'GBP',
    defaultStore: '',
  },
  {
    countryCode: 'de',
    name: 'country_germany',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  {
    countryCode: 'nl',
    name: 'country_netherlands',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  {
    countryCode: 'at',
    name: 'country_austria',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  {
    countryCode: 'ch',
    name: 'country_switzerland',
    defaultCurrency: 'EUR',
    defaultStore: '',
  },
  { countryCode: 'us', name: 'country_usa', defaultCurrency: 'USD', defaultStore: '' },
];

export const CURRENCIES = COUNTRIES.reduce((result: string[], el: Country) => {
  if (result.includes(el.defaultCurrency)) {
    return result;
  }
  return [...result, el.defaultCurrency];
}, []).sort();
