import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Observable, of } from 'rxjs';
import { CartService, CartQuery } from '@thema-core/state';
import { catchError, map, skipWhile, take, tap } from 'rxjs/operators';
import { SnackService } from '@thema-core/services';
import { TranslocoService } from '@ngneat/transloco';
import { AppQuery } from '@thema-core/state';
import { AnyObject } from '@thema-core/models';
import { isPlatformServer } from '@angular/common';
import { combineQueries } from '@datorama/akita';
import { LocalStorageService } from '@thema-core/services';

@Injectable({
  providedIn: 'root',
})
export class CartGuard  {
  constructor(
    private cartService: CartService,
    private cartQuery: CartQuery,
    private snack: SnackService,
    private transloco: TranslocoService,
    private appQuery: AppQuery,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) private pid: AnyObject
  ) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.fetchAndValidate();
  }

  public canLoad(): Observable<boolean> | boolean {
    return this.fetchAndValidate();
  }

  private fetchAndValidate(): Observable<boolean> | boolean {
    if (isPlatformServer(this.pid)) {
      return true;
    }
    this.cartService.initializeCart();
    return this.getResultBaseOnCartQuery().pipe(catchError(() => of(false)));
  }

  private getResultBaseOnCartQuery(): Observable<boolean> {
    return combineQueries([this.cartQuery.id$, this.cartQuery.items$]).pipe(
      skipWhile(([id]) => !id),
      take(1),
      map(([, items]) => items.length > 0),
      tap((can) => {
        if (can) {
          return;
        }

        this.snack.show({
          message: this.transloco.translate('cart_is_empty'),
          type: 'info',
          duration: 3000,
        });
      })
    );
  }
}
