import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionGroupComponent } from './expansion-group/expansion-group.component';
import { ExpansionModule } from '../expansion/expansion.module';

@NgModule({
  declarations: [ExpansionGroupComponent],
  exports: [ExpansionGroupComponent],
  imports: [CommonModule, ExpansionModule],
})
export class ExpansionGroupModule {}
