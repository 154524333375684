import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionComponent } from './expansion.component';

@NgModule({
  declarations: [ExpansionComponent],
  exports: [ExpansionComponent],
  imports: [CommonModule],
})
export class ExpansionModule {}
