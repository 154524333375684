import { AbstractControl, ValidationErrors } from '@angular/forms';

export function isValidPassword(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;
  }

  const actualValue = control.value.trim();

  if (!MIN_ONE_ALPHA_CHARACTER_PATTERN.test(actualValue)) {
    return { password: true, errorDescription: 'input_error_min_one_alpha' };
  }

  if (!MIN_ONE_DIGIT_PATTERN.test(actualValue)) {
    return { password: true, errorDescription: 'input_error_min_one_digit' };
  }

  if (actualValue.length < 8) {
    return { password: true, errorDescription: 'input_error_min_chars' };
  }

  return null;
}

function isEmptyInputValue(value: string | Array<unknown> | null | undefined): boolean {
  if (value instanceof Array) {
    return value.length === 0;
  }
  return value === null || value === undefined || value.trim().length === 0;
}

const MIN_ONE_DIGIT_PATTERN = /.*[0-9].*/;
const MIN_ONE_ALPHA_CHARACTER_PATTERN = /.*[a-zA-Z].*/;
