import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SeoStore, SeoState } from './seo.store';

@Injectable({ providedIn: 'root' })
export class SeoQuery extends Query<SeoState> {
  public state$ = this.select();

  constructor(protected store: SeoStore) {
    super(store);
  }
}
