import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsEmailValidatorDirective } from './is-email-validator.directive';



@NgModule({
  declarations: [IsEmailValidatorDirective],
  imports: [
    CommonModule
  ],
  exports: [IsEmailValidatorDirective]
})
export class IsEmailValidatorModule { }
