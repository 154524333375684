import { Environment } from '@thema-core/models';
import { VARS } from './environment.vars';

export const baseEnvironment: Environment = {
  ...VARS,
  appKey: 'ediam',
  baseUrl: 'http://localhost:4200',
  domain: 'ediamenty-client-dev.azurewebsites.net',
  clientUrl: '',
  production: false,
  storeName: '',
  logoPath: '',
  httpSettings: {
    enableDevFunctions: false,
    mediaStorageUrl: '',
    apiUrl: '',

    apiUrls: {
      dev: 'https://ediamenty-api-stage.azurewebsites.net',
      stage: 'https://ediamenty-api-stage.azurewebsites.net',
      prod: 'https://ediamenty-api.azurewebsites.net',
    },
    mediaStorageUrls: {
      dev: 'https://ediamentydev.blob.core.windows.net',
      stage: 'https://ediamentystagecdn.blob.core.windows.net',
      prod: 'https://ediamentyprodcdn.blob.core.windows.net',
    },
    clientUrls: {
      dev: 'https://ediamenty-client-dev.azurewebsites.net',
      stage: 'https://ediamenty-client-stage.azurewebsites.net',
      prod: 'https://e-diamenty.pl',
    },
  },
  mediaIdToFullUrl(mediaId: string, extension = '.jpg'): string {
    return '';
  },
  mediaUrlPathToFullUrl: function (mediaUrl): string {
    return `${this.httpSettings.mediaStorageUrl}/${mediaUrl}`;
  },
  menuJsonUrl: function (lang = 'pl'): string {
    return `${this.httpSettings.mediaStorageUrl}/public/content/json/menu_${lang}.json`;
  },
  footerJsonUrl: function (lang = 'pl'): string {
    return `${this.httpSettings.mediaStorageUrl}/public/content/json/footer_${lang}.json`;
  },
};
