import { ContactComponent } from './static-pages-implementations/contact/contact.component';
import { FaqComponent } from './static-pages-implementations/faq/faq.component';
import { ServicesComponent } from './static-pages-implementations/services/services.component';
import { EducationComponent } from './static-pages-implementations/education/education.component';
import { EducationArticleComponent } from './static-pages-implementations/education-article/education-article.component';
import { CustomLayoutComponent } from './static-pages-implementations/custom-layout/custom-layout.component';

export interface layout {
  name: string;
  //I dont know how to specify -> Component, which extend from AdminEditableBase :TODO
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

export interface LayoutHandler {
  isPriceList: boolean;
  hasCustomLayout: boolean;
}

export const LAYOUTS: layout[] = [
  {
    name: 'contact',
    component: ContactComponent,
  },
  {
    name: 'faq',
    component: FaqComponent,
  },
  {
    name: 'services',
    component: ServicesComponent,
  },
  {
    name: 'education',
    component: EducationComponent,
  },
  {
    name: 'custom',
    component: CustomLayoutComponent,
  },
  {
    name: 'education-article',
    component: EducationArticleComponent,
  },
];

export const hasCustomLayout = (layoutName: string): boolean => {
  const customLayouts = LAYOUTS.filter(
    (lay) => lay.component === CustomLayoutComponent
  ).map((lay) => lay.name);

  return customLayouts.includes(layoutName);
};

export const layoutHandler = (layoutName: string | undefined): LayoutHandler => {
  const noLayoutPlaceholder = {
    isPriceList: false,
    hasCustomLayout: false,
  };

  if (layoutName === undefined) {
    return noLayoutPlaceholder;
  }

  return {
    isPriceList: layoutName === 'pricelist',
    hasCustomLayout: hasCustomLayout(layoutName),
  };
};
