import { SEO_CONFIG, SeoConfig } from '@thema-core/state';

const description =
  'Diamenty jubilerskie i inwestycyjne bez pośredników. Kupuj kamienie szlachetne w najniższej cenie. Sprawdzony sprzedawca diamentów z całego świata.';

const seoConfig: SeoConfig = {
  /* origin is set in seoService */
  title: 'Diamenty jubilerskie, diamenty inwestycyjne, brylanty hurt – e-diamenty.pl',
  description: description,
  robots: 'all',
  ogTags: {
    'og:title':
      'Diamenty jubilerskie, diamenty inwestycyjne, brylanty hurt – e-diamenty.pl',
    'og:description': description,
    'og:site_name': 'E-diamenty',
    'og:type': 'website',
    'og:url': '',
    'og:image': `/assets/images/logo-seo.png`,
    'og:image:alt': 'e-diamenty logo',
    'og:image:url': `/assets/images/logo-seo.png`,
  },
  diamondFallbackImage: '/assets/images/diamond-placeholder.jpg',
  productFallbackImage: '/assets/images/diamond-placeholder.jpg',
};

export const SEO_PROVIDER = {
  provide: SEO_CONFIG,
  useValue: seoConfig,
};
