import {
  AUTH_SERVICE_TOKEN,
  TOKEN_REFRESH_INTERCEPTOR_CONFIG,
  TOKEN_REFRESH_INTERCEPTOR_PROVIDER,
} from '@thema-core/interceptors';
import { AuthService } from '@thema-core/services';

export const AUTH_PROVIDERS = [
  {
    provide: AUTH_SERVICE_TOKEN,
    useExisting: AuthService,
  },
  {
    provide: TOKEN_REFRESH_INTERCEPTOR_CONFIG,
    useValue: { shouldPassUntouchedUrlEnds: ['Token', 'Authenticate'] },
  },
  TOKEN_REFRESH_INTERCEPTOR_PROVIDER,
];
