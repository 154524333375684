import { Injectable } from '@angular/core';
import { WarnService } from '@thema-core/services';
import { uppercaseFirstLetter } from '@thema-core/helpers';

export enum DiamondShapeNames {
  Asscher,
  Cushion,
  Emerald,
  Heart,
  Marquise,
  Oval,
  Princess,
  Radiant,
  Round,
  Other,
}

@Injectable({ providedIn: 'root' })
export class AssetsService {
  constructor(private warnService: WarnService) {}

  public getDiamondImageByShape(diamondShape: string, bigImage = false): string {
    diamondShape = uppercaseFirstLetter(diamondShape);
    const message = `${diamondShape} is not valid shapeName`;
    this.warnService.developmentWarn(!(diamondShape in DiamondShapeNames), message);

    if (bigImage) {
      return `/assets/images/diamond-shapes/diamondShape${diamondShape}.jpg`;
    } else {
      diamondShape = diamondShape.toLowerCase();
      return `/assets/images/diamonds-small/${diamondShape}.jpg`;
    }
  }
}
