import { Component, Input } from '@angular/core';
import { flatSingle } from '@thema-core/helpers';
import { AdminEditablePartComponent } from '../../admin-editable-part-component';

@Component({
  selector: 'thema-core-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableTableComponent extends AdminEditablePartComponent {
  private _headers: string[] = ['header1', 'header2', 'header3'];

  protected numberOfEditableElementsInSinglePartOfEachGroup: number[];
  protected onPageCreateContent: string[][] = [];

  public headersWithMetadata = [] as {
    text: string;
    colspan: number;
  }[];

  @Input()
  public get headers(): string[] {
    return this._headers;
  }
  public set headers(value: string[]) {
    this._headers = value;
    this.createHeadersWithMetadata();
  }

  @Input()
  public data: string[][] = [
    ['11', '12', '13'],
    ['21', '22', '23'],
  ];
  @Input()
  public set customLayoutData(v: string[][]) {
    this.viewModelGroups = [v];
  }

  public ngOnInit(): void {
    this.onPageCreateContent = [flatSingle(this.data)];
    this.numberOfEditableElementsInSinglePartOfEachGroup = [this._headers.length];
  }

  private createHeadersWithMetadata(): void {
    const result = [] as { text: string; colspan: number }[];
    const length = this.headers.length;
    for (let i = 0; i < length; i++) {
      if (this._headers[i] === '' && i > 0) {
        result[result.length - 1].colspan++;
      } else {
        result.push({
          text: this._headers[i],
          colspan: 1,
        });
      }
    }

    this.headersWithMetadata = result;
  }
}
