import { FactoryProvider, InjectionToken } from '@angular/core';
import { ENV_FILE, ENVIRONMENT, INSTANCE } from '@thema-core/tokens';

export const ENVIRONMENT_PROVIDER: FactoryProvider = {
  provide: ENVIRONMENT,
  deps: [INSTANCE, ENV_FILE],
  useFactory: (instance, environment) => {
    environment.clientUrl = environment.httpSettings.clientUrls[instance];
    environment.httpSettings.apiUrl = environment.httpSettings.apiUrls[instance];
    environment.httpSettings.mediaStorageUrl =
      environment.httpSettings.mediaStorageUrls[instance];
    return environment;
  },
};
