import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DiamondFilters,
  FilterAttribute,
  ProductList,
  ProductMediaItem,
  ProductMediaItemType,
  ProductPage,
  ProductVM,
} from '@thema-core/models';
import { map, tap } from 'rxjs/operators';
import { AssetsService } from '../assets/assets.service';
import { WishlistService } from '@thema-core/state';
import { SimpleCache } from '@thema-core/helpers';
import { HttpService } from '../http/http.service';
import { ProductMapperService } from '../product-mapper/product-mapper.service';

const filtersConfig = (product: ProductVM | undefined): Record<string, string> => {
  if (!product) {
    return {};
  }
  const getPlusMinusValue = (value: number, plusMinus: number): string =>
    /* (value:100, plusMinus:0.15) => 85,115 */
    [value * (1 - plusMinus), value * (1 + plusMinus)].join(',');

  const hasTheSameAttrs = [
    /*attrs, which has to be equal to match product*/
    'diamond_clarity',
    'diamond_polish',
    'diamond_symmetry',
    'diamond_fluorescence',
    'diamond_cut',
    'diamond_color',
    'diamond_shape',
  ];

  const filtersObj = {};
  hasTheSameAttrs.map((attr) => {
    const codeLabelValue = product.attributes.find((prodAttr) => prodAttr.code === attr);
    const value = codeLabelValue?.value['label']?.toLowerCase();
    const key = (codeLabelValue?.label as string)?.toLowerCase();
    if (key && value) {
      filtersObj[key] = value;
    }
  });
  const priceLabel = (
    product.attributes.find((prodAttr) => prodAttr.code === 'price')?.label as string
  ).toLowerCase();
  return {
    [priceLabel]: getPlusMinusValue(product.pricing.currentPriceTaxIncluded, 0.15),
    ...filtersObj,
  };
};

@Injectable({ providedIn: 'root' })
export class ProductBannersService {
  public cache = new SimpleCache();
  public diamondShapeMap = new Map();
  public diamondShapes = [] as string[];

  constructor(
    private httpService: HttpService,
    private productMapperService: ProductMapperService,
    private assetsService: AssetsService,
    private wishlistService: WishlistService
  ) {}

  public getForCarousel(): Observable<ProductVM[]> {
    const carouselProducts = this.cache.get<ProductVM[]>('carouselProducts');
    if (carouselProducts) {
      return of(carouselProducts);
    }

    return this.fetchProducts(12, undefined, {
      kształt:
        'brylant,princessa,radiant,asscher,poduszka,szmaragd,markiza,serce,łezka,oval,bagieta',
    }).pipe(tap((products) => this.cache.put('carouselProducts', products, 3600000)));
  }

  public fetchProducts(
    productsNumber: number,
    product?: ProductVM,
    filters = filtersConfig(product)
  ): Observable<ProductVM[]> {
    if (product) {
      // In case it fetch the same product, we need 1 more to replace
      productsNumber++;
    }

    const params = this.httpService.objectToParams({
      pageSize: productsNumber,
      sortBy: 'added',
      offset: 0,
      store: 'pl',
      country: 'PL',
      currency: 'PLN',
      filterGroup: 'DiamondFilterGroup',
      sortOrder: 1,
    });
    const urlParam = this.createUrlParam(filters);
    return this.httpService
      .get<ProductPage>(`/Products/ByQueryWithContext?url=diamenty?${urlParam}`, {
        params,
      })
      .pipe(
        tap((page) => this.setDiamondShapeMap(page)),
        map((page) => page.products),
        map((products) =>
          this.removeProductWhichWasBaseForFiltering(products, product, productsNumber)
        ),
        map((products) => this.mapToProductVMWithMedia(products))
      );
  }

  public getDiamondFilters(): void {
    this.httpService
      .get<ProductPage>(`/Products/ByQueryWithContext?url=diamenty`)
      .pipe(tap((c) => console.log(c)));
  }

  public takeShapes(productList: ProductList[]): void {
    productList.map((product) =>
      this.diamondShapes.push(product.attributes.diamond_shape as string)
    );
  }

  public setImages(productVMS: ProductVM[]): ProductVM[] {
    return productVMS.map((product, index) => {
      product.small_image = this.fakeProductMediaItemGenerator(this.diamondShapes[index]);
      return product;
    });
  }

  public setDiamondShapeMap(page: ProductPage): void {
    const shapeFilter = page.filters.find(
      (filter) => filter.code === 'diamond_shape'
    ) as unknown as FilterAttribute<DiamondFilters>;
    shapeFilter.options?.map((shape) => this.diamondShapeMap.set(shape.label, shape.key));
  }

  public mapToProductVMWithMedia(products: ProductList[]): ProductVM[] {
    this.takeShapes(products);
    products = this.wishlistService.markFavourites(products);
    let productsVM = this.productMapperService.productListToProductVMs(products);
    productsVM = this.setImages(productsVM);
    this.diamondShapes = [];
    return productsVM;
  }

  private removeProductWhichWasBaseForFiltering(
    products: ProductList[],
    product: ProductVM | undefined,
    productsNumber: number
  ): ProductList[] {
    if (!product) {
      return products;
    }
    products = products.filter((foundedProduct) => foundedProduct.id !== product.id);
    if (products.length === productsNumber) {
      products.pop();
    }
    return products;
  }

  private createUrlParam(filters?: { [key: string]: string }): string {
    if (!filters) {
      return '';
    }
    return encodeURIComponent(
      `${Object.entries(filters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    );
  }

  public fakeProductMediaItemGenerator(diamondShape: string): ProductMediaItem {
    return {
      id: '',
      type: ProductMediaItemType.Photo,
      isHidden: false,
      description: '',
      imageId: '',
      url: this.assetsService.getDiamondImageByShape(
        this.diamondShapeMap.get(diamondShape),
        true
      ),
    };
  }
}
