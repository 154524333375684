/* eslint-disable */
import { Injectable } from '@angular/core';
import { CartItem, CartState, ProductList, ProductVM } from '@thema-core/models';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  public onAddPaymentInfo(cart: CartState): void {
    this.gtag('event', 'add_payment_info', {
      currency: 'PLN',
      value: cart.subtotalTaxIncluded,
      items: cart.positions.map(this.mapPositionToEventItem),
      payment_type: cart.selectedPaymentMethod?.code,
    });
  }

  public onAddShippingInfo(cart: CartState): void {
    this.gtag('event', 'add_shipping_info', {
      currency: 'PLN',
      value: cart.subtotalTaxIncluded,
      items: cart.positions.map(this.mapPositionToEventItem),
      shipping_tier: cart.selectedShippingMethod?.label,
    });
  }

  public onViewItemList(items: ProductVM[] | ProductList[]): void {
    this.gtag('event', 'view_item_list', {
      items: areItemsProductVM(items)
        ? items.map((item) => this.mapProductToEventItem(item))
        : items.map((item) => this.mapProductListToEventItem(item)),
    });
  }

  public onSelectItem(item: ProductVM | ProductList): void {
    const items = [item] as ProductVM[] | ProductList[];
    this.gtag('event', 'select_item', {
      items: areItemsProductVM(items)
        ? items.map((item) => this.mapProductToEventItem(item))
        : items.map((item) => this.mapProductListToEventItem(item)),
    });
  }

  public onViewItem(product: ProductVM): void {
    this.gtag('event', 'view_item', {
      currency: 'PLN',
      value: product.pricing.fullPriceTaxIncluded,
      items: [this.mapProductToEventItem(product)],
    });
  }

  public onAddToCart(product: ProductVM): void {
    this.gtag('event', 'add_to_cart', {
      currency: 'PLN',
      value: product.pricing.fullPriceTaxIncluded,
      items: [this.mapProductToEventItem(product)],
    });
  }

  public onRemoveFromCart(item: CartItem): void {
    this.gtag('event', 'remove_from_cart', {
      currency: 'PLN',
      value: item.product.pricing.fullPriceTaxIncluded,
      items: [this.mapPositionToEventItem(item)],
    });
  }

  public onViewCart(cart: CartState): void {
    this.gtag('event', 'view_cart', {
      currency: 'PLN',
      value: cart.subtotalTaxIncluded,
      items: cart.positions.map(this.mapPositionToEventItem),
    });
  }

  public onPurchase(cart: CartState, orderId: string): void {
    this.gtag('event', 'purchase', {
      transaction_id: orderId,
      value: cart.subtotalTaxIncluded,
      tax: cart.subtotalTaxAmount,
      shipping: cart.selectedShippingMethod!.priceTaxIncluded,
      currency: 'PLN',
      items: cart.positions.map(this.mapPositionToEventItem),
    });
  }

  private mapProductListToEventItem = (item: ProductList): unknown => ({
    item_id: item.id,
    item_name: item.attributes.short_description,
    discount: item.pricing.fullPriceTaxIncluded - item.pricing.currentPriceTaxIncluded,
    item_category: 'Diamenty',
    price: item.pricing.currentPriceTaxIncluded,
  });

  private mapPositionToEventItem = (position: CartItem): unknown => ({
    item_id: position.product.sku,
    item_name: position.product.attributeValues['short_description'],
    discount: position.discount,
    item_category: 'Diamenty',
    price: position.priceTaxIncluded,
    quantity: 1,
  });

  private mapProductToEventItem(product: ProductVM): unknown {
    const cats = {};
    if ('categories' in product) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.categories.forEach(({ name }, index) => {
        const catIndex = index + 1;
        const catName = 'item_category' + (catIndex > 1 ? catIndex : '');
        cats[catName] = name;
      });
    } else {
      cats['item_category'] = 'Diamenty';
    }

    return {
      item_id: product.sku,
      item_name: product.short_description,
      discount:
        product.pricing.fullPriceTaxIncluded - product.pricing.currentPriceTaxIncluded,
      price: product.pricing.fullPriceTaxIncluded,
      quantity: 1,
      ...cats,
    };
  }

  private gtag(...args): void {
    if (typeof window === 'undefined' || !('gtag' in window)) {
      return;
    }

    // @ts-ignore
    window.gtag(...args);
  }
}

function areItemsProductVM(items: ProductVM[] | ProductList[]): items is ProductVM[] {
  return !items.length || Array.isArray(items[0].attributes);
}
