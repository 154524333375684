import { HttpService } from '@thema-core/services';
import { AppQuery, AppService } from '@thema-core/state';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '@thema-core/models';
import { switchMap } from 'rxjs/operators';
import { APP_INITIALIZER } from '@angular/core';
import { firstValueFrom } from 'rxjs';

function getAvailableLangsAndSetupUrls() {
  return (
    http: HttpService,
    appService: AppService,
    appQuery: AppQuery,
    transloco: TranslocoService
  ): (() => Promise<unknown>) => {
    return (): Promise<unknown> => {
      return firstValueFrom(
        http.get<Language[]>('/Languages').pipe(
          switchMap((languages) => {
            appService.updateAvailableLanguages(languages);
            const dl = appQuery.getDefaultLang();
            transloco.setDefaultLang(dl);
            appService.updateLanguage(dl);
            transloco.setAvailableLangs(appQuery.getValue().languages.map((l) => l.tag));
            transloco.setActiveLang(dl);
            return transloco.load(dl);
          })
        )
      );
    };
  };
}

export const APP_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [HttpService, AppService, AppQuery, TranslocoService],
  useFactory: getAvailableLangsAndSetupUrls(),
};
