export const once = (func) => {
  let result;
  let hasRun = false;
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  return (...args) => {
    if (!hasRun) {
      result = func(args);
      func = null;
      hasRun = true;
    }
    return result;
  };
};

export const minWidthBreakpointFactory = (w: number): string => `(min-width: ${w}px)`;
export const breakpointsFactory = (widths: number[]): string[] =>
  widths.map(minWidthBreakpointFactory);
