import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { Consent } from '@thema-core/models';
import { GetRoutePipe } from '@thema-core/pipes';

@Component({
  selector: 'thema-core-checkout-login',
  templateUrl: './checkout-login.component.html',
  styleUrls: ['./checkout-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutLoginComponent {
  @Input()
  public showTitle = true;
  @Input()
  public appearance = 'fill';
  @Output()
  public proceedNotLogged = new EventEmitter();
  public isLoginMode = true;
  public consents: Consent[] = [
    {
      required: true,
      translation: 'registration_firstRequiredConsent',
    },
    {
      required: true,
      translation: 'registration_secondRequiredConsent',
    },
    {
      required: false,
      translation: 'registration_smsConsent',
      key: 'smsConsent',
    },
  ];
  constructor(private router: Router, private getRoute: GetRoutePipe) {}
  public changeForm(mode: string): void {
    this.isLoginMode = mode === 'login';
  }

  public resetPassword(): void {
    void this.router.navigateByUrl(this.getRoute.transform('forgotPassword'));
  }

  public sendCheckoutAsGuest(): void {
    this.proceedNotLogged.emit();
  }
}
