import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WishlistComponent } from './wishlist/wishlist.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ContinueShoppingModule } from '../simple-components';
import { RouterModule } from '@angular/router';
import { GetRouteModule } from '@thema-core/pipes';

@NgModule({
  declarations: [WishlistComponent],
  exports: [WishlistComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ContinueShoppingModule,
    RouterModule,
    GetRouteModule,
  ],
})
export class WishlistLibModule {}
