import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySelectComponent } from './country-select.component';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [CountrySelectComponent],
  exports: [CountrySelectComponent],
  imports: [CommonModule, SelectModule],
})
export class CountrySelectModule {}
