import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function mustMatch(
  controlName: string,
  matchingControlName: string
): (FormGroup: UntypedFormGroup) => ValidationErrors | null {
  return (formGroup: UntypedFormGroup): null | { mustMatch: boolean } => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // return null if controls haven't initialised yet
    if (!control || !matchingControl) {
      return null;
    }

    // return null if another validator has already found an error on the matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }

    return { mustMatch: true };
  };
}
