import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiamondsTableComponent } from './diamonds-table/diamonds-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TranslocoModule } from '@ngneat/transloco';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { DiamondMultiselectFilterComponent } from './diamond-multiselect-filter/diamond-multiselect-filter.component';
import { SvgIconsModule } from '../svg/_svg-icon/svg-icons.module';
import { ToggleModule } from '../simple-components';
import { MatTooltipModule } from '../tooltip/tooltip-module';
import {
  GetRouteModule,
  MethodModule,
  UpperCaseFirstLetterModule,
} from '@thema-core/pipes';
import { DiamondRangeFilterComponent } from './diamond-multiselect-slider/diamond-range-filter.component';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from '@thema-core/external';
import { TableHeaderComponent } from './table-header/table-header.component';
import { IconButtonModule } from '../simple-components/icon-button/icon-button.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DiamondsTableComponent,
    FilterHeaderComponent,
    DiamondMultiselectFilterComponent,
    DiamondRangeFilterComponent,
    TableHeaderComponent,
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    SvgIconsModule,
    TranslocoModule,
    ToggleModule,
    MatTooltipModule,
    UpperCaseFirstLetterModule,
    FormsModule,
    NouisliderModule,
    IconButtonModule,
    GetRouteModule,
    RouterModule,
    MethodModule,
  ],
  exports: [
    DiamondsTableComponent,
    FilterHeaderComponent,
    DiamondMultiselectFilterComponent,
    DiamondRangeFilterComponent,
    TableHeaderComponent,
  ],
})
export class DiamondsCatalogLibModule {}
