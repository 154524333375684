<span class="thema-button-span thema-button-content">
  <span class="thema-button-span thema-button-label">
    <ng-content></ng-content>
  </span>
  <span class="thema-button-span loading-wrapper"
        *ngIf="isBusy">
    <span class="thema-button-span marker1"></span>
    <span class="thema-button-span marker2"></span>
    <span class="thema-button-span marker3"></span>
  </span>
</span>
