import { Injectable } from '@angular/core';
import { HttpService, ProductMapperService } from '@thema-core/services';
import { LocaleOptions, ProductConfigurationView, ProductVM } from '@thema-core/models';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const ENDPOINT_URL = '/Products';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  constructor(
    private http: HttpService,
    private productMapperService: ProductMapperService
  ) {}

  public getConfiguration(
    configurationId: string,
    locale: LocaleOptions
  ): Observable<ProductConfigurationView> {
    const params = new HttpParams()
      .set('store', locale.language)
      .set('currency', locale.currency)
      .set('country', locale.country);
    return this.http
      .get<ProductConfigurationView>(
        `${ENDPOINT_URL}/${configurationId}/Configurations`,
        { params }
      )
      .pipe(
        map((configuration) => {
          configuration.mediaItems?.forEach(this.productMapperService.mapMediaItem);
          return configuration;
        })
      );
  }

  public getFullProduct(
    productUrl: string,
    locale: LocaleOptions
  ): Observable<ProductVM> {
    const params = new HttpParams({
      fromObject: { country: locale.country, currency: locale.currency },
    });
    return this.http
      .get<ProductVM>(`/Products/ByUrl/${productUrl}`, {
        params,
      })
      .pipe(map(this.productMapperService.productMapper));
  }
}
