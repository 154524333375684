<section *transloco="let t">
  <div class="narrow">
    <h3>{{t('checkout_guest_title')}}</h3>
    <button thema-core-button (click)="checkoutClicked.emit()">{{t('checkout_guest_checkout_button')}}</button>
    <h6>{{t('checkout_guest_subtitle')}}!</h6>
    <uL>
      <li><span>{{t('checkout_list_1')}}</span></li>
      <li><span>{{t('checkout_list_2')}}</span></li>
      <li><span>{{t('checkout_list_3')}}</span></li>
    </uL>
  </div>
  <thema-core-expansion-group
    titleKey='checkout_expansion_title_'
    contentKey='checkout_expansion_content_'>
  </thema-core-expansion-group>

</section>
