import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmpty } from '../objects';

export function isEmail(control: AbstractControl): ValidationErrors | null {
  if (isEmpty(control.value)) {
    return null;
  }

  return EMAIL_PATTERN.test(control.value)
    ? null
    : { email: true, errorDescription: 'Nieprawidłowy adres e-mail.' };
}

const EMAIL_PATTERN = /^ *(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)* *$/;
