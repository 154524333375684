import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'addLangData',
})
export class AddLangDataPipe implements PipeTransform {
  constructor(private t: TranslocoService) {}

  public transform(value: string): string {
    if (!value.startsWith('/')) {
      // relative urls should be fine by default
      return value;
    }

    const active = this.t.getActiveLang();
    if (active === this.t.getDefaultLang()) {
      return value;
    }

    return `/${active}${value}`;
  }
}
