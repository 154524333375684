import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

let checkboxIdCounter = 0;

@Component({
  selector: 'thema-core-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  public _required: boolean;

  public inputId = `checkbox_${checkboxIdCounter++}`;

  @Input()
  public set required(value: unknown) {
    this._required = coerceBooleanProperty(value);
  }
  @Input()
  public disabled: boolean;
  @Input()
  public checkboxPosition: 'center' | 'top' = 'center';
  @Input()
  public checked: boolean;
  @Input()
  public rounded: boolean;
  @Input()
  public radio: boolean;
  @Output()
  public checkedChange = new EventEmitter<boolean>();

  public onChange(target: EventTarget): void {
    this.checkedChange.emit((target as HTMLInputElement).checked);
  }
}
