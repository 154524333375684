import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { StaticPageTranslationWithLayout } from '../common/interfaces';
import { layoutHandler, LAYOUTS } from '../layouts';
import { StaticPagesLibService } from '../common/static-page.service';
import { AdminEditableBaseComponent } from '../static-pages-implementations/admin-editable-base.component';
import {
  EMPTY_CONTENT,
  getContent,
  setContent,
  TranslationContent,
} from '../common/content-edit-helpers';
import { StaticPageDirective } from '../common/static-page.directive';
import { catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { BlogEntry, LanguageUpdateService, SeoHandler } from '@thema-core/models';
import { APP_KEY, LANGUAGE_UPDATE_SERVICE } from '@thema-core/tokens';

@Component({
  selector: 'thema-core-static-page-container',
  templateUrl: './static-page-container.component.html',
  styleUrls: ['./static-page-container.component.scss'],
})
export class StaticPageContainerComponent {
  @Input()
  private seoHandler: SeoHandler | undefined;
  @Input()
  public staticPageUrl: string | undefined;
  @Input()
  public blogEntry: BlogEntry | undefined;
  @Output()
  public pageNotFound = new EventEmitter<boolean>();

  @Output()
  public componentLoaded = new EventEmitter<{
    component: AdminEditableBaseComponent;
    initialContent: string[];
    initialContentGroups: string[][];
  }>();

  @ViewChild(StaticPageDirective, { static: true })
  public staticPageHost: StaticPageDirective;

  public staticPageComponent: AdminEditableBaseComponent;
  public pageContent: string[];
  public pageContentGroups: string[][];
  public content: string;
  public layoutDetails = layoutHandler(undefined);

  public get seoStaticPageUrl(): string {
    if (this.appKey === 'thema') {
      return `s/${this.staticPageUrl}`;
    }
    return this.staticPageUrl!;
  }

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private staticPagesLibService: StaticPagesLibService,
    @Inject(LANGUAGE_UPDATE_SERVICE) private appService: LanguageUpdateService,
    @Inject(APP_KEY) private appKey: string
  ) {}

  public get pageTagsArray(): ElementRef[] {
    return this.staticPageComponent.editableTags;
  }

  public get pageGroupsTagsArray(): ElementRef[][] {
    return this.staticPageComponent.tagGroups;
  }

  public setPageContent(): void {
    setContent(
      this.renderer,
      this.pageTagsArray,
      this.pageGroupsTagsArray,
      this.pageContent,
      this.pageContentGroups
    );
  }

  public getInitialPageContent(): TranslationContent {
    return getContent(this.pageTagsArray, this.pageGroupsTagsArray);
  }

  public ngOnChanges(changesData: {
    staticPageUrl?: { currentValue: string };
    blogEntry?: { currentValue: BlogEntry };
  }): void {
    if (changesData.staticPageUrl?.currentValue) {
      this.handleUrlChange();
    }
    if (changesData.blogEntry?.currentValue) {
      this.handleBlogChange();
    }
  }

  public handleBlogChange(): void {
    if (this.seoHandler) {
      this.seoHandler.updateBlogArticleSeo(this.blogEntry!);
    }
    this.appService.updateLanguage(this.blogEntry!.language);
    this.content = this.blogEntry!.text;
    this.staticPageHost?.viewContainerRef?.clear();
    this.cdr.markForCheck();
  }

  public handleUrlChange(): void {
    this.staticPagesLibService
      .getTranslationByUrl(this.staticPageUrl!)
      .pipe(
        catchError(() => {
          this.pageNotFound.emit(true);
          return of(undefined);
        }),
        filter((v) => !!v)
      )
      .subscribe((translation: StaticPageTranslationWithLayout) => {
        if (this.seoHandler && translation) {
          this.seoHandler.updateStaticPageSeo(
            this.seoStaticPageUrl,
            translation.metaTitle,
            translation.metaDescription
          );
        }

        this.appService.updateLanguage(translation.languageTag);
        this.layoutDetails = layoutHandler(translation?.layout);

        if (this.layoutDetails.hasCustomLayout) {
          this.content = translation.content;
          this.staticPageHost?.viewContainerRef?.clear();
          this.cdr.markForCheck();
          return;
        }

        translation.content === EMPTY_CONTENT
          ? this.handlePageWithoutContent(translation)
          : this.handlePageWithContent(translation);

        this.componentLoaded.emit({
          component: this.staticPageComponent,
          initialContent: this.pageContent,
          initialContentGroups: this.pageContentGroups,
        });
      });
  }

  private handlePageWithoutContent(translation: StaticPageTranslationWithLayout): void {
    this.loadComponent(translation.layout);
    if (this.layoutDetails.hasCustomLayout) {
      this.staticPageHost?.viewContainerRef?.clear();
      return;
    }
    [this.pageContent, this.pageContentGroups] = this.getInitialPageContent();
  }

  private handlePageWithContent(translation: StaticPageTranslationWithLayout): void {
    const content = JSON.parse(translation.content);
    this.loadComponent(translation.layout, content);
    [this.pageContent, this.pageContentGroups] = content;
    this.setPageContent();
  }

  private loadComponent(layout: string, content: string[][][] | null = null): void {
    const page = LAYOUTS.find((l) => l.name === layout)?.component;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<AdminEditableBaseComponent>(
        page
      );
    const viewContainerRef = this.staticPageHost.viewContainerRef;
    viewContainerRef.clear();

    const ref =
      viewContainerRef.createComponent<AdminEditableBaseComponent>(componentFactory);
    this.staticPageComponent = ref.instance;
    this.staticPageComponent.initialContent = content;
    ref.changeDetectorRef.detectChanges(); // Force component lifecycle
  }
}
