import { Component, Input } from '@angular/core';
import { AdminEditablePartComponent } from '../../admin-editable-part-component';

@Component({
  selector: 'thema-core-service-tile',
  templateUrl: './service-tile.component.html',
  styleUrls: ['./service-tile.component.scss'],
})
export class ServiceTileComponent extends AdminEditablePartComponent {
  @Input()
  public initialTitle = 'Title';
  @Input()
  public initialSubtitle = 'Subtitle';
}
