import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GetRouteModule } from '@thema-core/pipes';
import { LazyLoadModule } from '@thema-core/directives';
import { ProductTileComponent } from './product-tile.component';
import { ProductPriceModule } from '../simple-components';
import { IconButtonModule } from '../simple-components/icon-button/icon-button.module';

@NgModule({
  declarations: [ProductTileComponent],
  exports: [ProductTileComponent],
  imports: [
    CommonModule,
    ProductPriceModule,
    IconButtonModule,
    RouterModule,
    GetRouteModule,
    LazyLoadModule,
  ],
})
export class ProductTileModule {}
