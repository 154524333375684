import { RouteStrategyEmitterService } from '@thema-core/services';
import { Router } from '@angular/router';
import { Attachable } from '@thema-core/models';
import { Subject } from 'rxjs';

export abstract class RouteReusable implements Attachable {
  protected attached$ = new Subject<void>();
  protected isAttached = true;
  public detachedRouteKey = Symbol();

  protected constructor(
    protected emitter: RouteStrategyEmitterService,
    protected router: Router
  ) {
    this.register();
  }

  public willBeAttached(): void {
    this.isAttached = true;
    this.attached$.next();
    this.onReattached();
  }

  public willBeDetached(): void {
    this.isAttached = false;
  }

  public abstract onReattached(): void;

  private register(): void {
    this.emitter.registerReusable(this);
  }
}
