export enum AttributeType {
  Undefined,
  Decimal,
  Integer,
  Price,
  TextField,
  TextArea,
  Bool,
  DateTime,
  Select,
  Multiselect,
  Media,
}
