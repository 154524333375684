import { AppRoutes } from '@thema-core/models';

const userPages = {
  user: 'konto',
  userInfo: 'konto/twoje-dane',
  orderHistory: 'konto/historia-zamowien',
  changePassword: 'konto/zmien-haslo',
};

export const ROUTES_CONFIG: AppRoutes = {
  diamondCatalog: 'sklep', // To change sklep/katalog
  defaultCategory: 'sklep', //Should be the same as the one above
  productCard: 'sklep/produkt',
  checkout: 'zamowienie',
  checkoutSummary: 'zamowienie/podsumowanie',
  login: 'sklep/logowanie',
  register: 'sklep/rejestracja',
  wishList: 'sklep/obserwowane',
  cart: 'sklep/koszyk',
  forgotPassword: 'sklep/przypomnij-haslo',
  searchQueryParamName: 'szukaj',
  thankYou: 'zamowienie/dziekujemy',
  contact: 'kontakt',
  checkoutLogin: 'zamowienie/logowanie',
  blog: 'blog',
  privacyPolicy: 'informacje/polityka-prywatnosci',
  ...userPages,
};
