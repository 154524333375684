
export function isNotEmpty(heading: string | null): boolean {
  return (heading ?? '').trim().length > 0 && heading !== 'undefined';
}

export function simplifyUrl(url: string): string {
  return url.toLowerCase().split(' ').join('-');
}

export function uppercaseFirstLetter(value: string | null | undefined): string {
  if (value == null || value[0] === undefined) {
    return '';
  }

  if (value.length === 1) {
    return value.toUpperCase();
  }

  return value[0].toUpperCase() + value.substring(1, value.length);
}
