import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { isValidPassword } from '@thema-core/helpers';

@Directive({
  selector: '[appPasswordValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true },
  ],
})
export class PasswordValidatorDirective {
  public validate(control: AbstractControl): ValidationErrors | null {
    return isValidPassword(control);
  }
}
