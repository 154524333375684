<div class="thema-form-field-optional-label"
     *ngIf="label && isOptional && !hideOptional">(optional)
</div>
<ng-content select="label"></ng-content>
<ng-content select="[appInputPrefix]"></ng-content>
<ng-content select="[appInput]"></ng-content>
<ng-content select="[appInputPostfix]"></ng-content>
<div class="thema-form-field-underline"></div>
<ng-container *ngIf="inputModel.invalid && inputModel.dirty">
  <ng-content select="thema-core-input-error"></ng-content>
</ng-container>
