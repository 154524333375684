import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  DoCheck,
  ElementRef,
  inject,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { InputDirective } from './input/input.directive';
import { LabelDirective } from './label/label.directive';
import { InputErrorComponent } from './input-error/input-error.component';
import { NgModel } from '@angular/forms';
import { InputPostfixDirective } from './input-postfix/input-postfix.directive';
import { fromEvent } from 'rxjs';
import { COMPONENT_APPEARANCE_TOKEN } from '@thema-core/tokens';
import { ComponentAppearance, ComponentAppearanceConfig } from '@thema-core/models';
import { InputPrefixDirective } from './input-prefix/input-prefix.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

let formFieldIdCounter = 0;

@Component({
  selector: 'thema-core-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldComponent implements AfterViewInit, DoCheck {
  private destroyRef = inject(DestroyRef);
  private nativeValue: unknown = '';

  @ContentChild(InputDirective, { read: ElementRef })
  private input: ElementRef<HTMLInputElement | HTMLTextAreaElement>;
  @ContentChild(InputErrorComponent)
  private error: InputErrorComponent;
  @ContentChild(InputPostfixDirective, { read: ElementRef })
  private postfix?: ElementRef;
  @ContentChild(InputPrefixDirective, { read: ElementRef })
  private prefix?: ElementRef;
  @Input()
  private appearance?: ComponentAppearance;
  @Input()
  private theme: 'light' | 'dark' | 'light2' = 'light';
  @Input()
  public hideOptional = false;

  @ContentChild(NgModel)
  public inputModel: NgModel;
  @ContentChild(LabelDirective)
  public label?: LabelDirective;

  public isOptional = true;
  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    @Optional()
    @Inject(COMPONENT_APPEARANCE_TOKEN)
    private formFieldConfig: ComponentAppearanceConfig
  ) {}

  public ngAfterViewInit(): void {
    // this.label.textChange.subscribe(this.updatePlaceholder);
    this.isOptional = !this.input.nativeElement.hasAttribute('required');
    this.checkConfig();
    this.addCss();
    this.connectLabelWithInput();
    this.updateNativeValue();
    this.inputModel.statusChanges
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onInputModelChange);
    if (this.appearance === 'fill') {
      this.addEventListeners();
    }
  }

  public ngDoCheck(): void {
    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer is updating the value using
    // `emitEvent: false` or form with 'updateOn': 'submit').
    this.updateNativeValue();
  }

  private updateNativeValue(): void {
    if (!this.input || !this.input.nativeElement) {
      return;
    }
    const newValue = this.input.nativeElement.value;
    if (newValue !== this.nativeValue) {
      this.nativeValue = newValue;
    }
  }

  private addCss(): void {
    this.el.nativeElement.classList.add('thema-form-field');
    if (this.appearance !== 'default') {
      this.el.nativeElement.classList.add('thema-form-field-' + this.appearance);
      this.el.nativeElement.classList.add('thema-form-field-' + this.theme);
    }
    if (this.postfix) {
      this.input.nativeElement.style.paddingRight = '30px';
    }
    if (this.prefix && this.appearance === 'fill') {
      this.input.nativeElement.style.lineHeight = '2.7rem';
      this.input.nativeElement.style.paddingLeft = '20px';
    }
    this.input.nativeElement.classList.add(
      'thema-form-field-input',
      'thema-form-field-input-' + this.theme
    );
    this.label?.el.nativeElement.classList.add(
      'thema-form-field-label'
      // 'cdk-visually-hidden'
    );
    this.postfix?.nativeElement.classList.add('thema-form-field-input-postfix');
  }

  private connectLabelWithInput(): void {
    this.input.nativeElement.id = `input_${formFieldIdCounter++}`;
    if (this.label) {
      this.label.el.nativeElement.htmlFor = this.input.nativeElement.id;
    }
  }

  private updatePlaceholder = (text): void => {
    this.input.nativeElement.placeholder = text;
  };

  private addEventListeners(): void {
    this.addFocusListener();
    this.addBlurListener();
  }

  private addFocusListener(): void {
    fromEvent(this.input.nativeElement, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.el.nativeElement.classList.add('thema-form-field-focused');
      });
  }

  private addBlurListener(): void {
    fromEvent(this.input.nativeElement, 'blur')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.el.nativeElement.classList.remove('thema-form-field-focused');
        if (this.inputModel.value || this.nativeValue) {
          this.el.nativeElement.classList.add('thema-form-field-dirty');
          return;
        }
        this.el.nativeElement.classList.remove('thema-form-field-dirty');
      });
  }

  private onInputModelChange = (): void => {
    this.el.nativeElement.classList.toggle(
      'thema-form-field-disabled',
      !!this.inputModel.disabled
    );

    if (this.inputModel.invalid && !this.inputModel.pristine) {
      this.input.nativeElement.classList.add('error');
    } else {
      this.input.nativeElement.classList.remove('error');
    }
    if (this.inputModel.value) {
      this.el.nativeElement.classList.add('thema-form-field-dirty');
    }
    this.cdr.markForCheck();
  };

  private checkConfig(): void {
    if (!this.formFieldConfig || this.appearance) {
      return;
    }
    this.appearance = this.formFieldConfig.appearance;
  }
}
