import { Injectable } from '@angular/core';
import { CartItem, CartOptions, WholeCartUpdate } from '@thema-core/models';
import { AppQuery } from '../app/app.query';
import { CartApiService } from './cart-api.service';
import { CartQuery } from './cart.query';
import { CartStore } from './cart.store';
import { filter } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { UserQuery } from '../user/user.query';
import {
  DataLayerService,
  LocalStorageService,
  SnackService,
} from '@thema-core/services';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private isInitialized = false;
  public cartId: string;

  constructor(
    private store: CartStore,
    private query: CartQuery,
    private api: CartApiService,
    private snack: SnackService,
    private appQuery: AppQuery,
    private transloco: TranslocoService,
    private userQuery: UserQuery,
    private localStorage: LocalStorageService,
    private dl: DataLayerService
  ) {
    this.query.id$.subscribe((id) => (this.cartId = id));
  }

  public initializeCart(): void {
    // this method might be called from both app.component and cart.guard
    // so we need to prevent duplicated executions
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    // locale options must be available before initializing cart
    combineLatest([this.appQuery.localeOptions$, this.userQuery.isLoggedIn$])
      .pipe(filter((v) => v[0] && !!v[0].language))
      .subscribe(() => {
        const cartId = this.localStorage.getItem('cartId');
        this.getCart(cartId);
      });
  }

  public updateCart(cart: WholeCartUpdate, skipSnack = false): void {
    if (!skipSnack) {
      this.snack.show({
        type: 'info',
        message: this.transloco.translate('common_processing'),
        duration: 0,
      });
    }
    this.api.updateCart(this.cartId, cart, this.getParams()).subscribe((response) => {
      this.store.update(response);
      if (!skipSnack) {
        this.snack.showSuccess(this.transloco.translate('cart_succesfull_update'));
      }
    }, this.errorHandler);
  }

  public getCart(cartId: string | null): void {
    if (cartId) {
      this.getExistingCart(cartId);
      return;
    }
    this.startNewCart();
  }

  public startNewCart(): void {
    // Fetches existing cart for logged user as well
    this.api.fetchCart(' ', this.getParams()).subscribe((response) => {
      this.store.update(response);
    });
  }

  public refreshCart(): void {
    if (!this.cartId) {
      this.startNewCart();
      return;
    }

    this.getExistingCart(this.cartId);
  }

  public getExistingCart(cartId: string): void {
    this.api.fetchCart(cartId, this.getParams()).subscribe((response) => {
      this.store.update(response);
    }, this.errorHandler);
  }

  public addItem(
    productId: string | number,
    quantity = 1,
    notes: Record<string, string | number> = {}
  ): void {
    this.snack.show({
      type: 'info',
      message: this.transloco.translate('common_processing'),
      duration: 0,
    });
    this.api
      .addPosition(this.cartId, productId, quantity, notes, this.getParams())
      .subscribe(
        (cartState) => {
          this.snack.showSuccess(this.transloco.translate('cart_added_to_cart'));
          this.localStorage.setItem('cartId', this.cartId);
          this.store.update(cartState);
        },
        (error) => this.errorHandler(error, false)
      );
  }

  public updatePosition(
    positionId: string,
    quantity: number,
    notes: Record<string, string | number>
  ): void {
    this.api
      .updatePosition(this.cartId, positionId, quantity, notes, this.getParams())
      .subscribe(
        (cartState) => {
          this.store.update(cartState);
        },
        (error) => {
          this.errorHandler(error, false);
          this.store.update((state) => ({
            positions: state.positions.map((pos) => {
              return pos.positionId === positionId ? { ...pos } : pos;
            }),
          }));
        }
      );
  }

  public deleteItem(positionId: string): void {
    this.api
      .deletePosition(this.cartId, positionId, this.getParams())
      .subscribe((cartState) => {
        this.store.update(cartState);
      }, this.errorHandler);
  }

  public changeQuantity(positionId: string, newQuantity: number): void {
    const position = this.findItem(positionId);
    if (!position) {
      return;
    }
    this.updatePosition(positionId, newQuantity, position.notes);
  }

  public changeEngraving(positionId: string, newEngraving: string): void {
    const position = this.findItem(positionId);
    if (!position) {
      return;
    }
    const notes = { ...position.notes, ...{ engraving: newEngraving } };
    this.updatePosition(positionId, position.quantity, notes);
  }

  public findItem(positionId: string): CartItem | undefined {
    return this.store.getValue().positions.find((el) => {
      return el.positionId === positionId;
    });
  }

  public clearCart(): void {
    this.localStorage.removeItem('cartId');
    this.store.reset();
    this.startNewCart();
  }

  public changeShippingMethod(shippingMethodId: string): void {
    this.api
      .updateShipping(this.cartId, shippingMethodId, this.getParams())
      .subscribe((cartState) => {
        this.store.update(cartState);
        this.dl.onAddShippingInfo(cartState);
      }, this.errorHandler);
  }

  public changePaymentMethod(paymentMethodId: string): void {
    this.api
      .updatePayment(this.cartId, paymentMethodId, this.getParams())
      .subscribe((cartState) => {
        this.store.update(cartState);
        this.dl.onAddPaymentInfo(cartState);
      }, this.errorHandler);
  }

  public getParams(
    codes = [
      'url_key',
      'short_description',
      'engraving',
      'thumbnail',
      'ring_size',
      'diamond_fancy_color',
      'diamond_color',
      'diamond_weight_ct',
      'diamond_clarity',
      'diamond_shape',
      'stock_status',
      'diamond_certificate',
    ]
  ): CartOptions {
    const currStore = this.appQuery.getValue();
    return {
      store: currStore.language,
      locale: currStore.language,
      currency: currStore.currency,
      country: currStore.country,
      attributeCodes: codes,
    };
  }

  public updateAdvancePayment(isAdvance: boolean): void {
    const params = { ...this.getParams(), isAdvance };
    this.api.updateAdvance(this.cartId, params).subscribe((cartState) => {
      this.store.update(cartState);
    }, this.errorHandler);
  }

  public errorHandler = (e: HttpErrorResponse, shouldFetchCart = true): void => {
    try {
      //405 - happens when adding items to unowned cart
      if (CartService.isQuoteClosed(e) || [403, 404, 405].includes(e.status)) {
        this.localStorage.removeItem('cartId');
        this.startNewCart();
        return; // don't show snack in those cases
      }

      const errorMessage: string =
        typeof e.error === 'object'
          ? e.error.Reference
          : (JSON.parse(e.error).Reference as string);
      if (errorMessage === undefined || errorMessage === 'undefined') {
        this.snack.showError(this.transloco.translate('common_unknown_error_occurred'));
        return;
      }

      this.snack.showError(this.transloco.translate('cart_error_' + errorMessage));
      if (!shouldFetchCart) {
        return;
      }
      this.getExistingCart(this.cartId);
    } catch (error) {
      this.snack.showError(this.transloco.translate('common_unknown_error_occurred'));
    }
  };

  private static isQuoteClosed(e: HttpErrorResponse): boolean {
    return e.error?.Reference && e.error.Reference === 'QuoteIsClosed';
  }
}
