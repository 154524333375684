/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { JwtHelperService } from './jwthelper.service';
import { JWT_OPTIONS } from './jwtoptions.token';

import { mergeMap } from 'rxjs/operators';

import { from, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public tokenGetter: () => string | null | Promise<string | null>;
  public headerName: string;
  public authScheme: string;
  public whitelistedDomains: Array<string | RegExp>;
  public blacklistedRoutes: Array<string | RegExp>;
  public throwNoTokenError: boolean;
  public skipWhenExpired: boolean;
  standardPorts: string[] = ['80', '443'];
  // tslint:disable-next-line:no-any
  constructor(
    @Inject(JWT_OPTIONS) config: any,
    public jwtHelper: JwtHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.tokenGetter = jwtHelper.tokenGetter;
    this.headerName = config.headerName || 'Authorization';
    this.authScheme =
      config.authScheme || config.authScheme === '' ? config.authScheme : 'Bearer ';
    this.whitelistedDomains = config.whitelistedDomains || [];
    this.blacklistedRoutes = config.blacklistedRoutes || [];
    this.throwNoTokenError = config.throwNoTokenError || false;
    this.skipWhenExpired = config.skipWhenExpired;
  }

  public isWhitelistedDomain(request: HttpRequest<unknown>): boolean {
    const requestUrl: URL = new URL(request.url, this.document.location.origin);

    // If the host equals the current window origin,
    // the domain is allowed by default
    if (requestUrl.host === this.document.location.host) {
      return true;
    }

    // If not the current domain, check the allowed list
    const hostName = `${requestUrl.hostname}${
      requestUrl.port && !this.standardPorts.includes(requestUrl.port)
        ? ':' + requestUrl.port
        : ''
    }`;

    return (
      this.whitelistedDomains.findIndex((domain) =>
        typeof domain === 'string'
          ? domain === hostName
          : domain instanceof RegExp
          ? domain.test(hostName)
          : false
      ) > -1
    );
  }

  public isBlacklistedRoute(request: HttpRequest<unknown>): boolean {
    const url = request.url;

    return (
      this.blacklistedRoutes.findIndex((route) =>
        typeof route === 'string'
          ? route === url
          : route instanceof RegExp
          ? route.test(url)
          : false
      ) > -1
    );
  }

  public handleInterception(
    token: string | null,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let tokenIsExpired = false;

    if (!token && this.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    if (this.skipWhenExpired) {
      tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
    }

    if (token && tokenIsExpired && this.skipWhenExpired) {
      request = request.clone();
    } else if (token) {
      request = request.clone({
        setHeaders: {
          [this.headerName]: `${this.authScheme}${token}`,
        },
      });
    }
    return next.handle(request);
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isWhitelistedDomain(request) || this.isBlacklistedRoute(request)) {
      return next.handle(request);
    }
    const token = this.tokenGetter();

    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string | null) => {
          return this.handleInterception(asyncToken, request, next);
        })
      );
    } else {
      return this.handleInterception(token, request, next);
    }
  }
}
