import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { SP_CLASSES } from '../common/static-pages-helpers-classes';

@Component({ template: `` })
export class AdminEditablePartComponent {
  /*
  Use _PROPERTY NAME -> elements only with this editableComponent
  Use PROPERTY NAME -> getter for elements in this component and its children
   */
  @ViewChildren('editable')
  protected _editableTags: QueryList<ElementRef>;

  @ViewChildren('editableGroup')
  protected _editableGroups: QueryList<ElementRef>;

  @ViewChildren('editableComponent')
  protected _editableComponents: QueryList<AdminEditablePartComponent>;

  protected onPageCreateContent: string[][] = []; // Define it in implementation -> content for fresh group
  protected numberOfEditableElementsInSinglePartOfEachGroup: number[] = [];

  public CLASSES = SP_CLASSES;
  public viewModelGroups: string[][][] = []; // Editable elements group content split for parts in group
  public _tagGroupsContent: string[][] = []; // Editable elements content split by groups

  public get editableTags(): ElementRef[] {
    return this.contactPropertyWithPropertiesFromChildren<ElementRef>(
      this._editableTags.toArray(),
      'editableTags'
    );
  }

  public get editableGroups(): ElementRef[] {
    return this.contactPropertyWithPropertiesFromChildren<ElementRef>(
      this._editableGroups.toArray(),
      'editableGroups',
      true
    );
  }

  public get editableComponents(): AdminEditablePartComponent[] {
    return this.contactPropertyWithPropertiesFromChildren<AdminEditablePartComponent>(
      this._editableComponents.toArray(),
      'editableComponents'
    );
  }

  public get tagGroupsContent(): string[][] {
    /*    return [
      ...this._tagGroupsContent,
      ...this.concatEditableElementsFromChildren<string[]>('tagGroupsContent'),
    ];*/
    return this.contactPropertyWithPropertiesFromChildren<string[]>(
      this._tagGroupsContent,
      'tagGroupsContent',
      true
    );
  }

  public handleInitialContent(initialGroupContent: string[][] | null): void {
    this._tagGroupsContent = initialGroupContent
      ? this.takeFromInitialContentGroupsForThisComponent(initialGroupContent)
      : this.onPageCreateContent;
    this.splitEditableGroups();
  }

  private contactPropertyWithPropertiesFromChildren<T>(
    property: T[],
    childrenPropertyName: string,
    reversed = false
  ): T[] {
    if (reversed) {
      return this.concatEditableElementsFromChildren<T>(childrenPropertyName).concat(
        property
      );
    }
    return property.concat(
      this.concatEditableElementsFromChildren<T>(childrenPropertyName)
    );
  }

  private concatEditableElementsFromChildren<T>(objKey: string): T[] {
    return this._editableComponents
      .toArray()
      .map((com) => com[objKey])
      .reduce((acc, curr) => {
        acc = acc.concat(curr);
        return acc;
      }, []);
  }

  private takeFromInitialContentGroupsForThisComponent(
    groupContent: string[][]
  ): string[][] {
    const thisComponentGroupContent: string[][] = [];
    this.numberOfEditableElementsInSinglePartOfEachGroup.map(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore I don't know, why ts think that groupContent can be undefined
      thisComponentGroupContent.push(groupContent.shift());
    });
    return thisComponentGroupContent;
  }

  private splitEditableGroups(): void {
    this.numberOfEditableElementsInSinglePartOfEachGroup.map(
      (numberOfElementsInGroup, index) => {
        const groupSplitOnParts = this.splitGroupAtElementsInside(
          index,
          numberOfElementsInGroup
        );
        this.viewModelGroups.push(groupSplitOnParts);
      }
    );
  }

  private splitGroupAtElementsInside(
    groupIndex: number,
    numberOfEditableElementsInGroup: number
  ): string[][] {
    /* Changing ['h1','p1','h2','p2'] to [['h1','p1'],['h2','p2']] */
    /* Use it in implementations after ngOnInit.super() */
    if (this._tagGroupsContent.length === 0) {
      return [];
    }
    return this._tagGroupsContent[groupIndex].reduce(
      (acc, curr) => {
        const lastElOfAcc = acc[acc.length - 1];

        lastElOfAcc.length < numberOfEditableElementsInGroup
          ? lastElOfAcc.push(curr)
          : acc.push([curr]);
        return acc;
      },
      [[]] as string[][]
    );
  }
}
