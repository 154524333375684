import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { HttpOptions } from './http-options';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AnyObject,
  HttpServiceConfig,
  InstanceType,
  Page,
  PageOptions,
} from '@thema-core/models';
import { HttpParamsEncoder } from './http-params-encoder';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { INSTANCE } from '@thema-core/tokens';

interface ResponseTypeFix extends HttpOptions {
  responseType?: 'json';
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HandledType = string | number | boolean | any[] | null | undefined;
export const HTTP_SERVICE_CONFIG = new InjectionToken('http-service-config');

@Injectable({
  providedIn: 'root',
})
/**
 * Wrapper over [[HttpClient]]
 */
export class HttpService {
  private readonly defaultApi: string;
  private apiUrl: string;
  private apiUrlSub = new ReplaySubject(1);
  public apiUrl$ = this.apiUrlSub.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(HTTP_SERVICE_CONFIG) public config: HttpServiceConfig,
    @Inject(PLATFORM_ID) private pid: AnyObject,
    @Inject(INSTANCE) private instanceType: InstanceType,
    private localStorage: LocalStorageService,
    private httpClient: HttpClient
  ) {
    this.config.enableDevFunctions = this.canUnlockDevFunctions();
    this.apiUrl = this.config.apiUrl!;
    this.defaultApi = this.apiUrl;

    if (!this.config.enableDevFunctions || !isPlatformBrowser(this.pid)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    Object.defineProperty(window, 'backendApiUrl', {
      get(): string {
        return that.apiUrl;
      },
      set(v: string): void {
        that.apiChangeCallback(v);
      },
    });

    const savedApi = this.localStorage.getItem('backendApiUrl');
    if (savedApi) {
      this.apiUrl = savedApi;
      this.apiUrlSub.next(this.apiUrl);
    }
  }

  public get<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + url, options as ResponseTypeFix);
  }

  public getPage<T>(
    url: string,
    options: PageOptions,
    query?: { [key: string]: HandledType }
  ): Observable<Page<T>> {
    const params = this.objectToParams({ ...options, ...query });
    return this.httpClient.get<Page<T>>(this.apiUrl + url, { params });
  }

  public post<T>(
    url: string,
    body: unknown | null,
    options?: HttpOptions
  ): Observable<T> {
    return this.httpClient.post<T>(this.apiUrl + url, body, options as ResponseTypeFix);
  }

  public put<T>(url: string, body: unknown | null, options?: HttpOptions): Observable<T> {
    return this.httpClient.put<T>(this.apiUrl + url, body, options as ResponseTypeFix);
  }

  public delete<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.httpClient.delete<T>(this.apiUrl + url, options as ResponseTypeFix);
  }

  public uploadFiles<T>(
    files: File[],
    url: string,
    options?: HttpOptions
  ): Observable<T[]> {
    return forkJoin(...files.map((file) => this.uploadFile<T>(file, url, options)));
  }

  public objectToParams(object: { [key: string]: HandledType }): HttpParams | undefined {
    if (!object) {
      return;
    }
    let params = new HttpParams({ encoder: new HttpParamsEncoder() });
    // let params = new HttpParams();
    Object.entries(object).forEach(([key, value]) => {
      if (value == null) {
        return;
      }

      if (!this.config.enableDevFunctions && this.isUnsupportedType(value)) {
        console.log(value);
        throw new Error(
          'This method can process only values of type: string | number | boolean | undefined | Array'
        );
      }

      if (value instanceof Array) {
        value.forEach((v) => {
          if (v != null && v !== 'null') {
            params = params.append(key, v);
          }
        });
      } else {
        params = params.set(key, value.toString());
      }
    });
    // debugger;
    return params;
  }

  private uploadFile<T>(file: File, url, options?: HttpOptions): Observable<T> {
    const formData = HttpService.createFormData(file);
    return this.post<T>(url, formData, options);
  }

  private isUnsupportedType(value: unknown): boolean {
    return (
      typeof value !== 'string' &&
      typeof value !== 'number' &&
      typeof value !== 'boolean' &&
      !(value instanceof Array)
    );
  }

  private static createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return formData;
  }

  private apiChangeCallback = (url): void => {
    this.apiUrl = url === '' ? this.defaultApi : url;
    this.localStorage.setItem('backendApiUrl', this.apiUrl);
    this.apiUrlSub.next(this.apiUrl);
  };

  private canUnlockDevFunctions(): boolean {
    return isPlatformBrowser(this.pid) && this.instanceType === 'dev';
  }
}
