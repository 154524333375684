import {
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { UserService, UserQuery } from '@thema-core/state';
import { ComponentAppearance, Consent } from '@thema-core/models';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'thema-core-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  private _consents: Consent[];
  @Input()
  private redirectUrl: string | undefined;
  @Input()
  public checkoutMode: boolean;
  @Input()
  public checkoutUrl = '/cart/checkout';
  @Output()
  public modeChange = new EventEmitter<string>();
  @Output()
  public forgotPassword = new EventEmitter();
  @Input()
  public isLoginMode = true;
  @Input()
  public appearance = 'default' as ComponentAppearance;

  @Input()
  public set consents(v: Consent[]) {
    this._consents = v;
    this.optionalConsents = [];
    this.requiredConsents = [];

    v.forEach((c) => {
      if (c.required) {
        this.requiredConsents.push(c);
        return;
      }
      this.optionalConsents.push(c);
    });
  }

  public get consents(): Consent[] {
    return this._consents;
  }

  public get checkedOptionalConsents(): Record<string, boolean>[] {
    const result: Record<string, boolean>[] = [];
    this.optionalConsents.forEach((el) => {
      if (!el.checked) {
        return;
      }
      const key = el.key;
      if (key !== undefined) {
        result.push({ [key]: true });
      }
    });
    return result;
  }

  public optionalConsents: Consent[];
  public requiredConsents: Consent[];
  public registrationError$ = this.userQuery.registrationError$;
  public authError$ = this.userQuery.loginError$;
  public isLoggingIn$ = this.userQuery.selectLoading();
  public isPasswordVisible = false;
  public isConfirmPasswordVisible = false;
  public loginData = {
    email: '',
    password: '',
  };
  public confirmation: string;

  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.userQuery.isLoggedIn$
      .pipe(filter((v) => v && !!this.redirectUrl))
      .subscribe(() => void this.router.navigateByUrl(this.redirectUrl!));
  }

  public onLogin(): void {
    this.userService.login({ ...this.loginData });
  }

  public changeMode(mode: string): void {
    this.modeChange.emit(mode);
  }

  public clearErrorStatus(): void {
    this.userService.setError(null);
  }

  public onRegister(): void {
    if (!this.requiredConsentsChecked()) {
      return;
    }
    this.userService.register(
      Object.assign({}, this.loginData, ...this.checkedOptionalConsents)
    );
  }

  public onRequiredConsentCheckChange(consent: Consent, value: boolean): void {
    consent.checked = value;
    consent.hasError = false;
  }

  public onOptionalConsentCheckChange(consent: Consent, value: boolean): void {
    consent.checked = value;
    consent.hasError = false;
  }

  private requiredConsentsChecked(): boolean {
    let allChecked = true;
    this.requiredConsents.forEach((c) => {
      allChecked = !!c.checked && allChecked;
      c.hasError = !c.checked;
    });
    return allChecked;
  }
}
