import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPriceComponent } from './product-price.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [ProductPriceComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [ProductPriceComponent],
})
export class ProductPriceModule {}
