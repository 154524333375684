import { Inject, Injectable } from '@angular/core';
import { Environment, Menu, MenuItem } from '@thema-core/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from '@thema-core/tokens';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuCache = {};
  private footerCache = {};
  private educationCache = {};

  public upperRowLeft$ = new BehaviorSubject<MenuItem[]>([]);

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  public getMenu(lang: string | undefined): Observable<Menu> {
    if (!lang) {
      return of({} as Menu);
    }
    if (this.menuCache[lang]) {
      return of(this.menuCache[lang]);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.http.get<Menu>(this.environment.menuJsonUrl(lang)).pipe(
      catchError(() => of({} as Menu)),
      tap((result) => {
        this.menuCache[lang] = result;
        this.upperRowLeft$.next(result.upperRowLeft);
      })
    );
  }

  public getFooter(lang: string | undefined): Observable<Menu> {
    if (!lang) {
      return of({} as Menu);
    }
    if (this.footerCache[lang]) {
      return of(this.footerCache[lang]);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.http.get<Menu>(this.environment.footerJsonUrl(lang)).pipe(
      catchError(() => of({} as Menu)),
      tap((r) => (this.footerCache[lang] = r))
    );
  }

  public getEducationMenu(lang = 'pl'): Observable<{ label: string; url: string }[]> {
    return this.http
      .get<{ label: string; url: string }[]>(
        `${this.environment.httpSettings.mediaStorageUrl}/public/content/json/education_${lang}.json`
      )
      .pipe(
        catchError(() => of([])),
        tap((r) => (this.educationCache[lang] = r))
      );
  }
}
