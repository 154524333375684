import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSideMenuComponent } from './user-side-menu/user-side-menu.component';
import { RouterModule } from '@angular/router';
import { AddLangDataModule } from '@thema-core/pipes';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ButtonModule } from '../button/button.module';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [UserSideMenuComponent],
  exports:[UserSideMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    AddLangDataModule,
    IconButtonModule,
    ButtonModule,
    TranslocoModule
  ]
})
export class UserSideMenuModule { }
