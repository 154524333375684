import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactNumberComponent } from './contact-number.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SvgIconsModule } from '../../svg/_svg-icon/svg-icons.module';

@NgModule({
  declarations: [ContactNumberComponent],
  exports: [ContactNumberComponent],
  imports: [CommonModule, SvgIconsModule, TranslocoModule],
})
export class ContactNumberModule {}
