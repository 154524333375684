import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'thema-core-delivery-location-dialog',
  templateUrl: './delivery-location-dialog.component.html',
  styleUrls: ['./delivery-location-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryLocationDialogComponent {
  @Input()
  public logoImageSrc = '/apps/thema-store/src/assets/logo-desktop.png';
  @Input()
  public logoImageAlt = 'logo sde';

  constructor(private dialogRef: MatDialogRef<DeliveryLocationDialogComponent>) {}

  public onCountrySelect(country: string): void {
    this.dialogRef.close(country);
  }
}
