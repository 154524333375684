<label [attr.for]="inputId"
       class="container">
  <span *ngIf="_required"
        style="color:red; align-self: flex-start;">*</span>
  <ng-content></ng-content>
  <input [id]="inputId"
         [type]="radio ? 'radio' : 'checkbox'"
         [disabled]="disabled"
         [checked]="checked"
         [required]="_required"
         (change)="onChange($event.target)">
  <span class="checkmark"
        [class.posTop]="checkboxPosition === 'top'"
        [class.rounded]="rounded"
        ></span>
</label>
