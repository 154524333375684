import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './toggle.component';
import { ObserversModule } from '@angular/cdk/observers';
import { ToggleContentDirective } from './toggle-content.directive';

@NgModule({
  declarations: [ToggleComponent, ToggleContentDirective],
  imports: [CommonModule, ObserversModule],
  exports: [ToggleComponent, ToggleContentDirective],
})
export class ToggleModule {}
