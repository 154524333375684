import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackComponent } from './snack.component';

@NgModule({
  declarations: [SnackComponent],
  exports: [SnackComponent],
  imports: [CommonModule],
})
export class SnackModule {}
