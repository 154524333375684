import { InstanceType } from '@thema-core/models';

export const instanceResolver = (d: Document): InstanceType => {
  const hostname = d.location.hostname;
  if (
    hostname.startsWith('ediamenty-client.azurewebsites.net') ||
    hostname === 'e-diamenty.pl'
  ) {
    return 'prod';
  }
  if (hostname.includes('client-stage')) {
    return 'stage';
  }
  return 'dev';
};
