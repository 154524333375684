import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AppComponent } from './app.component';
import localePl from '@angular/common/locales/pl';
import localPlExtra from '@angular/common/locales/extra/pl';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import {
  FormFieldModule,
  RangeSliderWithInputsModule,
  SnackModule,
  SnackService,
  svgIcons,
  SvgIconsModule,
} from '@thema-core/components';
import { E_DIAMENTY_PROVIDERS as SETTINGS } from './configs';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './configs/transloco-root.module';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { JwtModule } from '@thema-core/external';
import { JwtConfig } from './configs/jwt-config';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {
  APP_KEY,
  ENV_FILE,
  INSTANCE,
  IS_ADMIN_APP,
  LANGUAGE_UPDATE_SERVICE,
  LOCAL_STORAGE,
  SNACK_SERVICE,
} from '@thema-core/tokens';
import {
  APP_INITIALIZER_PROVIDER,
  ENVIRONMENT_PROVIDER,
  HTTP_CONFIG_PROVIDER,
  STORAGE_URL_PROVIDER,
} from '@thema-core/providers';
import { instanceResolver } from './configs/instance-resolver';
// eslint-disable-next-line no-restricted-imports
import { environment } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { isNgDevMode } from '@thema-core/helpers';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AppService } from '@thema-core/state';
import { LocalStorageService } from '@thema-core/services';

registerLocaleData(localePl, 'pl', localPlExtra);

const conditionalModules = isNgDevMode() ? [AkitaNgDevtools.forRoot()] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    AppRoutingModule,
    MatDialogModule,
    AkitaNgRouterStoreModule,
    JwtModule.forRoot({ config: JwtConfig }),
    SvgIconsModule.forRoot({
      icons: svgIcons,
    }),
    ...conditionalModules,
    SnackModule,
    FormFieldModule.forRoot({ appearance: 'fill' }),
    RangeSliderWithInputsModule.forRoot({ appearance: 'fill' }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: IS_ADMIN_APP, useValue: false },
    { provide: INSTANCE, deps: [DOCUMENT], useFactory: instanceResolver },
    { provide: ENV_FILE, useValue: environment },
    { provide: APP_ID, useValue: environment.appKey },
    { provide: APP_KEY, useValue: environment.appKey },
    { provide: LANGUAGE_UPDATE_SERVICE, useExisting: AppService },
    { provide: LOCAL_STORAGE, useExisting: LocalStorageService },
    { provide: SNACK_SERVICE, useExisting: SnackService },
    ENVIRONMENT_PROVIDER,
    STORAGE_URL_PROVIDER,
    HTTP_CONFIG_PROVIDER,
    APP_INITIALIZER_PROVIDER,
    SETTINGS.SEO_PROVIDER,
    SETTINGS.APP_STORE_PROVIDER,
    ...SETTINGS.FEATURES_PROVIDERS,
    ...SETTINGS.AUTH_PROVIDERS,
    provideClientHydration(),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

function createErrorHandler(): ErrorHandler {
  const sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
  });

  return {
    handleError(error: unknown): void {
      try {
        sentryErrorHandler.handleError(error);
      } catch (e) {
        console.log({ e });
      }
    },
  };
}
