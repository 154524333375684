import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  SecurityContext,
} from '@angular/core';
import { isNotEmpty } from '@thema-core/helpers';

export interface ImageWithParagraphData {
  header: string;
  paragraph: string;
  photo: string;
  revertLayout: boolean;
}

@Component({
  selector: 'thema-core-image-with-text',
  templateUrl: './image-with-text.component.html',
  styleUrls: ['./image-with-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithTextComponent {
  private _data: ImageWithParagraphData;

  public get data(): ImageWithParagraphData {
    return this._data;
  }

  @Input()
  public set data(value: ImageWithParagraphData) {
    this._data = value;
    this.hasHeading = isNotEmpty(this.data.header);
    this.isInverted = this._data.revertLayout;
  }

  @HostBinding('class.inverted')
  private isInverted = false;
  @HostBinding('class.has-heading')
  public hasHeading: boolean;

  public resourceUrlContext = SecurityContext.RESOURCE_URL;
}
