import { ElementRef, Renderer2 } from '@angular/core';
import { SP_CLASSES } from './static-pages-helpers-classes';

export type TranslationContent = [string[], string[][]];

export const EMPTY_CONTENT = '$EmptyContent';

export const isImage = (el: ElementRef): boolean => el.nativeElement.tagName === 'IMG';
export const hasBackgroundImageEditable = (el: ElementRef): boolean =>
  el.nativeElement.classList.contains(SP_CLASSES.EDITABLE_BACKGROUND_IMAGE);

function getValueFromContent(
  simpleContent: string[],
  groupContent: string[][],
  index: number,
  groupIndex: number | null
): string {
  if (groupIndex !== null) {
    return groupContent[groupIndex][index];
  }
  return simpleContent[index];
}

// typing this makes it less readable
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const setValue = (name) => (renderer: Renderer2, el: ElementRef, value: string) =>
  renderer.setProperty(el.nativeElement, name, value);

// typing this makes it less readable
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const setStyle = (name) => (renderer: Renderer2, el: ElementRef, value: string) =>
  renderer.setStyle(el.nativeElement, name, value);

const setBackgroundImage = setStyle('backgroundImage');

const setSrc = setValue('src');

const setInnerText = setValue('innerText');

export function singleOperation(
  el: ElementRef,
  index: number,
  imageHandler: (el: ElementRef, index: number, groupIndex: number | null) => void,
  textHandler: (el: ElementRef, index: number, groupIndex: number | null) => void,
  backgroundImageHandler: (
    el: ElementRef,
    index: number,
    groupIndex: number | null
  ) => void,
  groupIndex: null | number = null
): void {
  if (isImage(el)) {
    imageHandler(el, index, groupIndex);
    return;
  }
  if (hasBackgroundImageEditable(el)) {
    backgroundImageHandler(el, index, groupIndex);
    return;
  }
  textHandler(el, index, groupIndex);
}

export function contentOperation(
  contentArray: ElementRef[],
  imageHandler: (el: ElementRef, index, groupIndex) => void,
  textHandler: (el: ElementRef, index, groupIndex) => void,
  backgroundImageHandler: (
    el: ElementRef,
    index: number,
    groupIndex: number | null
  ) => void,
  contentGroupsArray: ElementRef[][] = []
): void {
  contentArray.map((el, index) =>
    singleOperation(el, index, imageHandler, textHandler, backgroundImageHandler)
  );
  contentGroupsArray.map((group, groupIndex) =>
    group.map((el, index) =>
      singleOperation(
        el,
        index,
        imageHandler,
        textHandler,
        backgroundImageHandler,
        groupIndex
      )
    )
  );
}

export function getContent(
  contentArray: ElementRef[],
  contentGroupsArray: ElementRef[][] = []
): [string[], string[][]] {
  const simpleContent: string[] = [];
  const groupContent: string[][] = [];
  contentArray.map((el, index) => singleOperationForGetContent(el, index, simpleContent));

  contentGroupsArray.map((group) => {
    const singleGroupContent: string[] = [];
    group.map((el, index) => singleOperationForGetContent(el, index, singleGroupContent));
    groupContent.push(singleGroupContent);
  });
  return [simpleContent, groupContent];
}

const singleOperationForGetContent = (
  el: ElementRef,
  index: number,
  group: string[]
): void =>
  singleOperation(
    el,
    index,
    (el) => group.push(el.nativeElement.src),
    (el) => group.push(el.nativeElement.innerText),
    (el) => {
      group.push(el.nativeElement.style?.backgroundImage ?? '');
    }
  );

export function setContent(
  renderer: Renderer2,
  contentArray: ElementRef[],
  contentGroupsArray: ElementRef[][] = [],
  simpleContent: string[],
  groupContent: string[][]
): void {
  contentOperation(
    contentArray,
    (el, index, groupIndex) =>
      setSrc(
        renderer,
        el,
        getValueFromContent(simpleContent, groupContent, index, groupIndex)
      ),
    (el, index, groupIndex) =>
      setInnerText(
        renderer,
        el,
        getValueFromContent(simpleContent, groupContent, index, groupIndex)
      ),
    (el, index, groupIndex) =>
      setBackgroundImage(
        renderer,
        el,
        getValueFromContent(simpleContent, groupContent, index, groupIndex)
      ),
    contentGroupsArray
  );
}

export function getEditableElements(groupRef: ElementRef): ElementRef<HTMLElement>[] {
  return Array.from(
    groupRef.nativeElement.getElementsByClassName(SP_CLASSES.EDITABLE_ITEM)
  ).map((el: HTMLElement) => new ElementRef<HTMLElement>(el));
}

export function setImageSrc(el: ElementRef, url: string, renderer: Renderer2): void {
  if (isImage(el)) {
    renderer.setProperty(el.nativeElement, 'src', url);
  }
  if (hasBackgroundImageEditable(el)) {
    renderer.setStyle(el.nativeElement, 'background-image', `url(${url})`);
  }
}

export function getFirstNodeOfEditableGroup(groupRef: ElementRef): Node {
  const refToFirstEditableElement = getEditableElements(groupRef)[0];
  const groupHasNoSections =
    refToFirstEditableElement.nativeElement.parentNode === groupRef.nativeElement;

  if (groupHasNoSections) {
    return refToFirstEditableElement.nativeElement;
  }
  return refToFirstEditableElement.nativeElement.parentNode!;
}
