import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardComponent } from './summary-card.component';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { SummaryCardBadgeComponent } from './summary-card-badge/summary-card-badge.component';

@NgModule({
  declarations: [SummaryCardComponent, SummaryCardBadgeComponent],
  imports: [CommonModule, IconButtonModule],
  exports: [SummaryCardComponent, SummaryCardBadgeComponent],
})
export class SummaryCardModule {}
