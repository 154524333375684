import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuantityControlComponent } from './quantity-control.component';
import { SvgIconsModule } from '../../svg';

@NgModule({
  declarations: [QuantityControlComponent],
  exports: [QuantityControlComponent],
  imports: [CommonModule, SvgIconsModule],
})
export class QuantityControlModule {}
