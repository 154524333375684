import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'purify',
  pure: true,
})
export class PurifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(
    value: Record<string, unknown> | string | null,
    context: SecurityContext = SecurityContext.HTML
  ): SafeValue | string | null {
    switch (context) {
      case SecurityContext.NONE:
        return this.sanitizer.sanitize(context, value);
      case SecurityContext.HTML:
        return this.sanitizer.sanitize(context, value);
      case SecurityContext.STYLE:
        return null;
      case SecurityContext.SCRIPT:
        return null;
      case SecurityContext.URL:
        return this.sanitizer.sanitize(context, value);
      case SecurityContext.RESOURCE_URL:
        // if ((value as string).includes('')) {
        return value;
      // }
      // return '';
    }
  }
}
