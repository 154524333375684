<article #editable [class]=[CLASSES.EDITABLE_BACKGROUND_IMAGE] class="education-article">
  <div style="padding: 10px; height: 100%">
    <h5 #editable>EDUCATION</h5>
    <h3 #editable> {{ initialTitle }}</h3>
    <p #editable>
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <a *transloco="let t;"
       thema-core-button
       [routerLink]="'/' + url"
    >{{t('education_read_guide')}}</a>
  </div>
</article>
