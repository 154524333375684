import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Country, Language } from '@thema-core/models';
import { LOCAL_STORAGE } from '@thema-core/tokens';

export const APP_STORE_CONFIG = new InjectionToken<AppStoreConfig>('app-store-config');

export interface AppStoreConfig {
  defaultCountry: string;
  defaultCurrency: string;
  localStorageKeys?: {
    country?: string;
    currency?: string;
    cookie?: string;
  };
  COUNTRIES: Country[];
  CURRENCIES: string[];
}

export interface AppState {
  language: string;
  currency: string;
  isSideMenuOpened?: boolean;
  languages: Language[];
  defaultLanguage: string;
  country: string;
  countries: Country[];
  currencies: string[];
  cookiesAccepted: boolean;
}

export function createInitialState(
  localStorage: Storage,
  language: string,
  defaultCountry: string,
  defaultCurrency: string,
  countries: Country[],
  currencies: string[]
): AppState {
  let country = defaultCountry;
  let currency = defaultCurrency;

  country = localStorage.getItem('deliveryCountry') ?? country;
  currency = localStorage.getItem('currency') ?? currency;
  const cookiesAccepted = localStorage.getItem('cookieConsent') === 'agree';

  return {
    language,
    languages: [],
    currency,
    country,
    defaultLanguage: '',
    cookiesAccepted,
    countries,
    currencies,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor(
    @Optional() @Inject(APP_STORE_CONFIG) private storeConfig: AppStoreConfig,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    @Inject(LOCAL_STORAGE) localStorage: any
  ) {
    super(
      createInitialState(
        localStorage,
        '',
        storeConfig?.defaultCountry ?? '',
        storeConfig?.defaultCurrency ?? '',
        storeConfig?.COUNTRIES ?? '',
        storeConfig?.CURRENCIES ?? ''
      )
    );
  }
}
