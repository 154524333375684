import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { SnackService } from '@thema-core/services';
import { Subscription } from 'rxjs';
import { SnackConfig } from '@thema-core/models';
import Timer = NodeJS.Timer;

@Component({
  selector: 'thema-core-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackComponent implements OnInit {
  private serviceSubscription: Subscription;
  private autoHideTimer: Timer;
  public config = {} as SnackConfig;
  public state = 'out';

  @HostBinding('class')
  public hostClass: string;

  constructor(
    private snackService: SnackService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.serviceSubscription = this.snackService.message$.subscribe(
      (data: SnackConfig) => {
        this.config = { ...data };
        this.changeDetectorRef.markForCheck();
        clearTimeout(this.autoHideTimer);
        this.slideIn();
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  public onClick(): void {
    this.state = 'out';
    clearTimeout(this.autoHideTimer);
    if (this.config.callback) {
      this.config.callback();
    }
  }

  private slideIn(): void {
    this.state = 'in';
    if (this.config.duration == null || this.config.duration === 0) {
      return;
    }

    this.autoHideTimer = setTimeout(
      () => {
        this.state = 'out';
        this.changeDetectorRef.markForCheck();
      },
      this.config.duration ? this.config.duration : 2500
    );
  }
}
