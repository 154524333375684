import { Injectable } from '@angular/core';
import { WishlistStore } from './wishlist.store';
import { WishlistApiService } from './wishlist-api.service';
import { Router } from '@angular/router';
import { UserQuery } from '../user/user.query';
import { ProductMapperService, SnackService } from '@thema-core/services';
import { TranslocoService } from '@ngneat/transloco';
import { GetRoutePipe } from '@thema-core/pipes';
import { ProductList } from '@thema-core/models';

@Injectable({ providedIn: 'root' })
export class WishlistService {
  constructor(
    private store: WishlistStore,
    private api: WishlistApiService,
    private router: Router,
    private productMapperService: ProductMapperService,
    private userQuery: UserQuery,
    private snack: SnackService,
    private transloco: TranslocoService,
    private getRoute: GetRoutePipe
  ) {}

  public handleProductOnWishlist(
    productId: string | number,
    isProductOnWishlist: boolean | undefined
  ): void {
    if (!this.userQuery.isLoggedIn()) {
      this.snack.showInfo(this.transloco.translate('wishlist_loginToAdd'));
      return;
    }
    if (isProductOnWishlist) {
      this.deleteFromWishlist(productId);
    } else {
      this.addToWishlist(productId);
    }
  }

  public getWishlist(): void {
    this.api.getList().subscribe((res) => {
      const wishlist = this.productMapperService.productListToProductVMs(
        res.map((el) => ({
          ...el,
          isFavourite: true,
        }))
      );
      this.store.update({
        wishlist,
        total: res.length,
      });
    });
  }

  public getWishlistIds(): void {
    if (!this.userQuery.isLoggedIn()) {
      return;
    }
    this.api.getIds().subscribe((res: Array<string | number>) => {
      this.store.update({ wishlistIds: res, total: res.length });
    });
  }

  public clearWishList(): void {
    this.store.reset();
  }

  public markFavourites(
    p: ProductList[],
    wishlistIds = this.store.getValue().wishlistIds
  ): ProductList[] {
    if (!this.userQuery.isLoggedIn()) {
      return p;
    }

    return p.map((product) => {
      const productOnWishlist = wishlistIds.includes(product.id);
      if (product?.isFavourite === productOnWishlist) {
        return product;
      }
      return { ...product, isFavourite: productOnWishlist };
    });
  }

  private deleteFromWishlist(productId: string | number): void {
    this.api.deleteProduct(productId).subscribe(() => {
      this.getWishlistIds();
      if (!this.isOnWwishlist()) {
        return;
      }
      this.getWishlist();
    });
  }

  private addToWishlist(productId: string | number): void {
    this.api.addProduct(productId as string).subscribe(() => {
      this.getWishlistIds();
      if (!this.isOnWwishlist()) {
        return;
      }
      this.getWishlist();
    });
  }

  private isOnWwishlist = (): boolean => {
    return this.router.url.endsWith(this.getRoute.transform('wishList'));
  };
}
