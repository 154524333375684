import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { Observable } from 'rxjs';
import {
  CustomerAddress,
  CustomerAddressNew,
  CustomerAddressUpdate,
  CustomerInfo,
  CustomerUpdate,
} from '@thema-core/models';

const ENDPOINT_URL = '/Customers/Me';
const ADDRESSES_ENDPOINT_URL = ENDPOINT_URL + '/Addresses';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpService) {}

  public get(): Observable<CustomerInfo> {
    return this.http.get(ENDPOINT_URL);
  }

  public update(update: CustomerUpdate & { language: string }): Observable<CustomerInfo> {
    return this.http.put<CustomerInfo>(ENDPOINT_URL, update);
  }

  public addToWishlist(productId: string): Observable<string> {
    return this.http.post(
      `${ENDPOINT_URL}/Wishlist`,
      {},
      { params: { productId }, responseType: 'text' }
    );
  }

  public addAddress(customerAddress: CustomerAddressNew): Observable<CustomerAddress> {
    return this.http.post(ADDRESSES_ENDPOINT_URL, customerAddress);
  }

  public updateAddress(
    customerAddress: CustomerAddressUpdate
  ): Observable<CustomerAddress> {
    return this.http.put<CustomerAddress>(
      `${ADDRESSES_ENDPOINT_URL}/${customerAddress.id}`,
      customerAddress
    );
  }

  public deleteAddress(addressId: string): Observable<CustomerAddress> {
    return this.http.delete<CustomerAddress>(`${ADDRESSES_ENDPOINT_URL}/${addressId}`);
  }

  public removeFromWishlist(productId: string): Observable<string> {
    return this.http.delete(`${ENDPOINT_URL}/Wishlist/${productId}`, {
      responseType: 'text',
    });
  }
}
