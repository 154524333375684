import { Directive, ElementRef } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ContentObserver } from '@angular/cdk/observers';

@Directive({
  selector: '[appLabel]',
})
export class LabelDirective {
  public textChange = new ReplaySubject<string>(1);

  constructor(public el: ElementRef<HTMLLabelElement>, observer: ContentObserver) {
    observer
      .observe(el.nativeElement)
      .pipe(
        map((mutations) => mutations[0]),
        take(1)
      )
      .subscribe((mutation) =>
        this.textChange.next(mutation.target.textContent as string)
      );
  }
}
