import { Component, Input } from '@angular/core';
import { AdminEditablePartComponent } from '../../admin-editable-part-component';

@Component({
  selector: 'thema-core-education-tile',
  templateUrl: './education-tile.component.html',
  styleUrls: ['./education-tile.component.scss'],
})
export class EducationTileComponent extends AdminEditablePartComponent {
  @Input()
  public initialTitle = 'Title';
  @Input()
  public url: string;
}
