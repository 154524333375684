import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldModule } from '../simple-components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { IsPhonePolishValidatorModule } from '@thema-core/directives';
import { AddressFormFieldsComponent } from './address-form-fields/address-form-fields.component';

@NgModule({
  declarations: [AddressFormFieldsComponent],
  imports: [
    CommonModule,
    FormFieldModule,
    FormsModule,
    TranslocoModule,
    IsPhonePolishValidatorModule,
    ReactiveFormsModule,
  ],
  exports: [AddressFormFieldsComponent],
})
export class AddressFormModule {}
