import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPhotosComponent } from './product-photos.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GalleryComponent } from './gallery/gallery.component';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslocoModule } from '@ngneat/transloco';
import { SvgIconsModule } from '../../svg';

@NgModule({
  declarations: [ProductPhotosComponent, GalleryComponent],
  exports: [ProductPhotosComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    SvgIconsModule,
    NguCarouselModule,
    TranslocoModule,
  ],
})
export class ProductPhotosModule {}
