import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { ButtonModule } from '../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  IsEmailValidatorModule,
  MustMatchValidatorModule,
  PasswordValidatorModule,
} from '@thema-core/directives';
import { RouterModule } from '@angular/router';
import { AddLangDataModule, GetRouteModule } from '@thema-core/pipes';
import { FormFieldModule } from '../form-field/form-field.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [ForgotPasswordComponent],
  exports: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MustMatchValidatorModule,
    PasswordValidatorModule,
    IsEmailValidatorModule,
    RouterModule,
    AddLangDataModule,
    FormFieldModule,
    IconButtonModule,
    TranslocoModule,
    GetRouteModule,
  ],
})
export class ForgotPasswordModule {}
