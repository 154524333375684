<ng-container *transloco="let t">
  <div class="page-container">
    <h4 *ngIf="showTitle" class="title h3">{{t('checkout_notLogIn_title')}}</h4>
    <div class="not-login-container">
      <thema-core-login-form
      checkoutMode="true"
      [isLoginMode]="isLoginMode"
      [consents]="consents"
      (modeChange)="changeForm($event)"
      (forgotPassword)="resetPassword()"
      [appearance]="appearance"
      ></thema-core-login-form>
      <thema-core-guest (checkoutClicked)="sendCheckoutAsGuest()"></thema-core-guest>
    </div>
  </div>
</ng-container>
