import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppQuery } from '@thema-core/state';
import { TranslocoService } from '@ngneat/transloco';
import { BlogEntry, BlogEntryList, ProductVM } from '@thema-core/models';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  private readonly renderer = inject(RendererFactory2).createRenderer(null, null);
  private readonly document = inject(DOCUMENT);
  private readonly appQuery = inject(AppQuery);
  private readonly t = inject(TranslocoService);
  private readonly clearSubscription = inject(Router)
    .events.pipe(
      filter((e) => e instanceof NavigationStart),
      takeUntilDestroyed()
    )
    .subscribe(() => {
      this.clearLd();
    });

  public setHomeSchema(): void {
    const initialData = {
      '@context': 'https://schema.org',
      '@type': 'JewelryStore',
      name: 'E-DIAMENTY',
      image: 'https://e-diamenty.pl/assets/logo.svg',
      '@id': 'https://e-diamenty.pl/',
      url: 'https://e-diamenty.pl/',
      telephone: `${this.t.translate('common_phoneNumber')}`,
      priceRange: '30-1000000',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Świerzawska 1',
        addressLocality: 'Poznań',
        postalCode: '60-321',
        addressCountry: 'PL',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 52.3598526,
        longitude: 16.9041641,
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '08:00',
        closes: '16:00',
      },
    };

    this.loadScript(this.toJson(initialData));
  }

  public setProductSchema(product: ProductVM): void {
    const stockStatus = product.attributes.find((a) => a.code === 'stock_status');
    let availability: string | undefined;

    switch (stockStatus?.value['selectKey']) {
      case 'pre_order':
        availability = 'https://schema.org/PreOrder';
        break;
      case 'out_of_stock':
        availability = 'https://schema.org/OutOfStock';
        break;
      case 'in_stock':
        availability = 'https://schema.org/InStock';
        break;
    }

    const productData = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: product.short_description,
      description: product.description,
      sku: product.sku,
      category: 'Diament',
      brand: {
        '@context': 'https://schema.org',
        '@type': 'Brand',
        name: 'E-diamenty',
      },
      offers: {
        '@context': 'https://schema.org',
        '@type': 'Offer',
        priceCurrency: 'PLN',
        price: product.pricing.currentPriceTaxIncluded,
        url: this.document.location.href,
        itemCondition: 'https://schema.org/NewCondition',
        availability,
      },
    };

    this.loadScript(this.toJson(productData));
  }

  public setBlogPostSchema(url: string, blogEntry: BlogEntry): void {
    const postSchema = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://e-diamenty.pl/blog/',
      },
      headline: blogEntry.title,
      image: blogEntry.miniatureUrl,
      description: blogEntry.metaDescription,
      author: {
        '@type': 'Person',
        name: `${blogEntry.author.name} ${blogEntry.author.lastName}`,
      },
      publisher: {
        '@type': 'Organization',
        name: 'E-diamenty',
        logo: {
          '@type': 'ImageObject',
          url: 'https://e-diamenty.pl/assets/images/logo.png',
        },
      },
      url,
      isPartOf: {
        '@type': 'Blog',
        '@id': 'https://e-diamenty.pl/blog/',
        name: 'E-diamenty Blog',
        publisher: {
          '@type': 'Organization',
          name: 'E-diamenty',
        },
      },
    };

    this.loadScript(this.toJson(postSchema));
  }

  setBlogSchema(entries: BlogEntryList[]): void {
    const blogPosts = entries.map((entry) => ({
      '@type': 'blogPosting',
      mainEntityOfPage: `https://e-diamenty.pl/blog/${entry.url}`,
      headline: entry.title,
      author: {
        '@type': 'Person',
        name: `${entry.author.name} ${entry.author.lastName}`,
      },
      image: entry.miniatureUrl,
      publisher: {
        '@type': 'Organization',
        name: 'E-diamenty',
        logo: {
          '@type': 'ImageObject',
          url: 'https://e-diamenty.pl/assets/images/logo.png',
        },
      },
    }));

    const schema = {
      '@context': 'https://schema.org',
      '@type': 'Blog',
      name: 'E-diamenty Blog',
      url: 'https://e-diamenty.pl/blog/',
      publisher: {
        '@type': 'Organization',
        name: 'E-diamenty',
      },
      blogPosts,
    };

    this.loadScript(this.toJson(schema));
  }

  setAboutSchema(): void {
    this.setHomeSchema();
  }

  setContactSchema(): void {
    this.setHomeSchema();
  }

  // set

  private toJson(jsonLD: object): string {
    return jsonLD
      ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>')
      : '';
  }

  private loadScript(jsonData: string): void {
    this.clearLd();

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.id = 'json-ld';
    const text = this.renderer.createText(jsonData);
    this.renderer.appendChild(script, text);
    this.renderer.appendChild(this.document.head, script);
  }

  private clearLd(): void {
    const existingElement = this.document.getElementById('json-ld');
    if (existingElement) {
      existingElement.remove();
    }
  }
}
