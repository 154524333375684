import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'button[thema-core-icon-button], a[thema-core-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  // tslint:disable-next-line:no-input-rename
  @Input('thema-core-icon-button')
  public icon: string;
  @Input()
  public iconSize = '20px';
}
