import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AppQuery } from '@thema-core/state';
import { getCurrencySymbol } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { first, tap } from 'rxjs/operators';

export enum InputDataTypes {
  Currency,
  DiamondWeight,
  Availability,
}

@Component({
  selector: 'thema-core-input-postfix-data',
  templateUrl: './input-postfix-data.component.html',
  styleUrls: ['./input-postfix-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPostfixDataComponent {
  public data: string;

  @Input()
  public set dataType(v: InputDataTypes) {
    switch (v) {
      case InputDataTypes.DiamondWeight:
        this.data = 'ct';
        return;
      case InputDataTypes.Availability:
        this.data = this.translocoService.translate('common_availability_days');
        return;
      case InputDataTypes.Currency:
        this.appQuery.currency$
          .pipe(first())
          .subscribe((v) => (this.data = getCurrencySymbol(v, 'narrow')));
        return;
    }
  }
  constructor(private appQuery: AppQuery, private translocoService: TranslocoService) {}
}
