import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { WishlistState, WishlistStore } from './wishlist.store';

@Injectable({ providedIn: 'root' })
export class WishlistQuery extends Query<WishlistState> {
  public wishlist$ = this.select('wishlist');
  public wishlistIds$ = this.select('wishlistIds');
  public total$ = this.select('total');
  constructor(protected store: WishlistStore) {
    super(store);
  }
  public isWishlistEmpty(): boolean {
    return this.store.getValue().wishlist.length === 0;
  }
}
