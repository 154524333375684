import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ProductVM } from '@thema-core/models';

export interface WishlistState {
  wishlist: ProductVM[];
  wishlistIds: Array<string | number>;
  total: number;
}
export function createInitialState(): WishlistState {
  return {
    wishlist: [],
    wishlistIds: [],
    total: 0,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wishlist', resettable: true })
export class WishlistStore extends Store<WishlistState> {
  constructor() {
    super(createInitialState());
  }
}
