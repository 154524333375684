import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSliderWithInputsComponent } from './range-slider-with-inputs.component';
import { FormFieldModule } from '../form-field/form-field.module';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from '@thema-core/external';
import { TranslocoModule } from '@ngneat/transloco';
import {
  COMPONENT_APPEARANCE_DEFAULT_CONFIG,
  ComponentAppearanceConfig,
} from '@thema-core/models';
import { COMPONENT_APPEARANCE_TOKEN } from '@thema-core/tokens';

@NgModule({
  declarations: [RangeSliderWithInputsComponent],
  exports: [RangeSliderWithInputsComponent],
  imports: [
    CommonModule,
    NouisliderModule,
    FormFieldModule,
    FormsModule,
    TranslocoModule,
  ],
})
export class RangeSliderWithInputsModule {
  public static forRoot(
    config: Partial<ComponentAppearanceConfig> = COMPONENT_APPEARANCE_DEFAULT_CONFIG
  ): ModuleWithProviders<RangeSliderWithInputsModule> {
    return {
      ngModule: RangeSliderWithInputsModule,
      providers: [
        {
          provide: COMPONENT_APPEARANCE_TOKEN,
          useValue: { ...COMPONENT_APPEARANCE_DEFAULT_CONFIG, ...config },
        },
      ],
    };
  }
}
