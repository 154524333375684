import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountBadgeComponent } from './count-badge.component';

@NgModule({
  declarations: [CountBadgeComponent],
  imports: [CommonModule],
  exports: [CountBadgeComponent],
})
export class CountBadgeModule {}
