import { Component } from '@angular/core';
import { DiamondShape } from './diamond-shape';

@Component({
  selector: 'thema-core-diamond-group',
  templateUrl: './diamond-group.component.html',
  styleUrls: ['./diamond-group.component.scss'],
})
export class DiamondGroupComponent {
  public shapes: DiamondShape[] = [
    new DiamondShape('Round', 'RoundBrilliant'),
    new DiamondShape('Emerald'),
    new DiamondShape('Radiant'),
    new DiamondShape('Princess'),
    new DiamondShape('Pear'),
    new DiamondShape('Asscher'),
    new DiamondShape('Cushion'),
    new DiamondShape('Oval'),
    new DiamondShape('Marquise'),
  ];
}
