import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { PricingShort, ProductVM } from '@thema-core/models';
import { AppQuery, WishlistQuery, WishlistService } from '@thema-core/state';
import { Router } from '@angular/router';
import { GetRoutePipe } from '@thema-core/pipes';
import { filter, first } from 'rxjs/operators';
import { DataLayerService } from '@thema-core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ed-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTileComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input()
  public product: ProductVM;
  public pricing: PricingShort;
  public currency$ = this.appQuery.currency$;
  public name: string;
  public image = 'assets/images/diamond-placeholder.jpg';

  constructor(
    private appQuery: AppQuery,
    private wishlistService: WishlistService,
    private router: Router,
    private getRoute: GetRoutePipe,
    private wishlistQuery: WishlistQuery,
    private cdr: ChangeDetectorRef,
    private dl: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.pricing = this.product.pricing;
    this.name = this.product.short_description as string;
    this.image = (this.product.small_image?.url as string) ?? this.image;
    this.initializeWishlistStatus();
  }
  public onAddToWishlist(event: MouseEvent): void {
    event.preventDefault();
    this.wishlistService.handleProductOnWishlist(
      this.product.id,
      this.product.isFavourite
    );
    this.wishlistQuery.wishlistIds$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ids) => {
        this.product.isFavourite = ids.includes(this.product.id);
        this.cdr.markForCheck();
      });
    event.stopPropagation();
  }

  public onItemSelected(product: ProductVM): void {
    this.dl.onSelectItem(product);
  }

  private initializeWishlistStatus(): void {
    this.wishlistQuery.wishlistIds$
      .pipe(
        first(),
        filter((ids) => ids.some((id) => id === this.product.id))
      )
      .subscribe(() => (this.product.isFavourite = true));
  }
}
