import { AfterViewInit, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import {
  getEditableElements,
  getFirstNodeOfEditableGroup,
} from '../common/content-edit-helpers';
import { AdminEditablePartComponent } from './admin-editable-part-component';

@Component({ template: `` })
export class AdminEditableBaseComponent
  extends AdminEditablePartComponent
  implements AfterViewInit {
  /* Structure of content
                              [
   simpleContent ->             string[#editable,...,#editable],
   groupContent ->              string[#editableGroup][
   singleGroup ex. expansion ->                         string[class="editableItem",...,class="editableItem"]
   singleGroup ex. table ->                             string[class="editableItem",...,class="editableItem"]
                                                      ]
                              ]
  */
  public editableGroupPartToClone: Node[] = []; // First parts from each group for adding new elements to groups
  public tagGroups: ElementRef[][] = []; // Editable elements from groups split by groups
  public initialContent: null | string[][][]; // If null component has been just created else loaded content from backend
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.setInitialContent();
    this.createGroupsRepresentativeClones();
    this.setGroupTags();
    this.setInitialTagsValue();
  }

  public setGroupTags(): void {
    this.tagGroups = [];
    this.editableGroups.map((groupRef) => {
      this.tagGroups.push(getEditableElements(groupRef));
    });
  }

  private setInitialTagsValue(): void {
    if (this.tagGroupsContent.length === 0) {
      return;
    }
    this.tagGroups = this.tagGroups.map((group, groupIndex) => {
      group.map((el, index) => {
        el.nativeElement.innerText = this.tagGroupsContent[groupIndex][index];
        return el;
      });
      return group;
    });
  }

  private createGroupsRepresentativeClones(): void {
    this.editableGroups.map((groupRef, groupIndex) => {
      if (groupIndex >= this.editableGroupPartToClone.length) {
        const nodeClone = getFirstNodeOfEditableGroup(groupRef).cloneNode(true);
        this.editableGroupPartToClone.push(nodeClone);
      }
    });
  }

  private setInitialContent(): void {
    const initialGroupContentClone = this.initialContent
      ? JSON.parse(JSON.stringify(this.initialContent[1]))
      : null;
    if (initialGroupContentClone) {
      this.handleChildContent(initialGroupContentClone);
    }

    this.handleInitialContent(initialGroupContentClone);
    this.cdr.detectChanges();
  }

  private handleChildContent(initialGroupContentClone: string[][]): void {
    this.editableComponents.map((component) => {
      component.handleInitialContent(initialGroupContentClone);
    });
  }
}
