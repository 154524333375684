/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { CartOptions, CartState, WholeCartUpdate } from '@thema-core/models';
import { HttpService } from '@thema-core/services';
import { Observable, Subject } from 'rxjs';

const ENDPOINT_URL = '/Carts';

// todo do something with those @ts-ignores

@Injectable({
  providedIn: 'root',
})
export class CartApiService {
  constructor(private http: HttpService) {}

  public fetchCart(cartId: string, params: CartOptions): Observable<CartState> {
    return this.http.get(`${ENDPOINT_URL}/${cartId}/`, {
      // @ts-ignore
      params: this.http.objectToParams(params),
    });
    // .pipe(
    //   map((v: CartState) => {
    //     v.billingAddress = v.billingAddress;
    //     v.shippingAddress = v.shippingAddress;
    //     return v;
    //   })
    // );
  }

  public updateCart(
    cartId: string,
    cart: WholeCartUpdate,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.put(`${ENDPOINT_URL}/${cartId}`, cart, {
      //@ts-ignore
      params: this.http.objectToParams(params),
    });
  }

  public updatePosition(
    cartId: string,
    positionId: string,
    quantity: number,
    notes: Record<string, string | number>,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.put(
      `${ENDPOINT_URL}/${cartId}/Positions/${positionId}`,
      {
        quantity,
        notes,
      },
      // @ts-ignore
      { params: this.http.objectToParams(params) }
    );
  }

  public addPosition(
    cartId: string,
    productId: string | number,
    quantity: number,
    notes: Record<string, string | number>,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.post(
      `${ENDPOINT_URL}/${cartId}/Positions`,
      {
        productId,
        quantity,
        notes,
      },
      // @ts-ignore
      { params: this.http.objectToParams(params) }
    );
  }

  public updatePayment(
    cartId: string,
    paymentMethodId: string,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.put(
      `${ENDPOINT_URL}/${cartId}/Payment`,
      {},
      // @ts-ignore
      { params: this.http.objectToParams({ ...params, paymentMethodId }) }
    );
  }

  public updateShipping(
    cartId: string,
    shippingMethodId: string,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.put(
      `${ENDPOINT_URL}/${cartId}/Shipping`,
      {},
      // @ts-ignore
      { params: this.http.objectToParams({ ...params, shippingMethodId }) }
    );
  }

  public deletePosition(
    cartId: string,
    positionId: string,
    params: CartOptions
  ): Observable<CartState> {
    return this.http.delete(`${ENDPOINT_URL}/${cartId}/Positions/${positionId}`, {
      // @ts-ignore
      params: this.http.objectToParams(params),
    });
  }

  public updateAdvance(
    cartId: string,
    params: CartOptions & {
      isAdvance: boolean;
    }
  ): Observable<CartState> {
    return this.http.put(
      `${ENDPOINT_URL}/${cartId}/AdvancePayment`,
      {},
      {
        //@ts-ignore
        params: this.http.objectToParams(params),
      }
    );
  }

  public checkCoupon(coupon: string): Observable<unknown> {
    //placeholder
    // this.http.put(`${ENDPOINT_URL}/this_is_where_your_url_is`, {});
    return new Subject<unknown>();
  }
}
