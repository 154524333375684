import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MustMatchValidatorDirective } from './must-match-validator.directive';



@NgModule({
  declarations: [MustMatchValidatorDirective],
  exports: [
    MustMatchValidatorDirective
  ],
  imports: [
    CommonModule
  ]
})
export class MustMatchValidatorModule { }
