import { DestroyRef, Directive, ElementRef, inject, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const MAX_ROWS = 10;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'textarea[appAutoResize]',
})
export class AutoResizeDirective implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(private area: ElementRef<HTMLTextAreaElement>) {}

  public ngOnInit(): void {
    fromEvent(this.area.nativeElement, 'input')
      .pipe(
        // first one must be delayed, so it's called after ngModel does it's job
        startWith({ delayed: true }),
        takeUntilDestroyed(this.destroyRef)
        // takeWhile(() => this.area.nativeElement.rows < MAX_ROWS)
      )
      .subscribe(this.onInput);
  }

  private onInput = ({ delayed }): void => {
    if (delayed) {
      setTimeout(this.resize);
    } else {
      this.resize();
    }
  };

  private resize = (): void => {
    while (
      this.area.nativeElement.scrollHeight == this.area.nativeElement.clientHeight &&
      this.area.nativeElement.rows > 1
    ) {
      this.area.nativeElement.rows--;
    }
    while (
      this.area.nativeElement.scrollHeight > this.area.nativeElement.clientHeight &&
      this.area.nativeElement.rows < MAX_ROWS
    ) {
      this.area.nativeElement.rows++;
    }
  };
}
